var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper-new"},[_c('h6',{staticClass:"title-card"},[_vm._v(" "+_vm._s(_vm.$t("trackMyOrderPage.subtitle"))+" ")]),_c('NbBorderTextInput',{attrs:{"id":"tracking-number","name":_vm.$t('trackMyOrderPage.trackingNumber'),"placeholder":_vm.$t('trackMyOrderPage.placeholderTrackingNumber'),"error":_vm.errors.tracking_number},model:{value:(_vm.tracking_number),callback:function ($$v) {_vm.tracking_number=$$v},expression:"tracking_number"}}),_c('div',{staticClass:"d-flex justify-content-between align-items-end mt-4"},[_c('div'),_c('button',{staticClass:"button button-primary float-right",attrs:{"id":"tracking-btn","disabled":_vm.loading},on:{"click":function($event){return _vm.setRecaptchaToken()}}},[(_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("loading"))+" "),_c('NbIcon',{attrs:{"icon":"loader","attributes":{
            class: 'mb-1 spin',
            width: '1rem',
            height: '1rem',
          }}})],1):_vm._e(),(!_vm.loading)?_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("trackMyOrderPage.track"))+" ")]):_vm._e(),_c('NbIcon',{attrs:{"icon":"chevron-right","attributes":{
          class: 'mb-1',
          width: '1rem',
          height: '1rem',
        }}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }