import Helpers from "../helpers";
export default class UserService {
  constructor() {
    this.helpers = Helpers;
  }
  /**
   * Set logged in user
   * @param userData
   */
  setLoggedInUser(userData) {
    const platform = this.helpers.getPlatform();
    return localStorage.setItem(`${platform}-user`, userData);
  }

  /**
   * Get logged in user
   * @returns {any}
   */
  getLoggedInUser() {
    const platform = this.helpers.getPlatform();
    return JSON.parse(localStorage.getItem(`${platform}-user`));
  }

  getUserAttribute(attribute) {
    const user = this.getLoggedInUser();
    if (user && user[attribute]) {
      return user[attribute];
    }
    return false;
  }

  /**
   * Delete the logged in user data
   */
  deleteLoggedInUser() {
    const platform = this.helpers.getPlatform();
    return localStorage.removeItem(`${platform}-user`);
  }
}
