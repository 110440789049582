import { CHECKPOINT_GROUP, NotificationType } from "../../constants";

export const trackingRuleSettingsModule = {
  namespaced: true,
  state: () => ({
    ruleName: "",
    applyRuleTo: "",
    contractCreation: "",
    sellerIds: [],
    contractIds: [],
    isAllContractsSelected: false,
    notifications: [],
    checkpoints: [],
  }),
  mutations: {
    UPDATE_RULE_NAME(state, payload) {
      state.ruleName = payload;
    },
    UPDATE_APPLY_RULE_TO(state, payload) {
      state.applyRuleTo = payload;
    },
    UPDATE_CONTRACT_IDS(state, payload) {
      state.contractIds = payload;
    },
    UPDATE_CONTRACT_CREATION(state, payload) {
      state.contractCreation = payload;
    },
    UPDATE_IS_ALL_CONTRACTS_SELECTED(state, payload) {
      state.isAllContractsSelected = payload;
    },
    UPDATE_NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    RESET_STATE(state) {
      state.ruleName = "";
      state.applyRuleTo = "";
      state.contractCreation = "";
      state.sellerIds = [];
      state.contractIds = [];
      state.isAllContractsSelected = false;
      state.notifications = [];
    },
    UPDATE_RULE(state, payload) {
      state.ruleName = payload.ruleName;
      state.applyRuleTo = payload.applyRuleTo;
      state.contractCreation = payload.contractCreation;
      state.sellerIds = payload.sellerIds;
      state.contractIds = payload.contractIds;
      state.isAllContractsSelected = payload.isAllContractsSelected;
    },
    UPDATE_CHECKPOINTS(state, payload) {
      state.checkpoints = payload;
    },
  },
  actions: {
    applyRuleToAllShipments({ commit }) {
      commit("UPDATE_CONTRACT_CREATION", "auto_add_contract");
      commit("UPDATE_CONTRACT_IDS", []);
      commit("UPDATE_IS_ALL_CONTRACTS_SELECTED", true);
    },
    applyRuleToSomeShipments({ commit }) {
      commit("UPDATE_CONTRACT_CREATION", "");
    },
    reset({ commit }) {
      commit("RESET_STATE");
    },
    setNotificationElements({ state, commit }, payload = []) {
      const notificationType = [
        NotificationType.SMS_CUSTOMER,
        NotificationType.WHATSAPP_CUSTOMER,
        NotificationType.EMAIL_CUSTOMER,
        NotificationType.EMAIL_SELLER,
        NotificationType.WEBHOOK_SELLER,
      ];

      const notifications = notificationType.reduce((acc, current) => {
        acc[current] = false;
        return acc;
      }, {});

      const checkpoints = state.checkpoints.map((item) => ({
        id: item.id,
        trackingCode: item.tracking_code,
        title: item.title,
        zoneCode: item.zone_code,
        notifications: JSON.parse(JSON.stringify(notifications)),
      }));

      payload.forEach((item) => {
        const checkpointIndex = checkpoints.findIndex(
          (checkpoint) => checkpoint.id === item.checkpoint_id
        );

        if (checkpointIndex !== -1) {
          checkpoints[checkpointIndex].notifications[
            item.notification_type
          ] = true;
        }
      });

      const result = Object.values(CHECKPOINT_GROUP).map((item) => ({
        ...item,
        checkpoints: checkpoints.filter(
          (checkpoint) => checkpoint.zoneCode === item.zoneCode
        ),
      }));

      commit("UPDATE_NOTIFICATIONS", result);
    },
    setRule({ commit, dispatch }, payload) {
      const state = {
        ruleName: "",
        applyRuleTo: "some_shipments",
        contractCreation: "",
        isAllContractsSelected: false,
        sellerIds: [],
        contractIds: [],
      };

      state.ruleName = payload.name;
      const isAllContractsSelected = payload.contract_ids[0] === "all";
      if (isAllContractsSelected) {
        state.isAllContractsSelected = true;
        state.contractCreation = "auto_add_contract";
        state.applyRuleTo = "all_shipments";
      } else {
        state.contractIds = payload.contract_ids || [];
        state.contractCreation = "manual_add_contract";
      }

      state.notifications = [];

      commit("UPDATE_RULE", state);
      dispatch("setNotificationElements", payload.elements || []);
    },
    matchContractIds({ state, commit }, newContractIdsRequested) {
      const selectedContractsFIltered = state.contractIds.filter((contractId) =>
        newContractIdsRequested.includes(Number(contractId))
      );

      commit("UPDATE_CONTRACT_IDS", selectedContractsFIltered);
    },
  },
  getters: {
    ruleToAllContracts(state) {
      return (
        (state.isAllContractsSelected &&
          state.contractCreation === "auto_add_contract") ||
        state.applyRuleTo === "all_shipments"
      );
    },
  },
};
