<template>
  <div>
    <b-card-group deck class="justify-content-center">
      <b-card-group class="justify-content-center" deck>
        <template v-for="(index, category) in categories">
          <card-prepaid
            v-if="
              (selected_method === 'prepaid' || selected_method === 'all') &&
              cat_settings(category).method === 'prepaid'
            "
            @clicked="onClickChild"
            :category="category"
            :categories="categories"
            :settings="cat_settings(category)"
            :global_settings="cat_settings('global')"
            :value="balance.prepaid[category]"
            :key="`pre-${index}`"
          />
          <card-postpaid
            v-if="
              (selected_method === 'postpaid' || selected_method === 'all') &&
              cat_settings(category).method === 'postpaid'
            "
            @clicked="onClickChild"
            :category="category"
            :categories="categories"
            :settings="cat_settings(category)"
            :global_settings="cat_settings('global')"
            :value="balance.postpaid[category]"
            :key="`post-${index}`"
          />
        </template>
      </b-card-group>
    </b-card-group>
  </div>
</template>

<script>
import CardPrepaid from "../components/CardPrepaid";
import CardPostpaid from "../components/CardPostpaid";

export default {
  name: "ViewBalance",
  components: {
    CardPrepaid,
    CardPostpaid,
  },
  props: {
    categories: Object,
    balance: Object,
    settings: [Array, Object],
  },
  data: () => {
    return {
      selected_method: "all",
    };
  },
  methods: {
    onClickChild(category) {
      this.$emit("clicked", category);
    },
    cat_settings(category) {
      let setting = {};
      if (this.settings.length > 0) {
        setting = this.settings.filter((item) => item.category === category)[0];
      }
      return setting;
    },
    select_method(method) {
      this.selected_method = method;
    },
  },
};
</script>
