import { RULE_PRIORITY } from "../../constants";

const initialData = {
  name: "",
  priority: RULE_PRIORITY.VERY_LOW,
  expiration: "",
  active: true,
  actions: [
    {
      name: "",
      values: [],
    },
  ],
  conditions_attributes: [
    {
      condition_name: "",
      operator: null,
      values: [],
      locked: false,
    },
  ],
  errors: [],
};

export const shippingRulesModule = {
  namespaced: true,
  state: () => JSON.parse(JSON.stringify(initialData)),
  mutations: {
    UPDATE_RULE(state, payload) {
      Object.assign(state, payload);
    },
    PUSH_CONDITION(state, payload) {
      state.conditions_attributes.push(payload);
    },
    UPDATE_CONDITION(state, payload) {
      Object.assign(state.conditions_attributes[payload.index], payload.value);
    },
    REMOVE_CONDITION(state, payload) {
      state.conditions_attributes.splice(payload, 1);
    },
    UPDATE_ACTION(state, payload) {
      Object.assign(state.actions[0], payload);
    },
    SET_RULE(state, payload) {
      state.name = payload.name;
      state.priority = payload.priority;
      state.expiration = payload.expiration;
      state.active = payload.active;
      state.actions = payload.actions;
      state.conditions_attributes = payload.conditions_attributes;

      if (payload.id) {
        state.id = payload.id;
      }
    },
    UPDATE_ERRORS(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    newCondition({ commit }) {
      commit("PUSH_CONDITION", {
        condition_name: "",
        operator: null,
        values: [],
        locked: false,
      });
    },
    removeCondition({ commit }, index) {
      commit("REMOVE_CONDITION", index);
    },
    setRule({ commit }, payload) {
      const {
        conditions_attributes,
        rule_conditions,
        actions_attributes,
        rule_actions,
        ...rest
      } = payload;

      const actions = [];
      const conditions = [];

      actions_attributes.forEach((element) => {
        const matchRuleAction = rule_actions.find(
          (item) => item.id === element.rule_action_id
        );

        if (matchRuleAction) {
          actions.push({
            // id: matchRuleAction.id,
            name: matchRuleAction.name,
            values: element.values,
          });
        }
      });

      conditions_attributes.forEach((element) => {
        const matchRuleCondition = rule_conditions.find(
          (item) => item.id === element.rule_condition_id
        );

        if (matchRuleCondition) {
          conditions.push({
            // id: matchRuleCondition.id,
            condition_name: matchRuleCondition.name,
            operator: element.operator,
            values: element.values,
            locked: true,
          });
        }
      });

      commit("SET_RULE", {
        ...rest,
        actions,
        conditions_attributes: conditions,
      });
    },
    reset({ commit }) {
      commit("SET_RULE", JSON.parse(JSON.stringify(initialData)));
    },
  },
  getters: {
    formatActions(state) {
      return state.actions.map((action) => {
        if (
          typeof action.values == "string" ||
          typeof action.values == "number"
        ) {
          action.values = action.values.toString().split(/\s*,\s*/);
        }

        return {
          action_name: action.name,
          values: action.values,
        };
      });
    },
    formatConditions(state) {
      const conditions = JSON.parse(
        JSON.stringify(state.conditions_attributes)
      );
      return conditions.map((condition) => {
        if (
          typeof condition.values == "string" ||
          typeof condition.values == "number"
        ) {
          condition.values = condition.values.toString().split(/\s*,\s*/);
        }

        delete condition.locked;

        return condition;
      });
    },
    shippingRulesData(state, getters) {
      return {
        name: state.name,
        priority: state.priority || 5,
        expiration: state.expiration,
        active: state.active,
        shipping_rules_conditions_attributes: getters.formatConditions,
        shipping_rules_actions_attributes: getters.formatActions,
      };
    },
    shippingRuleId(state) {
      return state?.id;
    },
    hasConditions(state) {
      return !!state.conditions_attributes?.find(
        (condition) => condition?.condition_name !== "" && condition?.locked
      );
    },
  },
};
