<template>
  <NbModal id="conditions" modalConfig="modal-dialog-centered" width="46rem">
    <template #header>
      <div class="d-flex mb-4">
        <h5 class="heading-4 mb-1">
          {{ $t("components.baseTemplate.termsOfService") }}
        </h5>
      </div>
    </template>

    <template #body>
      <div class="content">
        <Agreement />
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-between mt-4">
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
        <NbButton @click="downloadTerms">
          {{ $t("download") }}
        </NbButton>
      </div>
      <!-- <button class="button-light" @click="cancel">Back</button>
      <a
        href="/files/GCS_Nobordist05042022.docx"
        download="GCS_Nobordist.docx"
        class="button-primary"
        @click="ok"
        >Download</a
      > -->
    </template>
  </NbModal>
</template>

<script>
import Agreement from "../../views/Agreement.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "ConditionsService",
  components: {
    Agreement,
    NbModal,
    NbButton,
  },
  methods: {
    downloadTerms() {
      window.open("/files/GCS_Nobordist05042022.docx", "_blank");
    },
  },
};
</script>

<style scoped>
.internal-modal {
  position: absolute;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.text-format-one {
  line-height: 1.44;
  text-align: justify;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-two {
  font-size: 12pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-three {
  line-height: 1.295;
  text-align: justify;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-four {
  font-size: 12pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: underline;
  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-five {
  line-height: 1.44;
  margin-left: 14.2pt;
  text-align: justify;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-six {
  line-height: 1.44;
  margin-left: 14.2pt;
  text-align: justify;
  background-color: #ffffff;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-seven {
  line-height: 1.295;
  margin-left: 14.2pt;
  text-align: justify;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-eight {
  font-size: 11pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-nine {
  font-size: 11pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-ten {
  font-size: 12pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-eleven {
  border-left: solid #000000 0.5pt;
  border-right: solid #000000 0.5pt;
  border-bottom: solid #000000 0.5pt;
  border-top: solid #000000 0.5pt;
  vertical-align: top;
  padding: 0pt 5.4pt 0pt 5.4pt;
  overflow: hidden;
  overflow-wrap: break-word;
}
.text-format-twelve {
  line-height: 1.44;
  text-align: center;
  margin-top: 0pt;
  margin-bottom: 0pt;
}
.text-format-thirteen {
  font-size: 9pt;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.text-format-bold {
  font-size: 12pt;
  font-family: Calibri, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: underline;
  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}
.title-format-one {
  font-size: 12pt;
  font-family: Calibri, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  vertical-align: baseline;
  white-space: pre;
  white-space: pre-wrap;
}

.content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
