<template>
  <base-template>
    <div class="d-flex justify-content-between">
      <div>
        <h5 class="font-weight-bold text-uppercase mb-4">
          NB Tables and more tables
        </h5>
      </div>
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.tableWithTabs = !show.tableWithTabs"
      >
        <span class="text-primary">Table With Tabs</span> :required="true"
        :disabled="boolean" helpText="string" image="string" error="array"
      </h5>
      <div v-if="show.tableWithTabs">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100"></div>
          </div>
          <!-- {{currentTable}} -->
          <div class="d-flex justify-content-start">
            <div class="w-100">
              <NbTabs
                class="mb-3"
                :tabs="tabsOptions"
                @switchTab="switchTab($event)"
              />
              <div :class="currentTable == 'Table' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPage"
                  namespace="orders"
                  :filterOptions="filterOptions"
                  :allFields="allFields"
                  :fields="fields"
                  :selectable="true"
                  @reloadFields="fields = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrders = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #cell(current_status)="data">
                    {{ data.item.current_status.name }}
                  </template>
                  <template #vista(reference)="data">
                    <NbStatus
                      v-if="data.item.current_status_id === 1"
                      type="danger"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 20"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 3"
                      type="success"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 2"
                      type="warning"
                      :text="data.item.current_status.name"
                    />
                  </template>
                </NbTablev2>
              </div>
              <div :class="currentTable == 'Shipped' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPageDois"
                  namespace="orders"
                  :filterOptions="filterOptionShipped"
                  :allFields="allFields"
                  :fields="fieldsDois"
                  :selectable="true"
                  @reloadFields="fieldsDois = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrdersDois = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #cell(current_status)="data">
                    {{ data.item.current_status.name }}
                  </template>
                </NbTablev2>
              </div>
              <div :class="currentTable == 'In transit' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPageTres"
                  namespace="orders"
                  :filterOptions="filterOptionInTransit"
                  :allFields="allFields"
                  :fields="fieldsTres"
                  :selectable="true"
                  @reloadFields="fieldsTres = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrdersTres = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #cell(current_status)="data">
                    {{ data.item.current_status.name }}
                  </template>
                  <template #vista(reference)="data">
                    <NbStatus
                      v-if="data.item.current_status_id === 1"
                      type="danger"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 20"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 3"
                      type="success"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 2"
                      type="warning"
                      :text="data.item.current_status.name"
                    />
                  </template>
                </NbTablev2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5
        class="heading-2 pointer"
        @click="show.tableWithParentData = !show.tableWithParentData"
      >
        <span class="text-primary">Table With Parent data</span>
        :required="true" :disabled="boolean" helpText="string" image="string"
        error="array"
      </h5>
      <div v-if="show.tableWithParentData">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100"></div>
          </div>
          <!-- {{currentTable}} -->
          <div class="d-flex justify-content-start">
            <div class="w-100">
              <NbTabs
                class="mb-3"
                :tabs="tabsOptions"
                @switchTab="switchTab($event)"
              />
              <div :class="currentTable == 'Table' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPage"
                  namespace="orders"
                  :filterOptions="filterOptions"
                  :allFields="allFields"
                  :fields="fields"
                  :selectable="true"
                  :hasParentItens="hasParentItens"
                  :startItems="ordersResponse"
                  @reloadFields="fields = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrders = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #cell(current_status)="data">
                    {{ data.item.current_status.name }}
                  </template>
                  <template #vista(reference)="data">
                    <NbStatus
                      v-if="data.item.current_status_id === 1"
                      type="danger"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 20"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 3"
                      type="success"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 2"
                      type="warning"
                      :text="data.item.current_status.name"
                    />
                  </template>
                </NbTablev2>
              </div>
              <div :class="currentTable == 'Shipped' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPageDois"
                  namespace="orders"
                  :filterOptions="filterOptions"
                  :allFields="allFields"
                  :fields="fieldsDois"
                  :selectable="true"
                  :hasParentItens="hasParentItens"
                  :startItems="ordersResponse"
                  @reloadFields="fieldsDois = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrdersDois = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                </NbTablev2>
              </div>
              <div :class="currentTable == 'In transit' ? '' : 'display-none'">
                <NbTablev2
                  tableOf="tableItemsListPageTres"
                  namespace="orders"
                  :filterOptions="filterOptions"
                  :allFields="allFields"
                  :fields="fieldsTres"
                  :selectable="true"
                  :hasParentItens="hasParentItens"
                  :startItems="ordersResponse"
                  @reloadFields="fieldsTres = $event"
                  @selectedItems="selectedItems"
                  @total="totalOrdersTres = $event"
                >
                  <template #cell(order_number)="data">
                    <router-link
                      :to="`/orders/${data.item.id}`"
                      class="text-link"
                    >
                      {{ data.item.order_number }}
                    </router-link>
                  </template>
                  <template #cell(current_status)="data">
                    {{ data.item.current_status.name }}
                  </template>
                  <template #vista(reference)="data">
                    <NbStatus
                      v-if="data.item.current_status_id === 1"
                      type="danger"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 20"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 3"
                      type="success"
                      :text="data.item.current_status.name"
                    />
                    <NbStatus
                      v-if="data.item.current_status_id === 2"
                      type="warning"
                      :text="data.item.current_status.name"
                    />
                  </template>
                </NbTablev2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.tab = !show.tab">
        <span class="text-link">tabs (same code in /components)</span>
        :required="true" :disabled="boolean" helpText="string" image="string"
        error="array"
      </h5>
      <div v-if="show.tab">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100">Default</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100">
              <NbTabs :tabs="tabsOptions" @switchTab="switchTab($event)" />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>

    <div>
      <h5 class="heading-2 pointer" @click="show.table = !show.table">
        <span class="text-link">table (same code in /components)</span>
        :required="true" :disabled="boolean" helpText="string" image="string"
        error="array"
      </h5>
      <div v-if="show.table">
        <div class="d-flex flex-column bd-highlight my-3">
          <div class="d-flex justify-content-start">
            <div class="w-100">Default</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="w-100">
              <NbTablev2
                tableOf="tableItemsListPageQuatro"
                namespace="orders"
                :filterOptions="filterOptions"
                :allFields="allFields"
                :fields="fieldsQuatro"
                :selectable="true"
                @reloadFields="fieldsQuatro = $event"
                @selectedItems="selectedItems"
                @total="totalOrders = $event"
              >
                <template #cell(order_number)="data">
                  <router-link
                    :to="`/orders/${data.item.id}`"
                    class="text-link"
                  >
                    {{ data.item.order_number }}
                  </router-link>
                </template>
                <template #vista(reference)="data">
                  <NbStatus
                    v-if="data.item.current_status_id === 1"
                    type="danger"
                    :text="data.item.current_status.name"
                  />
                  <NbStatus
                    v-if="data.item.current_status_id === 20"
                    :text="data.item.current_status.name"
                  />
                  <NbStatus
                    v-if="data.item.current_status_id === 3"
                    type="success"
                    :text="data.item.current_status.name"
                  />
                  <NbStatus
                    v-if="data.item.current_status_id === 2"
                    type="warning"
                    :text="data.item.current_status.name"
                  />
                </template>
              </NbTablev2>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-1" />
    </div>
  </base-template>
</template>

<script>
import BaseTemplate from "../views/BaseTemplate.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbStatus from "@/components/alerts/NbStatus.vue";

import OrderService from "@/services/OrderService";
const orderService = new OrderService();

export default {
  components: {
    BaseTemplate,
    NbTablev2,
    NbTabs,
    NbStatus,
  },
  data() {
    return {
      ordersResponse: [],
      ordersResponseDois: [],
      ordersResponseTres: [],
      hasParentItens: true,
      show: {
        tableWithTabs: true,
        tableWithParentData: false,
        tab: false,
        table: false,
      },
      progress: 10,
      buttonOptions: [
        { text: "option 1", value: "action1" },
        { text: "option 2", value: "action2", disabled: true },
        { text: "option 3", value: "action3" },
        { text: "option 4", value: "action4" },
      ],
      buttonOptionChosen: "",
      actions: {
        action1: () => {
          return "action1 example of a function call";
        },
        action2: () => {
          return "action2 example of a function call";
        },
        action3: () => {
          return "action3 example of a function call";
        },
      },
      check: false,
      radioSelected: "radioVal2",
      radioSelected2: "",
      radioOptions: [
        { text: "Radio option 1", value: "radioVal1" },
        { text: "Radio option 2", value: "radioVal2" },
        { text: "Radio option 3", value: "radioVal3" },
      ],
      textForInput: "Texto inicial",
      textForInput2: "",
      selectInputVal: null,
      errors: {
        textForInput2: ["mensagem de erro!", "mensagem dois"],
      },
      cardItemsValue: "",
      cardConfigOne: [{ text: "", value: "Value1" }],
      cardConfigTwo: [{ text: "", value: "Value2" }],
      mabangConnect: false,
      switchValue: true,
      selectedTableItems: [],
      fields: [],
      fieldsDois: [],
      fieldsTres: [],
      fieldsQuatro: [],
      currentTable: "Table",
      totalOrders: 0,
      totalOrdersDois: 0,
      totalOrdersTres: 0,
    };
  },
  created() {
    orderService.getOrders().then((response) => {
      this.ordersResponse = response.data.data;
    });
  },
  methods: {
    selectedItems(event) {
      this.selectedTableItems = event;
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
  },
  computed: {
    allFields() {
      return [
        {
          key: "order_number",
          label: this.$t("ordersListPage.orderNo"),
          sortable: true,
        },
        {
          key: "sales_channel_order_number",
          label: this.$t("ordersListPage.salesChOrderNo"),
          sortable: false,
        },
        {
          key: "total_value",
          label: this.$t("ordersListPage.value"),
          sortable: true,
        },
        { key: "incoterm", label: this.$t("ordersListPage.incoterm") },
        { key: "freight_cost", label: this.$t("ordersListPage.freightCost") },
        {
          key: "customer_postal_code",
          label: this.$t("ordersListPage.postalCode"),
        },
        {
          key: "sales_channel_name",
          label: this.$t("ordersListPage.salesChannel"),
        },
        {
          key: "customer_full_name",
          label: this.$t("ordersListPage.customerName"),
        },
        { key: "customer_country", label: this.$t("ordersListPage.country") },
        { key: "customer_city", label: this.$t("ordersListPage.city") },
        { key: "contract_name", label: this.$t("ordersListPage.contractName") },
        { key: "created_at", label: this.$t("ordersListPage.creationDate") },
        {
          key: "estimated_delivery_date",
          label: this.$t("ordersListPage.estimatedDeliveryDate"),
        },
        { key: "label", label: this.$t("ordersListPage.label") },
        { key: "commercial_invoice", label: this.$t("ordersListPage.invoice") },
        {
          key: "contain_battery",
          label: this.$t("ordersListPage.shipperConfirmationPDF"),
        },
        {
          key: "dangerous_good",
          label: this.$t("ordersListPage.dangerousGoodPDF"),
        },
        { key: "current_status", label: this.$t("ordersListPage.status") },
        { key: "reference", label: this.$t("ordersListPage.trackingNumber") },
        {
          key: "insurance_coverage",
          label: this.$t("ordersListPage.insuranceCoverage"),
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("ordersListPage.lastMileTrackingNumber"),
        },
        {
          key: "last_mile_carrier_number",
          label: this.$t("ordersListPage.lastMileCarrierNumber"),
        },
        {
          key: "first_mile_carrier_name",
          label: this.$t("ordersListPage.firstMileCarrier"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("ordersListPage.firstMileTrackingNumber"),
        },
      ];
    },
    filterOptions() {
      let filtersToReturn = [
        { key: "order_number", label: this.$t("orderNumber") },
        { key: "customer_full_name", label: this.$t("customerName") },
        { key: "customer_country", label: this.$t("customerCountry") },
        { key: "sales_channel", label: this.$t("salesChannel") },
        {
          key: "sales_channel_order_number",
          label: this.$t("salesChannelOrderNumber"),
        },
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("lastMileTrackingNumber"),
        },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "contain_battery",
          label: this.$t("shipmentConfirmation"),
          type: "boolean",
        },
        {
          key: "dangerous_good",
          label: this.$t("printingsPage.dangerousGood"),
          type: "boolean",
        },
        {
          key: "start_registration_date",
          label: this.$t("startRegistrationDate"),
          type: "date",
        },
        {
          key: "end_registration_date",
          label: this.$t("endRegistrationDate"),
          type: "date",
        },
        {
          key: "registration_date",
          label: this.$t("betweenDates"),
          type: "dates",
        },
      ];

      return filtersToReturn;
    },
    filterOptionShipped() {
      let filtersToReturn = [
        { key: "order_number", label: this.$t("orderNumber"), initialVal: 500 },
        { key: "customer_full_name", label: this.$t("customerName") },
        { key: "customer_country", label: this.$t("customerCountry") },
        { key: "sales_channel", label: this.$t("salesChannel") },
        {
          key: "sales_channel_order_number",
          label: this.$t("salesChannelOrderNumber"),
        },
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("lastMileTrackingNumber"),
        },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "contain_battery",
          label: this.$t("shipmentConfirmation"),
          type: "boolean",
        },
        {
          key: "dangerous_good",
          label: this.$t("printingsPage.dangerousGood"),
          type: "boolean",
        },
        {
          key: "start_registration_date",
          label: this.$t("startRegistrationDate"),
          type: "date",
        },
        {
          key: "end_registration_date",
          label: this.$t("endRegistrationDate"),
          type: "date",
        },
      ];

      return filtersToReturn;
    },
    filterOptionInTransit() {
      let filtersToReturn = [
        { key: "order_number", label: this.$t("orderNumber"), initialVal: 600 },
        { key: "customer_full_name", label: this.$t("customerName") },
        { key: "customer_country", label: this.$t("customerCountry") },
        { key: "sales_channel", label: this.$t("salesChannel") },
        {
          key: "sales_channel_order_number",
          label: this.$t("salesChannelOrderNumber"),
        },
        { key: "reference", label: this.$t("trackingNumber") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("lastMileTrackingNumber"),
        },
        {
          key: "current_status",
          label: this.$t("status"),
          type: "select",
          options: this.allStatus,
        },
        {
          key: "contain_battery",
          label: this.$t("shipmentConfirmation"),
          type: "boolean",
        },
        {
          key: "dangerous_good",
          label: this.$t("printingsPage.dangerousGood"),
          type: "boolean",
        },
        {
          key: "start_registration_date",
          label: this.$t("startRegistrationDate"),
          type: "date",
        },
        {
          key: "end_registration_date",
          label: this.$t("endRegistrationDate"),
          type: "date",
        },
      ];

      return filtersToReturn;
    },
    tabsOptions() {
      return [
        {
          label: "Table",
          found: this.totalOrders || 0,
          current: this.currentTable,
        },
        {
          label: "Shipped",
          found: this.totalOrdersDois || 0,
          current: this.currentTable,
        },
        {
          label: "In transit",
          found: this.totalOrdersTres || 0,
          current: this.currentTable,
        },
      ];
    },
    allStatus() {
      return [
        { value: null, text: this.$t("orderStatus.selectOption") },
        { value: 100, text: this.$t("orderStatus.new") },
        { value: 300, text: this.$t("orderStatus.readyToShip") },
        { value: 400, text: this.$t("orderStatus.pickUp") },
        { value: 500, text: this.$t("orderStatus.shipped") },
        { value: 600, text: this.$t("orderStatus.inTransit") },
        { value: 800, text: this.$t("orderStatus.failedAttempt") },
        { value: 700, text: this.$t("orderStatus.outForDelivery") },
        { value: 900, text: this.$t("orderStatus.delivered") },
        { value: 1000, text: this.$t("orderStatus.deliveryFailed") },
        { value: 1300, text: this.$t("orderStatus.partialDelivery") },
        { value: 1400, text: this.$t("orderStatus.partialDeliveryFailed") },
      ];
    },
  },
};
</script>

<style>
.display-none {
  display: none;
}
.display-hidde {
  visibility: hidden;
}
</style>
