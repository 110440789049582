<template>
  <div class="d-flex flex-column justify-content-between">
    <div
      class="d-flex flex-row justify-content-center align-items-center seller-account-events-top mb-4"
    >
      <b-button-group class="mb-5 mr-4 my-auto">
        <b-button
          :class="selected_method === 'all' ? 'selected-method' : ''"
          @click="select_method('all')"
          >All</b-button
        >
        <b-button
          :class="selected_method === 'prepaid' ? 'selected-method' : ''"
          @click="select_method('prepaid')"
          >Pre-Paid</b-button
        >
        <b-button
          :class="selected_method === 'postpaid' ? 'selected-method' : ''"
          @click="select_method('postpaid')"
          >Post-Paid</b-button
        >
      </b-button-group>
      <div class="" v-if="events.length > 0">
        <span class="text-muted mr-2">{{ events[0].created_at }}</span>
        ⇌
        <span class="text-muted ml-2">{{
          events.slice(-1)[0].created_at
        }}</span>
      </div>
      <div class="pl-3">
        <b-form-select
          size="sm"
          v-model="selected_category"
          :options="categoryFilter"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- Filter by category --</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
    </div>
    <div>
      <events-list v-if="events.length > 0" :events="eventsFiltered" />
    </div>
  </div>
</template>

<script>
import EventsList from "../components/EventsList.vue";

export default {
  name: "ViewEvents",
  components: {
    EventsList,
  },
  props: {
    category: String,
    categories: Object,
    events: Array,
    date_range: Object,
  },
  data: () => {
    return {
      charge_method: null,
      selected_category: null,
      selected_method: "all",
      start_date: "",
      end_date: "",
      seller_account_events: [],
    };
  },
  created() {
    this.start_date = this.date_range.start_date;
    this.end_date = this.date_range.end_date;
  },
  methods: {
    categoryTitle(category) {
      const split_category = category.split("_");
      let string_category = "";
      split_category.forEach((category) => {
        string_category +=
          category.charAt(0).toUpperCase() + category.slice(1) + " ";
      });
      return string_category;
    },
    selectCategory(category) {
      this.selected_category = category;
    },
    select_method(method) {
      this.selected_method = method;
    },
    reloadEvents() {
      this.$emit("update", this.start_date, this.end_date);
    },
  },
  computed: {
    eventsFiltered() {
      if (this.selected_category === "all" || this.selected_category === null) {
        if (this.selected_method === "all") {
          return this.events;
        } else {
          return this.events.filter(
            (item) => item.method === this.selected_method
          );
        }
      }
      return this.events.filter(
        (item) => item.category == this.selected_category
      );
    },
    categoryFilter() {
      let filter = [];
      filter.push({ text: "All", value: "all" });
      Object.keys(this.categories).map((category) => {
        filter.push({ text: this.categoryTitle(category), value: category });
      });
      return filter;
    },
  },
};
</script>

<style scoped>
.seller-account-events-top {
  border-radius: 0.5em;
}
</style>
