<template>
  <div
    type="button"
    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    class="mb-3 go-back text-primary"
  >
    <i class="fas fa-chevron-left"></i><slot></slot>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style scoped>
.go-back {
  max-width: 40px;
  max-width: 40px;
  max-width: 40px;
  text-decoration: none;
}
</style>
