<template>
  <div
    class="container-fluid centralized"
    :class="hasQuery ? 'transparent' : ''"
  >
    <LangButton v-if="loaded" class="lang-button-topright" />
    <!-- LangButton usar variavel para construir esse componente por ultimo -->

    <div class="title-wrapper-new">
      <div class="d-flex justify-content-center">
        <IconBox class="mr-3 icon-box" height="26" width="24" />
        <h3 class="title-new">{{ $t("trackMyOrderPage.title") }}</h3>
      </div>
    </div>

    <TrackForm />

    <NbFooter />
  </div>
</template>

<script>
import IconBox from "@/components/icons/IconBox.vue";
import LangButton from "../components/buttons/LangButton.vue";
import NbFooter from "@/components/footers/NbFooter.vue";
import TrackForm from "../components/trackmyorder/TrackForm.vue";

export default {
  name: "track_my_order",
  components: { IconBox, LangButton, NbFooter, TrackForm },
  props: ["error"],
  data: () => {
    return {
      loaded: false,
      hasQuery: false,
    };
  },
  created() {
    if (this.$route.query.trackingNumber) {
      this.hasQuery = true;
    }
  },
  mounted() {
    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
.lang-button-topright {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}
.transparent {
  opacity: 0;
  transition: all 0.3s ease;
}
.centralized {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-weight: 600; */
  background: url("../assets/img/bg/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: all 0.3s ease;
}
.title-wrapper-new {
  margin-top: 0px;
  margin-bottom: 5rem;
  padding: 1.25rem 1.25rem 0px 1.25rem;
}
.title-new {
  font: normal normal 800 24px/32px Nunito Sans;
  letter-spacing: 3.07px;
  color: var(--white);
  text-transform: uppercase;
}

@media only screen and (max-width: 855px) {
  .lang-button-topright {
    position: absolute;
    top: 1.5rem;
    right: 3.1rem;
  }
  .centralized {
    height: 100%;
  }
  .title-wrapper-new {
    margin-top: 10.25rem;
    margin-bottom: 2.5rem;
    padding: 0px;
  }
  .title-new {
    font: normal normal bold 18px/24px Nunito Sans;
    letter-spacing: 1.15px;
  }
  .icon-box {
    position: absolute;
    margin-top: -2.5rem;
  }
}
@media only screen and (max-width: 480px) {
}
</style>
