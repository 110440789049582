<template>
  <img
    :src="image"
    style="padding-top: 8px; padding-right: 8px; padding-left: 8px"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      required: true,
    },
    height: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style></style>
