import HttpService from "./HttpService";

export default class UsersService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get user
   *
   * @param id
   * @returns {Promise}
   */
  getUser(id) {
    return this.httpService.get("/v1/users/" + id);
  }

  /**
   * Get users
   *
   * @param filters
   * @returns {Promise}
   */
  getUsers(filters = {}) {
    return this.httpService.get("/v1/users", {
      params: filters,
    });
  }

  /**
   * Get current seller
   *
   * @returns {Promise}
   */
  getCurrentUser() {
    return this.httpService.get("/v1/current-user-group");
  }

  /**
   * Get the preferred measurement system like "Imperial" or "Metric"
   *
   * @returns {Promise}
   */
  getUserMeasurementSystem() {
    return this.httpService.get("/v1/measure");
  }
  /**
   * Get the selected columns
   *
   * @returns {Promise}
   */
  getUserColumns() {
    return this.httpService.get("/v1/columns/");
  }

  /**
   * Set user
   *
   * @param data
   * @returns {Promise}
   */
  setUser(data = {}) {
    return this.httpService.post("/v1/users", data);
  }

  /**
   * Update user
   *
   * @param id
   * @param data
   * @returns {Promise}
   */
  updateUser(id, data = {}) {
    return this.httpService.put("/v1/users/" + id, data);
  }

  updateUserLanguage(data = {}) {
    return this.httpService.put("/v1/user_language/", data);
  }

  /**
   * Delete user
   *
   * @param id
   * @returns {Promise}
   */
  deleteUser(id) {
    return this.httpService.delete("/v1/users/" + id);
  }

  /**
   * Update Logged In User
   *
   * @param {*} data
   * @returns {Promise}
   */
  updateProfile(data = {}) {
    return this.httpService.put("/v1/users", data);
  }

  updateEmailPassword(data = {}) {
    return this.httpService.put("/v1/user_email_password", data);
  }
}
