<template>
  <div>
    <Datepicker
      v-model="modelValue"
      :lang="language"
      :position="position"
      :class="`element ${size}`"
      :range="range"
      input-class="input-custom"
    />
  </div>
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";

export default {
  components: {
    Datepicker: VueDatepickerUi,
  },
  props: {
    value: {
      type: [Array, String, Date],
      required: false,
      default: null,
    },
    range: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "right",
    },
    size: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatDate(date) {
      // Obtém o year, mês e dia da date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (val) {
          this.$emit("shortDate", this.formatDate(val));
        }
        this.$emit("input", val);
      },
    },
    language() {
      const locale = this.$i18n.locale;

      return locale.toLowerCase().replace("_", "-");
    },
  },
};
</script>

<style lang="scss" scoped>
//not working in scoped repliqued in _form.css
.sm.v-calendar .input-field input {
  height: 1.75rem;
}
.element {
  --v-calendar-picker-color: var(--gray-05);
  --v-calendar-input-bg-color: var(--gray-05);
  --v-calendar-select-bg-color: var(--gray-05);
  --v-calendar-border-color: #323b43;
  --v-calendar-triangle-color: var(--gray-05);
  --v-calendar-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
  --v-calendar-top-shadow: 3px -14px 30px 0px rgba(0, 0, 0, 0.2);
  --v-calendar-text-color: var(--black);
  --v-calendar-action-color: #b8b8b9;
  --v-calendar-text-disabled-color: #b8b8b97c;
  --v-calendar-view-button-color: var(--black);
  --v-calendar-datepicker-icon-color: #b8b8b9;
  --v-calendar-datepicker-icon-size: 1.1rem;
  --v-calendar-active-bg-color: var(--primary);
  --v-calendar-active-text-color: var(--gray-05);
  --v-calendar-range-bg-color: #00189112;
  --v-calendar-view-button-font-weight: 700;
  --v-calendar-view-button-font-size: 1.1rem;
  --v-calendar-range-text-color: var(--black);
  --v-calendar-range-radius: 100%;
  --v-calendar-day-hover-bg-color: var(--gray-10);
  --v-calendar-day-width: 45px;
  --v-calendar-day-height: 45px;
  --v-calendar-day-font-size: 0.8rem;
  --v-calendar-day-font-weight: 500;
  --v-calendar-input-border: none;
  --v-calendar-input-text-color: var(--black);
  --v-calendar-input-font-size: 0.75rem;
  --v-calendar-input-font-weight: 700;
  --v-calendar-content-radius: 4px;
  --v-calendar-day-name-font-size: 0.9rem;
  --v-calendar-day-name-font-weight: 600;
  --v-calendar-day-name-color: #323b43;
  --v-calendar-year-font-size: 1.1rem;
  --v-calendar-year-color: #b8b8b9;
  --v-calendar-year-font-weight: 500;
  --v-calendar-input-bg-disable-color: rgb(245, 245, 245);
  --v-calendar-input-text-disable-color: #b8b8b9;
  --v-calendar-year-disabled-color: #b8b8b9;
  --v-calendar-year-disabled-bg-color: transparent;
  --v-calendar-year-padding: 10px;
  --v-calendar-year-border: none;
  --v-calendar-year-border-radius: none;
  --v-calendar-month-font-size: 1.1rem;
  --v-calendar-month-color: #b8b8b9;
  --v-calendar-month-font-weight: 500;
  --v-calendar-month-disabled-color: #b8b8b9;
  --v-calendar-month-disabled-bg-color: transparent;
  --v-calendar-month-padding: 8px;
  --v-calendar-month-border: none;
  --v-calendar-month-border-radius: none;
}
</style>
