import HttpService from "./HttpService";

export default class OrderService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get order
   *
   * @param {*} id
   * @param filters
   * @returns {Promise}
   */
  getOrder(id = null, filters = {}) {
    return this.httpService.get("/v1/orders/" + id, {
      params: filters,
    });
  }

  /**
   * Get orders
   *
   * @param filters
   * @returns {Promise}
   */
  getOrders(filters = {}) {
    return this.httpService.get("/v1/orders", {
      params: filters,
    });
  }

  getHscodes(filters = {}) {
    return this.httpService.post("/v1/hs_codes/document_needed", filters);
  }
  /**
   * Download orders
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadOrders(filters = {}) {
    return this.httpService.get("/v1/orders/archives", {
      params: filters,
    });
  }

  /**
   * Get imported order with errors
   *
   * @returns {Promise}
   */
  getAmazonImports(perPage, currentPage) {
    return this.httpService.get(
      "/v1/amazon_files" + "?offset=" + currentPage + "&limit=" + perPage
    );
  }

  /**
   * Generate link
   *
   * @param {*} id
   * @returns {Promise}
   */
  generateLink(id = null) {
    return this.httpService.put("/v1/amazon_files/" + id);
  }

  /**
   * Get imported order with errors
   *
   * @returns {Promise}
   */
  getRejectedOrders(perPage, currentPage) {
    return this.httpService.get(
      "/v1/orders/import/errors" +
        "?offset=" +
        currentPage +
        "&limit=" +
        perPage
    );
  }

  /**
   * Get one order errors / valid?
   * @param {*} order
   * @returns {Promise}
   */
  checkOrder(order = {}) {
    return this.httpService.post("/v1/orders/check-valid", { order: order });
  }

  /**
   * Get one order errors / valid?
   * @param {*} orders
   * @returns {Promise}
   */
  saveOrders(orders = {}, amazon_file_id) {
    return this.httpService.post("/v1/amazon-save", {
      orders: orders,
      amazon_file_id: amazon_file_id,
    });
  }

  /**
   * Import orders of amazon
   * @param {*} data
   * @returns {Promise}
   */
  amazonImport(data = {}) {
    return this.httpService.post("/v1/orders/amazon-import", data);
  }
  /**
   * Get order contract
   *
   * @param {*} id
   * @returns {Promise}
   */
  getContract(id = null) {
    return this.httpService.get("/v1/orders/" + id + "/contract");
  }

  /**
   * Get order overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getOverpack(id = null) {
    return this.httpService.get("/v1/orders/" + id + "/overpack");
  }

  /**
   * Get order overpack
   *
   * @param {*} id
   * @returns {Promise}
   */
  getSalesChannel(id = null) {
    return this.httpService.get("/v1/orders/" + id + "/saleschannel");
  }

  /**
   * Get order checkpoints
   *
   * @param {*} id
   * @returns {Promise}
   */
  getCheckpoints(id = null) {
    return this.httpService.get("/v1/orders/" + id + "/checkpoints");
  }

  /**
   * Set order
   *
   * @param data
   * @returns {Promise}
   */
  setOrder(data = {}) {
    return this.httpService.post("/v1/orders", {
      ...data,
      data_source: 1,
    });
  }

  /**
   * Update order
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateOrder(id = null, data = {}) {
    return this.httpService.put("/v1/orders/" + id, data);
  }

  /**
   * Delete order
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteOrder(id = null) {
    return this.httpService.delete("/v1/orders/" + id);
  }

  /**
   * Import orders
   *
   * @param {*} data
   * @returns {Promise}
   */
  importOrders(data) {
    return this.httpService.post("/v1/orders/import", data);
  }

  addCheckpoint(id = null, params) {
    return this.httpService.post(
      "/v1/orders/" + id + "/add-checkpoint",
      params
    );
  }
  //To print Labels, Commercial Invoice and Packing Slip in one PDF
  printingAll(ids) {
    return this.httpService.put("/v1/printings/all", ids);
  }

  dispatchOrder(id = null) {
    return this.httpService.put("/v1/orders/" + id + "/dispatch");
  }

  dispatchOrders(ids = {}) {
    return this.httpService.put("/v1/orders/multi_dispatch", {
      ids: ids,
    });
  }
}
