<template>
  <b-modal
    ref="modalAddMoney"
    id="modal-add-credit"
    ok-title="Add Credit"
    @hide="setInitalState"
    @keyup.enter="createEvent()"
    @ok="createEvent()"
  >
    <template #modal-title>
      <span>Add Credit</span>
      <upcase-string :string="selectedCategory" />
    </template>
    <b-row class="justify-content-center">
      <b-col cols="" />
      <b-col cols="12">
        <b-form-group
          description="How much do you want to add."
          label-for="input-horizontal"
        >
          <label>Amount</label>
          <b-form-input
            v-model="amount"
            type="number"
            placeholder="$0.00"
            id="input-horizontal"
            autocomplete="off"
            autofocus
          >
          </b-form-input>
        </b-form-group>
        <b-form-group>
          <label>Category</label>
          <b-form-select
            v-model="selected_category"
            :options="categoriesSelectOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group>
          <label for="textarea-small">Description:</label>
          <div class="description-wrapper">
            <b-form-textarea
              id="textarea"
              placeholder="Enter something..."
              rows="3"
              max-rows="6"
              v-model="description"
              :disabled="disableDescription"
            ></b-form-textarea>
            <div class="d-flex justify-content-left pills-wrapper">
              <div
                :class="{ pillpressed: pre }"
                class="m-1 btn-sm pre-paid-pills"
                @click="pre = !pre"
              >
                Pre
              </div>
              <div
                :class="{ pillpressed: pos }"
                class="m-1 btn-sm pre-paid-pills"
                @click="pos = !pos"
              >
                Post
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-file
          placeholder="Choose a PDF file or drop it here..."
          drop-placeholder="Drop a PDF file here..."
        />
      </b-col>
      <b-col cols="2" />
    </b-row>
  </b-modal>
</template>

<script>
import SellerAccountService from "@/services/SellerAccountService";
import UserService from "@/services/UserService";
import UpcaseString from "@/components/UpcaseString";

const sellerAccountService = new SellerAccountService();
const userService = new UserService();

export default {
  name: "ModalAddCredit",
  components: {
    UpcaseString,
  },
  props: {
    sellers: Array,
    sellerId: Number,
    categories: Object,
    selectedCategory: {
      type: String,
      default: "global",
    },
  },
  data: () => {
    return {
      seller: null,
      category: null,
      loggedin_user: {},
      sellersOption: [],
      amount: null,
      description: "",
      attachment: "",
      selected_category: 0,
      disableDescription: false,
      categories_select_options: [],
      pre: false,
      pos: false,
    };
  },
  created() {
    this.getLoggedInUser();
    this.selected_category = this.selectedCategory;
  },
  methods: {
    categoryTitle: function (category) {
      const split_category = category.split("_");
      let string_category = "";
      split_category.forEach((category) => {
        string_category +=
          category.charAt(0).toUpperCase() + category.slice(1) + " ";
      });
      return string_category;
    },
    getLoggedInUser() {
      this.loggedin_user = userService.getLoggedInUser();
    },
    createEvent() {
      let formData = new FormData();
      formData.append("ad", this.event_attachment);
      this.$refs.modalAddMoney.hide();
      if (this.sellerId) {
        this.seller = this.sellerId;
      }
      const data = {
        event_type: "credit",
        category: this.selected_category,
        value: this.amount,
        description: this.description,
        seller_id: this.seller,
      };
      sellerAccountService.createEvent(data).then((response) => {
        this.$emit("newEvent", response.data.data);
      });
    },
    today() {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      return year + "/" + month + "/" + day;
    },
    setInitalState() {
      this.selected_category = 0;
      this.description = "";
      this.amount = null;
      this.seller = null;
      this.category = null;
      this.sellersOption = [];
    },
    enableDescription() {
      if (!this.pre && !this.pos) {
        this.description = "";
        this.disableDescription = false;
      } else {
        this.disableDescription = true;
      }
    },
  },
  computed: {
    categoriesSelectOptions() {
      let catArray = [];
      const categories = Object.keys(this.categories);
      categories.map((item) => {
        catArray.push({ text: this.categoryTitle(item), value: item });
      });
      return catArray;
    },
  },
  watch: {
    selectedCategory() {
      this.selected_category = this.selectedCategory;
      this.disableDescription = false;
      this.pos = false;
      this.pre = false;
    },
    pre() {
      this.enableDescription();
      if (this.pre) {
        this.pos = false;
        this.description = `PRE: Recharge made by ${
          this.loggedin_user.name
        }, NOBORDIST, ${this.today()}`;
      }
    },
    pos() {
      this.enableDescription();
      if (this.pos) {
        this.pre = false;
        this.description = `POS: Recharge made by ${
          this.loggedin_user.name
        }, NOBORDIST, ${this.today()}`;
      }
    },
  },
};
</script>

<style scoped>
.pre-paid-pills {
  cursor: pointer;
  background-color: #e9ecef;
}

.pillpressed {
  background-color: #006996;
  color: white;
}

.pills-wrapper {
  background-color: rgba(236, 245, 245, 0.089);
  border-radius: 0.5em;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.description-wrapper {
  border: 2px solid rgb(181, 194, 202);
  border-radius: 0.5em;
}

.pre-paid-pills .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: red;
  background-color: inherit !important;
  border-color: inherit;
}
</style>
