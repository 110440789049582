<template>
  <div class="d-inline-block">
    <button
      class="dropdown-toggle button-rows"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ $t("components.maxRows.button") }}:
      <span class="ml-2"
        >{{ val }}
        <NbIcon
          icon="chevron-down"
          :attributes="{
            class: 'mb-1',
            'stroke-width': 3,
            width: '.9rem',
            height: '.9rem',
          }"
        />
      </span>
    </button>
    <div class="dropdown-menu nb-rows">
      <!-- 
      <input
        type="number"
        class="form-control ml-2"
        style="max-width: 140px;"
        placeholder="1~900"
        v-model="val"
      /> 
      <button class="btn btn-success m-left" @click="saveItemsPerPage">Save</button>
      -->

      <a
        class="dropdown-item button-options"
        href="javascript:void(0)"
        v-on:click="
          val = 10;
          saveItemsPerPage();
        "
        >10</a
      >
      <a
        class="dropdown-item button-options"
        href="javascript:void(0)"
        v-on:click="
          val = 20;
          saveItemsPerPage();
        "
        >20</a
      >
      <a
        class="dropdown-item button-options"
        href="javascript:void(0)"
        v-on:click="
          val = 50;
          saveItemsPerPage();
        "
        >50</a
      >
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  name: "NbMaxRows",
  components: { NbIcon },

  props: {
    perPage: {
      required: true,
    },
    saveTo: {
      required: true,
    },
  },
  data: () => {
    return {
      val: "",
      itemsPerPage: {},
    };
  },
  beforeMount() {
    let storage = {};
    if (localStorage.getItem("itemsPerPage")) {
      storage = JSON.parse(localStorage.getItem("itemsPerPage"));
    }
    if (storage[this.saveTo]) {
      this.itemsPerPage[this.saveTo] = storage[this.saveTo];
    } else if (this.perpage) {
      this.itemsPerPage[this.saveTo] = this.perpage;
    } else {
      this.itemsPerPage[this.saveTo] = 20;
    }
    this.val = this.itemsPerPage[this.saveTo];
    //this.emitVal();
  },
  methods: {
    saveItemsPerPage() {
      let storage = {};
      if (localStorage.getItem("itemsPerPage")) {
        storage = JSON.parse(localStorage.getItem("itemsPerPage"));
      }
      storage[this.saveTo] = this.val;
      this.itemsPerPage[this.saveTo] = this.val;
      localStorage.setItem("itemsPerPage", JSON.stringify(storage));
      this.emitVal();
    },
    emitVal() {
      if (this.val <= 0) {
        this.val = 20;
      }
      this.$emit("emitValue", this.val);
    },
  },
  watch: {},
};
</script>

<style scoped>
.m-left {
  margin-left: 32px !important;
  margin-top: 8px !important;
}
.button-rows {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
.dropdown-toggle::after {
  display: none;
}
.nb-rows.dropdown-menu.show {
  background: var(--gray-05);
  box-shadow: 2px 2px 6px var(--gray-10);
  background: #f0f0fa 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  padding: 0px;
  border-radius: 4px !important;

  /* border: 2px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10);
  border-right: 2px solid var(--gray-10);
  border-bottom: 2px solid var(--gray-10);
  border-left: 2px solid var(--gray-10);
}
.button-options {
  text-align: center !important;
}
.button-options:hover {
  background-color: var(--gray-10);
}
</style>
