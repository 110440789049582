<template>
  <div class="nb-status" :class="`nb-status-${type}`">
    <NbIcon
      :class="`status-text-${type}`"
      icon="circle"
      :attributes="{
        class: 'mb-1',
        fill: getTypeColor,
        width: '.4rem',
        height: '.4rem',
      }"
    />
    <span :class="`status-text ${classes}`">
      {{ text }}
    </span>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  name: "NbStatus",
  components: { NbIcon },
  props: {
    text: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    classes: {
      type: String,
      require: false,
    },
  },
  computed: {
    getTypeColor() {
      if (this.type == "success") return "var(--success)";

      if (this.type == "warning" || this.type == "alert")
        return "var(--warning)";

      if (this.type == "danger" || this.type == "error") return "var(--danger)";

      return "var(--gray-20)";
    },
  },
};
</script>

<style scoped>
.nb-status {
  position: relative;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px var(--font-family-base);
  letter-spacing: 0px;
}
.link .status-text {
  color: var(--gray-60);
  text-decoration: underline;
  font: normal normal 600 14px/20px var(--font-family-base);
}
.status-icon {
  font-size: 0.5em;
  position: absolute;
  top: 45%;
  left: 0px;
  transform: translateY(-50%);
}
.status-text {
  margin-left: 0.3rem;
}
.status-text- {
  color: var(--gray-20) !important;
}
.status-text-danger {
  color: var(--error) !important;
}
.status-text-warning {
  color: var(--attention) !important;
}
.status-text-success {
  color: var(--success) !important;
}
</style>
