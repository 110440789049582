const redirectLegacyToServerless = () => {
  if (
    window.location.host.endsWith("nobordist.com") &&
    !window.location.host.endsWith(".app.nobordist.com")
  ) {
    if (window.location.host.startsWith("tst"))
      window.location.href = window.location.href
        .replace("tstmng.", "tstapp-nb.")
        .replace(/tstapp-(\w+)/, "$1.staging.app");
    else if (window.location.host.startsWith("hml"))
      window.location.href = window.location.href
        .replace("hmlmng.", "hmlapp-nb.")
        .replace(/hmlapp-(\w+)/, "$1.homolog.app");
    else
      window.location.href = window.location.href
        .replace("prdmng.", "nb.")
        .replace("app.", "nb.")
        .replace(".nobordist", ".production.app.nobordist");
  }
};

redirectLegacyToServerless();

import "bootstrap-vue/dist/bootstrap-vue.css";
import "feather-icons";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import { ToastPlugin } from "bootstrap-vue";
import PortalVue from "portal-vue";
import helpers from "./helpers";
import { FormDatepickerPlugin } from "bootstrap-vue";
import vueTextAreaAutoSize from "vue-textarea-autosize";
import { BootstrapVue } from "bootstrap-vue";
import { CoolSelectPlugin } from "vue-cool-select";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { store } from "./store";
import("vue-cool-select/dist/themes/bootstrap.css");

import i18n from "./i18n"; //importando i18n global
import VueEllipseProgress from "vue-ellipse-progress";

import * as Sentry from "@sentry/vue";

Vue.use(CoolSelectPlugin);
Vue.use(VueEllipseProgress);

Vue.config.productionTip = true;

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.config.productionTip = true;
Vue.use(ToastPlugin);
Vue.use(PortalVue);
Vue.use(plugin);
Vue.use(FormDatepickerPlugin);
Vue.use(vueTextAreaAutoSize);
Vue.use(BootstrapVue);
Vue.use(CoolSelectPlugin);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.config.errorHandler = () => {};

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "nobordist.com"],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  store,
  i18n /* i18n.doc CONFIGURA i18n quando vue é criado */,
  render: (h) => h(App),
}).$mount("#app");
