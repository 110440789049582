<template>
  <div class="card-with-btn">
    <div class="d-flex justify-content-between">
      <div class="cardbtn-header-btn">
        {{ title }}
      </div>
      <NbHelpText :id="`card-withbtn-${id}`" size="lg">{{
        helpText
      }}</NbHelpText>
    </div>
    <div class="cardbtn-body">
      {{ text }}
    </div>
    <div class="cardbtn-footer">
      <NbButton @click="$emit('click')">{{ btnLabel }}</NbButton>
    </div>
  </div>
</template>

<script>
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  name: "NbCardWithBtn",
  components: { NbHelpText, NbButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    btnLabel: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.card-with-btn {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border: 1px solid var(--gray-05);
  border-radius: 4px;
  padding: 2.5rem;
}
.cardbtn-header-btn {
  margin-bottom: 0.6rem;
  color: var(--black);
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
}
.cardbtn-body {
  margin-bottom: 1.6rem;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
}
</style>
