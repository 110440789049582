<template>
  <footer class="track-footer">
    <span
      >{{ $t("components.footer.poweredBy")
      }}<a href="https://nobordist.com/" class="textdecoration-underline"
        >Nobordist</a
      ></span
    >
  </footer>
</template>

<script>
export default {
  name: "nb_footer",
  methods: {
    getPlatform() {
      return this.$helpers.getPlatform();
    },
  },
};
</script>

<style lang="scss" scoped>
.track-footer {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  width: 100vw;
  text-align: center;
  font: normal normal 600 12px/20px Nunito Sans;
  letter-spacing: 0.19px;
}
.track-footer,
.track-footer > span > a {
  text-align: center;
  font: normal normal 600 12px/20px Nunito Sans;
  letter-spacing: 0.19px;
  color: var(--white);
}

@media only screen and (max-width: 855px) {
  .track-footer {
    position: static;
    margin-bottom: 1.6rem;
  }
}
@media only screen and (max-width: 480px) {
}
</style>
