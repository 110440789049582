<template>
  <div :class="`nb-spinner align-${align}`">
    <!-- <div :class="`spinner-border spinner-border-${size}`" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
    <NbIcon
      icon="loader"
      :attributes="{
        class: 'mr-1 mb-1 spin',
        width: size,
        height: size,
      }"
    />
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  components: { NbIcon },
  props: {
    align: {
      type: String,
      default: "left",
    },
    size: {
      type: String,
      default: ".8rem",
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-spinner {
  color: var(--primary);
  &.align-center {
    display: flex;
    justify-content: center;
  }
}
</style>
