<template>
  <div class="nb-progress-bar-default">
    <div
      class="progress-bar-wrapper"
      :class="`step-${progressVal} ${addInfoColor}`"
    >
      <b-progress
        :height="`${height}px`"
        :max="max"
        :value="progressVal * step"
      ></b-progress>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progressVal: {
      type: [Number, String],
      require: true,
    },
    step: {
      type: Number,
      default: 1,
    },
    addInfoColor: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 2,
    },
    max: {
      type: [Number, String],
      require: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nb-progress-bar-default .progress-bar-wrapper {
  margin: auto;
  z-index: 0;
}
</style>
