<template>
  <div class="search-input-ahead-wrapper" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <VueBootstrapTypeAhead
      :data="items"
      :serializer="serializer"
      :minMatchingChars="1"
      @hit="(event) => $emit('selected', event)"
      :inputClass="`form-control-ahead input ${size}`"
      :id="id"
      autocomplete="off"
      :placeholder="placeholder"
      v-model="textContent"
      :class="error[0] ? `is-invalid ${size}` : `${size}`"
    />
    <span :class="`search-icon-ahead ${size} ${name ? 'fix-top' : ''}`">
      <NbIcon icon="search" />
    </span>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import VueBootstrapTypeAhead from "vue-bootstrap-typeahead";
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbBorderTextInput",
  components: {
    ErrorFeedback,
    NbHelpText,
    VueBootstrapTypeAhead,
    NbIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    serializer: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      textContent: this.value,
    };
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  watch: {
    //altera o input
    textContent(newValue) {
      this.$emit("input", newValue);
    },
    //altera o modal do pai
    value(newValue) {
      this.textContent = newValue;
    },
  },
};
</script>

<style lang="scss">
.search-input-ahead-wrapper {
  position: relative;
}
.search-input-ahead-wrapper > #top-search .list-group {
  box-shadow: 4px 4px 12px var(--gray-10) !important;
  border-radius: 0px 0px 4px 4px !important;
  padding: 0px !important;

  /* border: 1px solid var(--gray-10); */
  border-top: 1px solid var(--gray-10) !important;
  border-right: 2px solid var(--gray-10) !important;
  border-bottom: 2px solid var(--gray-10) !important;
  border-left: 2px solid var(--gray-10) !important;
}
.vbst-item.list-group-item.list-group-item-action {
  text-align: center;
  background: var(--gray-05);
  background-color: var(--gray-05);
  padding: 0.5rem !important;
  color: black !important;
}
.vbst-item.list-group-item.list-group-item-action.active {
  border-top: 1px solid var(--gray-10) !important;
  border-right: 1px solid var(--gray-10) !important;
  border-left: 1px solid var(--gray-10) !important;
  border-bottom: 1px solid var(--gray-10) !important;

  background-color: var(--gray-10) !important;
}

.search-input-ahead-wrapper > .search-icon-ahead {
  z-index: 3;
  position: absolute;
  top: 0.7rem;
  left: 0.8rem;
  color: var(--gray-40);
}
.search-input-ahead-wrapper > .search-icon-ahead.sm {
  z-index: 3;
  position: absolute;
  top: 0.35rem !important;
  left: 0.8rem;
  color: var(--gray-40);
}

.search-input-ahead-wrapper > .search-icon-ahead.fix-top {
  position: absolute;
  top: 2.45rem;
  left: 0.8rem;
  color: var(--gray-40);
}
.search-input-ahead-wrapper > .search-icon-ahead.sm.fix-top {
  position: absolute;
  top: 2.2rem !important;
  left: 0.8rem;
  color: var(--gray-40);
}
.form-control-ahead.input.is-invalid:focus + .search-icon-ahead,
.form-control-ahead.input:focus + .search-icon-ahead,
.form-control-ahead.input.is-invalid:not(:placeholder-shown)
  + .search-icon-ahead,
.form-control-ahead.input:not(:placeholder-shown) + .search-icon-ahead {
  color: var(--black);
}
.form-control-ahead.input:not(:placeholder-shown),
.form-control-ahead.input.is-invalid:not(:placeholder-shown) {
  border: 1px solid #000000;
}
.form-control-ahead.input {
  height: 2.5rem !important;
  box-shadow: none !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  font: normal normal normal 14px/20px Nunito Sans !important;
  padding: 0rem 0.75rem 0rem 2.4rem !important;
}
.form-control-ahead.input:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.form-control-ahead.input:focus {
  box-shadow: none;
  border: 1px solid #000000;
}
.form-control-ahead.input::placeholder {
  font: normal normal normal 14px/20px Nunito Sans;
  color: var(--gray-40);
}

.form-control-ahead.input.is-invalid {
  border: 1px solid var(--danger);
  background-image: none;
}
.form-control-ahead.input.is-valid.sm,
.form-control-ahead.input.sm {
  padding: 0rem 0.55rem 0rem 2.2rem !important;
  height: 2rem !important;
}

.form-control-ahead.input.is-valid {
  border: 1px solid var(--success);
  background-image: none;
}
.form-control-ahead.input.is-invalid.sm,
.form-control-ahead.input.sm {
  padding: 0rem 0.55rem 0rem 2.2rem !important;
  height: 2rem !important;
}

.form-control-ahead.input.is-invalid:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box;
}
.form-control-ahead.input:disabled,
.form-control-ahead.input[disabled] {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid var(--gray-20) !important;
}
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}
</style>
