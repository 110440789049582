import axios from "axios";
import NProgress from "nprogress";
import UserService from "./UserService";
import router from "../router";
/* 
import Vue from 'vue'
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)
const vm = new Vue();
*/

import Helpers from "../helpers";
const helpers = Helpers;

const userService = new UserService();
const user = userService.getLoggedInUser();
let showToaster = true;

let authToken =
  user && user.hasOwnProperty("auth_token")
    ? user.auth_token
    : process.env.VUE_APP_DEFAULT_AUTH_TOKEN;

const instance = axios.create({
  baseURL: process.env.VUE_APP_LOGISTICS_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + authToken,
  },
});

instance.interceptors.request.use((config) => {
  NProgress.start();
  return config;
});

instance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    if (error.response.status === 401 && showToaster) {
      const prefix = helpers.getPlatform("prefix");

      const tostable = userService.getLoggedInUser();
      localStorage.removeItem("user");
      showToaster = false;
      router.push({
        path: `/${prefix}-login`,
        query: {
          intended: router.currentRoute.fullPath,
        },
      });

      if (tostable) {
        helpers.toast("Please login again.", "danger");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
