<template>
  <div>
    <label v-if="name" class="radio-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>

    <template v-if="options.length">
      <b-form-radio-group
        :id="id"
        class="nb-radio"
        :disabled="disabled"
        :class="classes"
        :options="options"
        @change="emitClick"
        v-model="content"
      />
    </template>
    <b-form-radio-group
      v-else
      class="nb-radio"
      :disabled="disabled"
      :class="classes"
      @change="emitClick"
    >
      <slot></slot>
    </b-form-radio-group>
    <ErrorFeedback :error="error[0]" />
    <!-- </b-form-radio-group> -->
  </div>
</template>

<script>
import ErrorFeedback from "../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbRadio",
  components: {
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
    validateContent() {
      //take care with || this.error.length > 0
      if (this.required && this.content.length < 1) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        return;
      }

      this.$emit("valid", this.id);
    },
  },
  watch: {
    //altera o input (way data bind ->)
    content(newValue) {
      this.$emit("input", newValue);
      this.validateContent();
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.content = newValue;
    },
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
};
</script>

<style lang="scss">
.radio-label {
  color: var(--black);
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  margin-bottom: 0.25rem !important;
}

.nb-radio
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after,
.nb-radio .custom-radio .custom-control-input ~ .custom-control-label::after {
  border-radius: 50%;
  background-color: transparent !important;
  background-image: none !important;
  transition: all 0.3s ease;
}
.nb-radio
  .custom-radio
  .custom-control-input:focus
  ~ .custom-control-label:focus::after {
  box-shadow: 0px 0px 0px 1px var(--black) !important;
}
.nb-radio
  .custom-radio
  .custom-control-input:hover
  ~ .custom-control-label:hover::after {
  background-color: var(--gray-20) !important;
  border: 1px solid var(--gray-40);
  background-image: none !important;
  transition: all 0.3s ease;
}
.nb-radio
  .custom-radio
  .custom-control-input:hover:checked
  ~ .custom-control-label:hover::after {
  background-color: var(--black) !important;
  border: 1px solid var(--black);
  background-image: none !important;
  transition: all 0.3s ease;
}
.nb-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--black) !important;
}
.nb-radio .custom-control-label::before {
  border: 1px solid var(--gray-60);
}

.invalid-feedback {
  display: block;
  margin-top: 0.25rem;
}
</style>
