<template>
  <div class="mt-0 relative" v-click-outside="closeCollapse">
    <div v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </div>
    <div
      :id="id"
      :style="customStyle"
      class="button select-button w-100"
      :class="
        error[0]
          ? `is-invalid ${filled} ${isDisabled} ${size} ${variant}`
          : `${filled} ${isDisabled} ${size} ${variant}`
      "
      v-b-toggle="[`collapse-${id}`]"
    >
      {{ showOptionSelected }}
      <NbIcon
        icon="chevron-right"
        :attributes="{
          class: `float-right ml-2 ${size ? 'mt-3px' : 'mt-1'}`,
          'stroke-width': 4,
          width: '.8rem',
          height: '.8rem',
        }"
      />
    </div>
    <div class="mt-0 relative">
      <b-collapse
        :id="`collapse-${id}`"
        class="mt-0 select-colapse-card"
        v-model="visible"
      >
        <b-card class="p-0 nb-selectinput-card">
          <div
            v-for="option in options"
            :key="option.id"
            :class="option.disabled ? 'text-disabled' : ''"
            @click="option.disabled ? null : switchSelect(option)"
          >
            <div
              class="select-options w-100"
              v-b-toggle="[`collapse-${option.disabled ? '' : id}`]"
            >
              <span class="select-label">{{ option.text }}</span>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { directive } from "v-click-outside";

export default {
  name: "SelectInput",
  directives: { clickOutside: directive },
  components: { ErrorFeedback, NbHelpText, NbIcon },
  data() {
    return {
      optionSelected: "",
      filled: "",
      isDisabled: "",
      visible: this.startOpen,
    };
  },
  props: {
    value: {
      type: [Object, String, Number],
      default: function () {
        return { text: "Select an item", value: "" };
      },
    },
    options: {
      type: Array,
      required: true,
    },
    returnCompleteObject: {
      type: Boolean,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      default: "",
    },
    customStyle: {
      type: [Object, Array],
      default: () => [],
    },
  },
  methods: {
    switchSelect(option) {
      if (option.value != this.optionSelected) {
        this.optionSelected = option.value;
        this.$emit("change", option.value);
        this.filled = "filled";
      }
      if (this.returnCompleteObject) {
        this.$emit("objectSelected", option);
      }
    },
    closeCollapse() {
      this.visible = false;
    },
    validateOptionSelected() {
      const element = document.getElementById(this.id);
      //take care with || this.error.length > 0
      if (
        this.required &&
        this.optionSelected &&
        this.optionSelected.length < 1
      ) {
        this.$emit("invalid", {
          id: this.id,
          message: this.$t("errorMessages.required"),
        });
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.optionSelected && this.optionSelected.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
      this.$emit("valid", this.id);
    },
  },
  computed: {
    classes() {
      const baseClass = ["form-control"];

      if (this.error.length) {
        baseClass.push("is-invalid");
      }
      return baseClass;
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    showOptionSelected() {
      const selectedText = this.options.find(
        (option) => option.value === this.optionSelected
      );
      if (selectedText) {
        return selectedText.text;
      }
      /* placeholder */
      return this.placeholder ? this.placeholder : this.$t("selectOption");
    },
  },
  created() {
    this.optionSelected = this.value;
  },
  watch: {
    optionSelected(newValue) {
      this.$emit("input", newValue);
      this.validateOptionSelected();
    },
    value(newValue) {
      if (newValue !== this.optionSelected) {
        this.optionSelected = newValue;
      }
    },
    disabled() {
      if (this.disabled) {
        this.isDisabled = "disabled";
        return;
      }
      this.isDisabled = "";
    },
  },
};
</script>

<style>
.button.select-button {
  position: relative;
  background: var(--gray-05);
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid var(--gray-40);
  color: var(--gray-40);
  text-align: left;
  font: normal normal normal 14px/24px var(--font-family-base);
  letter-spacing: 0px;
  box-shadow: none;
  padding: 9px 12px;
  height: 2.5rem;
  /* height: calc(2em + 0.75rem + 2px); */
}
.button.select-button.filled {
  color: var(--black);
}
.button.select-button.is-invalid {
  border-bottom: 2px solid var(--error);
}
.button.select-button.is-valid {
  border-bottom: 2px solid var(--success);
}
.form-control.input.is-valid {
  border-bottom: 2px solid var(--success) !important;
  caret-color: var(--success);
  background-image: none;
}
.button.select-button.disabled,
.text-disabled {
  color: var(--gray-60) !important;
}
.button.select-button:hover {
  background: var(--gray-10);
}
.button.select-button.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.not-collapsed > .i span svg,
.button.select-button.not-collapsed > i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button > .i span svg,
.button.select-button > i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.disabled > .i span svg,
.button.select-button.disabled > i {
  color: var(--gray-20);
}

.button.select-button.disabled {
  border-color: var(--gray-20);
}

/* size sm */
.button.select-button.sm {
  background: var(--gray-05);
  border: 1px solid var(--gray-50);
  border-radius: 4px;
  color: var(--gray-50);
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 0.4rem 0.5rem;
  height: 1.75rem;
}
.button.select-button.sm.filled {
  color: var(--black);
}
.button.select-button.sm.is-invalid {
  border: 1px solid var(--error);
}
.button.select-button.sm.is-valid {
  border: 1px solid var(--success);
}
.button.select-button.sm.disabled {
  color: var(--gray-20);
}
.button.select-button.sm:hover {
  background: var(--gray-10);
}
.button.select-button.sm.not-collapsed {
  transition: all 0.3s ease;
  border-bottom: 0px !important;
}
.button.select-button.sm.not-collapsed > .i span,
.button.select-button.sm.not-collapsed > i {
  transition: all 0.3s ease;
  transform: rotate(90deg);
}
.button.select-button.sm > .i span svg,
.button.select-button.sm > i {
  color: var(--gray-60);
  transition: all 0.3s ease;
}
.button.select-button.sm.disabled > .i span svg,
.button.select-button.sm.disabled > i {
  color: var(--gray-20);
}

.select-colapse-card {
  position: absolute;
  top: 0px;
  width: 100%;
}
.nb-selectinput-card {
  overflow: auto;
  max-height: 53vh;
}
.card {
  z-index: 4;
  background-color: var(--gray-05);
  border-radius: 0px;
  border: none;
}
.card-body {
  border-radius: 0px !important;
  padding: 0px;
  color: var(--black);
  text-align: left;
  font: normal normal normal 14px/20px var(--font-family-base);
  letter-spacing: 0px;
}
.card-body > div:last-child {
  border-bottom: 2px solid var(--black);
}
.relative {
  position: relative;
}
.select-options {
  cursor: pointer;
  padding: 9px 12px;
  border-bottom: 2px solid var(--gray-10);
}
.select-options:hover {
  background-color: var(--gray-10);
}
.text-disabled > .select-options:hover {
  cursor: not-allowed;
  background-color: var(--gray-05);
}

.mt-2px {
  margin-top: 2px;
}

/* borderless input */
.borderless.button.select-button {
  background: var(--gray-20) !important;
  border-radius: 4px !important;
  border-bottom: 2px solid transparent;
  color: var(--black) !important;
}

.borderless.button.select-button > .i span svg,
.borderless.button.select-button > i,
.borderless.button.select-button.disabled > .i span svg,
.borderless.button.select-button.disabled > i {
  color: var(--black) !important;
}
</style>
