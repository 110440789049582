<template>
  <b-card
    class="card-seller-accounts mb-4 shadow"
    :class="
      (settings.is_blocked && value < 0) ||
      !settings.is_active ||
      !global_settings.is_active
        ? 'card-seller-accounts-disabled'
        : ''
    "
    style="min-width: 300px; max-width: 300px"
    align="center"
  >
    <div class="d-flex justify-content-between">
      <div>
        <div v-if="settings.is_blocked">
          <i
            :id="`tooltip-target-${category}`"
            class="fa fa-exclamation-triangle mr-2 text-danger"
            v-b-tooltip.hover
          ></i>
          <b-tooltip
            :target="`tooltip-target-${category}`"
            triggers="hover"
            placement="bottom"
          >
            Service is suspended due to non-payment
          </b-tooltip>
        </div>
        <b-badge v-else variant="info">Post-paid</b-badge>
      </div>
      <upcase-string :string="category" />
      <div
        class="seller-accounts-btn-settings-info"
        @click="handleClick(category)"
        v-b-modal.modal.modal-settings
      >
        <i class="fas fa-sliders-h fa-lg"></i>
      </div>
    </div>
    <b-card-body>
      <div
        class="d-flex flex-row justify-content-center align-items-center"
        style="min-height: 100px"
      >
        <div class="align-self-center">
          <b-card-text>
            Partial Invoice
            <h2 v-if="value">{{ value }}</h2>
            <h2 v-if="!value">0.00</h2>
          </b-card-text>
          <div class="d-flex flex-column">
            <small v-if="limit" class="text-muted">Limit: {{ limit }}</small>
            <small v-if="!global_settings.is_active" class="text-danger"
              >Global is not active</small
            >
            <small v-else-if="!settings.is_active" class="text-danger"
              >Service is not active</small
            >
            <small v-else-if="settings.is_blocked" class="text-danger"
              >Service is suspended due to non-payment</small
            >
            <small v-else-if="limit && limit * -1 > value" class="text-danger"
              >Balance is {{ (value - limit * -1).toFixed(2) }} NBx below the
              limit</small
            >
            <small v-if="!limit" class="text-muted">No limit</small>
          </div>
        </div>
        <b-card-text> {{ description || " " }}</b-card-text>
      </div>
    </b-card-body>
    <button
      @click="handleClick(category)"
      class="btn add-credit-info m-1"
      v-b-modal.modal.modal-view-usage
    >
      View Usage
    </button>
  </b-card>
</template>

<script>
import UpcaseString from "@/components/UpcaseString";

export default {
  name: "CardPostpaid",
  components: {
    UpcaseString,
  },
  props: {
    category: {
      type: String,
    },
    description: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    settings: {
      type: [Object, Number, String],
      require: true,
    },
    global_settings: {
      type: [Object, Number, String],
      require: true,
    },
  },
  methods: {
    handleClick(category) {
      this.$emit("clicked", category);
    },
    handleClickEdit(title) {
      this.$router.push(this.$route.params.seller_id + "/category/" + title);
    },
  },
  computed: {
    categoryTitle: function () {
      const split_category = this.category.split("_");
      let string_category = "";
      split_category.forEach((category) => {
        string_category +=
          category.charAt(0).toUpperCase() + category.slice(1) + " ";
      });
      return string_category;
    },
    limit: function () {
      return this.settings.limit;
    },
  },
};
</script>

<style scoped>
.seller-accounts-btn-settings {
  padding: 0.35em;
  border-radius: 0.2em;
  border: 1px solid transparent;
}

.seller-accounts-btn-settings:hover {
  padding: 0.35em;
  border-radius: 0.2em;
  border: 1px solid transparent;
  background-color: #5aab5a;
  color: white;
}

.seller-accounts-btn-settings-info {
  padding: 0.35em;
  border-radius: 0.2em;
  border: 1px solid transparent;
}

.seller-accounts-btn-settings-info:hover {
  padding: 0.35em;
  border-radius: 0.2em;
  border: 1px solid transparent;
  background-color: #17a2b8;
  color: white;
}

.card-seller-accounts {
  background-color: white;
}

.card-seller-accounts .card-header {
  background-color: white;
}

.card-seller-accounts-disabled {
  color: gray !important;
  box-shadow: none !important;
}

.add-credit {
  color: black;
  background-color: white;
  border: 1px solid #5aab5a;
}

.add-credit:active {
  color: black;
  background-color: white;
  border: 1px solid #5aab5a;
}

.add-credit:hover {
  color: white;
  background-color: #5aab5a;
  border: 1px solid #5aab5a;
}

.add-credit-info {
  color: black;
  background-color: white;
  border: 1px solid #17a2b8;
}

.add-credit-info:active {
  color: black;
  background-color: white;
  border: 1px solid #17a2b8;
}

.add-credit-info:hover {
  color: white;
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
}
</style>
