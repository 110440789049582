var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card-group',{staticClass:"justify-content-center",attrs:{"deck":""}},[_c('b-card-group',{staticClass:"justify-content-center",attrs:{"deck":""}},[_vm._l((_vm.categories),function(index,category){return [(
            (_vm.selected_method === 'prepaid' || _vm.selected_method === 'all') &&
            _vm.cat_settings(category).method === 'prepaid'
          )?_c('card-prepaid',{key:`pre-${index}`,attrs:{"category":category,"categories":_vm.categories,"settings":_vm.cat_settings(category),"global_settings":_vm.cat_settings('global'),"value":_vm.balance.prepaid[category]},on:{"clicked":_vm.onClickChild}}):_vm._e(),(
            (_vm.selected_method === 'postpaid' || _vm.selected_method === 'all') &&
            _vm.cat_settings(category).method === 'postpaid'
          )?_c('card-postpaid',{key:`post-${index}`,attrs:{"category":category,"categories":_vm.categories,"settings":_vm.cat_settings(category),"global_settings":_vm.cat_settings('global'),"value":_vm.balance.postpaid[category]},on:{"clicked":_vm.onClickChild}}):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }