import HttpService from "./HttpService";

export default class GroupService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get groups
   *
   * @returns {Promise}
   */
  getGroups() {
    return this.httpService.get("/v1/groups");
  }

  /**
   * Get group
   *
   * @param id
   * @returns {Promise}
   */
  getGroup(id) {
    return this.httpService.get("/v1/groups/" + id);
  }
}
