import HttpService from "./HttpService";

export default class NotificationService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get Notifications
   *
   * @returns {Promise}
   */
  getNotifications() {
    return this.httpService.get("/v1/notification_configurations");
  }

  /**
   * Get Notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  getNotification(id) {
    return this.httpService.get("/v1/notification_configurations/" + id);
  }

  /**
   * Get Notification By checkpoint
   *
   * @param {*} id_menu
   * @param {*} id_checkpoint
   * @returns {Promise}
   */
  getNotificationByCheckpoint(id_menu, id_checkpoint) {
    return this.httpService.get(
      "/notification_configurations/" + id_menu + "/checkpoint/" + id_checkpoint
    );
  }

  /**
   * Add Notification
   *
   * @param data
   * @returns {Promise}
   */
  addNotification(data = {}) {
    return this.httpService.post("/v1/notification_configurations", data);
  }

  /**
   * Delete Notification
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteNotification(id = null) {
    return this.httpService.delete("/v1/notification_configurations/" + id);
  }

  /**
   * Get user_notifications titles
   *
   * @returns {Promise}
   */
  getUserNotificationsTitles() {
    return this.httpService.get("/v1/notifications/fetch_titles");
  }

  /**
   * Get seller_notifications titles
   *
   * @returns {Promise}
   */
  getSellerNotificationsTitles() {
    return this.httpService.get("/v1/my_notifications/fetch_titles");
  }

  updateNotificationIsReadseller_notifications(data = {}) {
    return this.httpService.put(
      "/v1/seller_notifications/massive_update",
      data
    );
  }

  updateNotificationIsReaduser_notifications(data = {}) {
    return this.httpService.put("/v1/user_notifications/massive_update", data);
  }
}
