<template>
  <div>
    <label v-if="name" class="input-label">
      {{ name }} <span>{{ required && "*" }}</span>
    </label>
    <div :class="['form-control input', size, { 'is-invalid': error[0] }]">
      <slot name="prefix">
        <span>{{ prefix }}</span>
      </slot>
      <input
        v-model="formattedValue"
        type="text"
        @input="onInput"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Array,
      default: () => [],
    },
    decimal: {
      type: String,
      default: ",",
    },
    thousands: {
      type: String,
      default: ".",
    },
    precision: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    formattedValue: {
      get() {
        return this.formatCurrency(this.value);
      },
      set(val) {
        this.$emit("input", this.parseCurrency(val));
      },
    },
    divisor() {
      const value = String(1).padEnd(this.precision + 1, "0");
      return Number(value);
    },
  },
  methods: {
    formatCurrency(value) {
      let negativeSign = String(value).indexOf("-") === 0 ? "-" : "";
      if (value === "" && !negativeSign) return "";
      if (!isNaN(Number(value))) {
        value = Number(value).toFixed(this.precision);
      }

      value = String(value).replace(/\D/g, "");
      value = (value / Number(this.divisor)).toFixed(this.precision) + "";
      const parts = value.split(".");

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.thousands);
      return negativeSign + parts.join(this.decimal);
    },
    unformatCurrency(value) {
      return value.replace(/[^0-9-]/g, "");
    },
    parseCurrency(value) {
      let negativeSign = value.indexOf("-") >= 0 ? "-" : "";
      let sanitizedData = value.replace(/[^0-9]/g, "");
      if (!sanitizedData && !negativeSign) {
        return "";
      }
      if (sanitizedData === "-") {
        return Number(0).toFixed(this.precision);
      }
      const final = (Number(sanitizedData) / this.divisor).toFixed(
        this.precision
      );
      const result = negativeSign ? negativeSign + final : final;
      if (Number(result) === 0 && negativeSign) {
        return "-".concat(Number(0).toFixed(this.precision));
      }

      return Number(result);
    },
    onInput(event) {
      let inputValue = event.target.value;
      inputValue = this.unformatCurrency(inputValue);
      if (
        event.data === null &&
        (inputValue === "" || Number(inputValue) === 0)
      ) {
        event.target.value = "";
        return;
      }
      event.target.value = this.formattedValue;
    },
  },
};
</script>
<style scoped>
.form-control.input {
  height: calc(2em + 0.75rem + 2px);
  height: 2.5rem;
  box-shadow: none;
  border: 1px solid var(--gray-30);
  border-radius: 4px;
  font: normal normal normal 16px/20px Nunito Sans;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  position: relative;
}
.form-control.input input {
  flex-grow: 1;
  background-color: transparent;
  outline: none;
  border: none;
}
.form-control.input:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:focus-within {
  box-shadow: none;
  border: 1px solid #000000;
}
.form-control.input::placeholder {
  font: normal normal normal 16px/28px Nunito Sans;
  color: var(--gray-40);
}
.form-control.input.is-invalid {
  border: 1px solid var(--danger);
  background-image: none;
}
.form-control.input.is-invalid:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid var(--gray-20) !important;
}
.input-label {
  font: normal normal normal 16px/28px Nunito Sans;
}
.form-control.input.sm {
  height: calc(2em + 0.75rem + 2px);
  height: 1.75rem;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 9px 12px;
}
.form-control.input.sm input {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: "Nunito Sans";
}
.form-control.input.sm::placeholder {
  font: normal normal 600 12px/16px Nunito Sans;
  color: var(--gray-40);
}
</style>
