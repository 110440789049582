export default {
  //components
  components: {
    nav: {
      HomeDashboard: "Home",
      Dashboard: "Dashboard",
      Quotes: "Quotações",
      New: "Novo",
      Orders: "Pedidos",
      OrdersManagement: "Gestão",
      Import: "Importar",
      Create: "Criar",
      Rejected: "Rejeitado",
      VolumeReverse: "Volumes reversa",
      PickupDropoff: "Coleta / Entrega",
      DutiesAndTaxes: "Impostos & Taxas",
      Overpacks: "Pacotes",
      OverpacksManagement: "Gestão",
      Notifications: "Notificações",
      RulesConfiguration: "Configuração de Regras",
      TrackingPage: "Página de Rastreio",
      NotificationSettings: "Configurações de notificações de rastreamento",
      Settings: "Configurações",
      ShippingRules: "Regras de Envio",
      QuickStart: "Quick Start",
      Advanced: "Avançado",
      BoxesProducts: "Caixas e Produtos",
      Boxes: "Caixas",
      Printing: "Impressão",
      SalesChannels: "Canal de Vendas",
      Products: "Produtos",
      Users: "Usuários",
      Customers: "Clientes",
      Documents: "Documentos",
      Insurance: "Seguro",
      includeInsurance: "include insurance",
      Integrations: "Integrações",
      NewIntegration: "Nova Integração",
      CouriersList: "Correios",
      ChannelsList: "Canais",
      PriceTablesDeliveryTime: "Tabelas de Preços & Prazo de Entrega",
      Onboard: "Tutorial",
      Downloads: "Downloads",
      support: "Supporte",
      myExperience: "Completar minha experiência",
      Archives: "Arquivos",
      Accessibility: "Acessibilidade",
      Account: "Account",
      UserProfile: "My Profile",
      Company: "My Company",
      NotificationsList: "Notifications",
      Contracts: "Contratos",
      TrackingRuleNotifications: "Regra de Notificações de Rastreio",
      FiscalPayments: "Pagamentos Fiscais",
    },
    searchData: {
      settingsTracking: "Tracking Settings",
      home: "Home",
      userProfile: "Perfil Usuario",
      userProfileEdit: "Perfil Usuario Editar",
      company: "Minha Empresa Compania",
      resetPassword: "Reset Password",
      trackOrders: "Rastereio Pedido",
      newQuote: "Nova Quotacao",
      ordersList: "Pedido Lista",
      orderImport: "Importar Pedido",
      orderCreateNew: "Criar Novo Pedido",
      volumeReverseList: "Volume Reverse Lista Devolucao",
      orderPickupDropoff: "Pedido Pickup Dropoff",
      overpacksList: "Overpacks Lista",
      notificationsConfig: "Notificacoes Config",
      notificationsCreate: "Notificacoes Create",
      notificationsTracking: "Notificacoes Rastereio",
      notificationsSettings: "Configurações de notificações de rastreamento",
      notificationsList: "Notificacoes Lista",
      settingsQuickstart: "Configuracoes Quickstart",
      settingsShippingRules: "Configuracoes Shipping Rules",
      boxesProductsList: "Lista de Caixas e Produtos",
      boxesList: "Caixa Lista",
      settingsPrinting: "Configuracoes Printing",
      settingsSalesChannels: "Configuracoes Sales Channels",
      settingsProducts: "Configuracoes Produtos",
      usersList: "Usuario Lista",
      usersCreateNew: "Usuario Criar Novo",
      settingsCustomers: "Configuracoes Customers",
      settingsDocuments: "Configuracoes Documents",
      settingsInsurance: "Configuracoes Insurance",
      userBalance: "Saldo",
      settingsAccessibility: "Configuracoes acessibilidade",
      contracts: "Contratos",
      dashboard: "Dash Board",
      trackingRuleNotifications: "Regra de Notificações de Rastreio",
      fiscalPayments: "Pagamentos Fiscais",
    },
    baseTemplate: {
      logout: "Logout",
      topBar: {
        sideNav: "Lateral",
        sideNavPopover: "Muda para a barra de navegação SUPERIOR",
        topNav: "Superior",
        topNavPopover: "Muda para barra de navegação LATERAL",
        lastEdit: "Últimas edições",
        saved: "salvas",
        saving: "Salvando alterações",
      },
      profile: {
        myProfile: "Meu Perfil",
        myBalance: "Meu Saldo",
        myCompany: "Minha Empresa",
        fontSize: "FontSize: ",
        myContracts: "Meus Contratos",
        conditions: "Condições",
        myNotifications: "Minhas Notificações",
      },
      termsOfService: "Termos de serviço",
    },
    chart: {
      bar: "Barra",
      line: "Linha",
      area: "Área",
    },
    donutChart: {
      pie: "Torta",
      donut: "Rosquinha",
      polarArea: "Área Polar",
    },
    mapChart: {
      hoverOverState: "Passe o mouse sobre um estado",
    },
    onboardRow: {
      moreAbout: "Mais sobre {val} aqui.",
    },
    stepper: {
      step: "Etapa",
      agreeBeforeContinue:
        "Concorde com o resumo de custos antes de continuar.",
    },
    boxesList: {
      newBox: "nova caixa",
      newItem: "new item",
      specificationsOf: "Espeficicacoes da ",
      containsHarmfulProducs: "Esta caixa contém produtos nocivos",
      harmfilExample: "Exemplos de produtos nocivos: baterias de lítio",
      ifDoubt: "Se você está em dúvida, por favor ",
      seeHarmfulList: "confira a lista completa de materiais nocivos",
      moreAbourShippingRules:
        "Clique aqui para saber mais sobre Regras de Remessa",
      notContainsHarmfulProduct: "Não, esta caixa não contém produtos nocivos",
      containsHarmfulProduct: "Sim, esta caixa contém produtos nocivos",
      productData: "Dados do produto",
      saveProduct: "Salvar dados do produto para pedidos futuros",
      metric: "Métrico (cm & kg)",
      imperial: "Sistema Imperial (in & lb)",
      howAboutCreatingRule:
        "Sabia que podia automatizar algumas configuracoes? Que tal criar uma regra?",
    },
    modalEditSellerMetric: {
      title: "Trocar unidades de medida",
    },
    modalCreateCustomBox: {
      title: "Criar uma nova caixa",
    },
    tableFilters: {
      title: "Filtro",
      subTitle: "Selecione os valores abaixo para criar um filtro",
      addNewFilter: "Adicionar novo filtro",
      clearFilters: "Limpar Filtros",
      filterBy: "Filtrar por: ",
      filteredBy: "Filtrado por:",
      openFilterOptions: "Abrir Opções de Filtro",
      clickToClearFilter: "Clique Para Limpar Todos os Filtros",
      clickToFilter: "Clique Para Filtrar",
      trueText: "Verdade",
      falseText: "Falso",
    },
    selectTableColumns: {
      title: "Selecionar Coluna da Tabela",
      button: "Colunas da Tabela",
      unSelectAll: "Desmarcar Todas Colunas",
      selectAll: "Selecionar Todas Colunas",
      shownColumns: "Mostrar colunas",
      hiddenColumns: "Esconder colunas",
    },
    maxRows: {
      button: "Linhas",
    },
    pagination: {
      prevText: "Volta",
      nextText: "Próximo",
    },
    boxesOverview: {
      mustHaveOne: "As caixas devem ter pelo menos 1 item",
      packageDimenstions: "Dimensões do pacote",
    },
    destructiveModal: {
      deleteBox: {
        title: "Excluir caixa",
        cancel: "Não, cancelar",
        ok: "Sim, Excluir",
        information: "Você deseja excluir esta caixa?",
      },
      addCustomBox: {
        title: "Adicionar uma nova caixa",
        cancel: "Não, obrigado",
        ok: "Adicionar agora",
      },
      editMetric: {
        title: "Editar Modo Métrico",
        cancel: "Voltar",
        ok: "Confirmar",
      },
    },
    unsavedWorkModal: {
      discard: "Descartar",
      draft: {
        title: "Retomar o rascunho anterior?",
        resume: "Retomar",
        information: "Você tem um rascunho anterior atualizado pela última vez",
      },
      otherDraft: {
        title: "Aguarde enquanto estamos trabalhando",
        information: "Carregando... ",
      },
    },
    nbTable: {
      wereFound: "encontrado(s)",
      all: "Todos",
      onThisPage: "nesta página",
      isSelected: "está selecionado | estão selecionados",
      worksOnlyCurrentPage:
        "Este filtro só funciona para os {onThisPageItems} itens mostrados nesta página da tabela, não para todos os {totalItems} itens. Para fazer isso, use o botão de filtro",
      sortBtn:
        "Ordenar os itens nesta página da tabela. (Não ordena todos os itens encontrados)",
      properties: "Propriedades",
      tableOptions: "Opções da Tabela",
      changeVisibilityReorderColumns: "Mude a visibilidade e ordem das colunas",
    },
    footer: {
      poweredBy: "Desenvolvido por ",
      needHelp: "Precisa de ajuda",
      configureYourAcc: "Aprenda a configurar sua conta",
      moreAboutCompany: "Saiba mais sobre dados da empresa",
      moreAboutSignature: "Saiba mais sobre marca e assinatura",
      checkDocumentsAndTags:
        "Confira nossos guias sobre documentos e etiquetas",
      checkOurSupport:
        "Confira nossos guias ou envie uma mensagem ao nosso suporte",
      learMoreAboutInsurance: "Saiba mais sobre seguro",
      learnMoreAboutWeightAndMeasurements: "Saiba mais sobre pesos e medidas",
      learnMoreAboutAccessibilityOptions:
        "Leia mais sobre opções de acessibilidade",
    },
  },

  locales: {
    ptBR: "Português (BR)",
    en: "Inglês",
    cn: "Chinês",
  },

  //words
  warning: "Aviso",
  selectAnOption: "Selecione uma opção",
  remove: "Remover",
  next: "Próximo",
  back: "Voltar",
  generalData: "Dados gerais",
  suggestedAction: "Suggested Action",
  suggestedActions: "Ações Sugeridas",
  products: "Products",
  freight: "Freight",
  view: "View",
  cubedWeight: "Peso Cubado",
  actions: "Ações",
  confirmation: "Confirmação",
  confirm: "Confirme",
  cancel: "Cancelar",
  yes: "Sim",
  no: "Não",
  save: "Salvar",
  close: "Fechar",
  upload: "Enviar",
  uploading: "Enviando",
  update: "Atualizar",
  updating: "Atualizando",
  lastUpdate: "Última atualização",
  loading: "Carregando",
  ignore: "Ignorar",
  invalid: "Inválido",
  enable: "Habilitar",
  disable: "Desativar",
  edit: "Editar",
  create: "Criar",
  creating: "Criando",
  delete: "Excluír",
  deleted: "Excluído",
  clone: "Duplicar",
  resend: "Reenviar",

  site: "Site",
  postalCode: "CEP",
  country: "País",
  city: "Cidade",
  state: "Estado",
  insurance: "Seguro",
  generalDescription: "Descrição Geral",
  includeInsurance: "include insurance",
  hasInsurance: "Tem Seguro",
  currency: "Moeda",
  collectedTaxesCurrency: "Moeda dos Impostos",
  orderCurrency: "oeda do Pedido",
  senderWord: "Remetente",
  sender: "Remetente (DDP)",
  receiver: "Destinatário (DDU)",
  justReceiver: "Destinatário",
  taxDuties: "Taxas e Obrigações",
  box: "Caixa",
  item: "Item",
  items: "Itens",
  itemOrItems: "item | itens",
  width: "Largura",
  added: "adicionado",
  created: "criado",
  height: "Altura",
  length: "Comprimento",
  weight: "Peso",
  abbreviatedWidth: "L",
  abbreviatedHeight: "A",
  abbreviatedLength: "C",
  abbreviatedWeight: "P",
  emptyWeight: "Peso (Vazio)",
  totalWeight: "Peso Total",
  sku: "Sku",
  name: "Nome",
  fullName: "Nome Completo",
  email: "Email",
  placeholderEmail: "exemplo@email.com",
  connect: "Conectar",
  disConnect: "Desconectar",
  connected: "Conectado",
  disConnected: "Desconectado",
  phone: "Telefone",
  whatsapp: "Whatsapp",
  sms: "SMS",
  profile: "Perfil",
  password: "Senha",
  confirmPassword: "Confirmar Senha",
  confirmOldPassword: "Confirmar Seu Password Antigo",
  confirmEmail: "Confirmar Email",
  description: "Descrição",
  originCountry: "País de Origem",
  category: "Categoria",
  hsCode: "Código HS",
  unitPrice: "Preço Unitário",
  unitWeight: "Unit Weight",
  taxValue: "Valor do Imposto",
  quantity: "Quantidade",
  total: "Total",
  totalValue: "Valor Total",
  declaredValue: "Valor Declarado",
  salesTaxValue: "Valor do Imposto Sobre Vendas",
  dutiesTaxValue: "Valor do Imposto Alfandegário",
  freightValue: "Valor do frete",
  freightCost: "Custo de frete",
  productsValue: "Products Value",
  dutiesTax: "Taxa Obrigações",
  salesTax: "Taxa de Venda",
  totalCost: "Custo Total",
  deliveryTime: "Tempo de Entrega",
  insuranceCost: "Custo do seguro",
  taxCost: "Custo do imposto",
  ddpCost: "Custo DDP",
  details: "Details",
  page: "página",
  pages: "Páginas",
  new: "Novo",
  dispatch: "Enviar",
  dispatched: "Enviado",
  notDispatched: "Não despachado",
  label: "Etiqueta",
  correios: "Correios",
  others: "Outros",
  contract: "Contrato",
  handlingTime: "Prazo Para Envio",
  dimensions: "Dimensões",
  date: "Data",
  add: "Adicionar",
  rule: "Regra",
  optional: "Opcional",
  group: "Grupo",
  download: "Baixar",
  addedIn: "Adicionado em",
  invalidFileFormat: "Formato do arquivo inválido",
  dragDropFile: "Arraste e solte ou clique aqui",
  this: "esse",
  these: "esses",
  customer: "Cliente",
  local: "Local",

  order: "Pedido",
  orders: "Pedidos",
  orderNumber: "Número do Pedido",
  orderNumberSales: "Número do pedido de vendas",
  orderCreate: "Criar Pedido",
  orderImport: "Importar pedido",
  overpack: "Pacote de Expedição",
  overpacks: "Pacotes de Expedição",
  product: "Produto",
  quotes: "Quotações",
  taxes: "Impostos",
  taxPayer: "Contribuinte",
  customerName: "Nome do cliente",
  customerCountry: "País do Cliente",
  salesChannel: "Canal de Vendas",
  salesChannels: "Canais de Vendas",
  salesChannelOrderNumber: "Número do pedido do canal de vendas",
  salesChannelOrderNumberShort: "Nº do pedido do canal de vendas",
  trackingNumber: "Número de Rastreio",
  lastMileTrackingNumber: "Número de Rastreamento da Última milha",
  firstMileTrackingNumber: "Número de rastreamento da primeira milha",
  status: "Situação",
  shipmentConfirmation: "Confirmação de Embarque",
  overpacked: "Empacotado?",
  betweenDates: "Between Dates",
  startDate: "Start Date",
  endDate: "End Date",
  registrationDate: "Data de registro",
  startRegistrationDate: "Data de início de registro",
  endRegistrationDate: "Data final de registro",
  creationDate: "Data de criação",
  myCompanyName: "Minha Empresa",
  company: "Empresa",
  document: "Documento",
  documentType: "Tipo de Documento",
  documentNumber: "Número do Documento",
  cpf: "CPF",
  cnpj: "CNPJ",
  cpfOrCnpj: "CPF/CNPJ",
  taxNumber: "Número fiscal",
  number: "Número",
  address: "Endereço",
  addressNumber: "Número do Endereço",
  complement: "Complemento",
  comments: "Comentários",
  contactUs: "Fale conosco",
  complete: "Completo",
  incomplete: "Incompleto",
  erro: "Erro",
  sellerName: "Nome do Vendedor",
  sellerEmail: "Email do Vendedor",
  sellerPhone: "Telefone do Vendedor",
  sellerCountry: "País do Vendedor",
  sellerZipCode: "CEP do Vendedor",
  sellerCity: "Cidade do Vendedor",
  sellerState: "Estado do Vendedor",
  sellerAddress: "Endereço do Vendedor",
  sellerAddressNumber: "Número do Endereço do Vendedor",
  sellerAddressComplement: "Complemento do Endereço do Vendedor",
  sellerTaxNumber: "CPF/CNPJ do Vendedor",
  TaxNumberCpf: "Número fiscal é CPF",
  recommendedCnpj: "Pessoa Física",
  sellerWebsite: "Website do Vendedor",
  sellerLogoUrl: "URL da Logo do Vendedor (JPG or PNG)",
  isCommercialDestinationMandatory: "O destino é comercial?",
  sellerRfbCode: "Código RFB do Vendedor",
  sellerLegalName: "Nome Legal do Vendedor",
  collectedIcms: "ICMS recolhido",
  collectedIi: "Coletado II",

  macro: "Macro",
  macroStatus: "Macro Status",
  showMacro: "Mostrar Macro",
  micro: "Micro",
  microStatus: "Micro Status",
  showMicro: "Mostrar Micro",
  active: "Ativo",
  inactive: "Inativo",
  blocked: "Bloqueado",
  allowed: "Permitido",

  nobordist: "Nobordist",
  carrier: "Transportadora",
  seller: "Vendedor",

  selectOption: "Selecione uma opção",
  selectAll: "Selecionar Todos",
  selectAllOf: "Selecionar todos os",
  clear: "Limpar",
  clearSelected: "Limpar Selecionados",
  selected: "selecionados",
  typography: "Tipografia",
  ad: "Anúncio",

  quickActions: "Ações rápidas",
  necessaryAction: "Ação necessária",
  all: "Todos",
  allItems: "Todos os Itens",
  hello: "Olá",
  value: "Valor",
  totalItems: "Total de Items",
  destination: "Destino",
  table: "Tabela",

  checkOurTutorial: "Confira nosso tutorial",
  confirmDeletes: "Tem certeza que deseja excluir estes {val}?",
  confirmDelete: "Tem certeza que deseja excluir {val}?",
  balance: "Saldo",
  errorMessages: {
    isNotPDF: "O arquivo não é .pdf",
    tryAgain: "Por favor, verifique as informações e tente novamente",
    required: "Não pode ficar em branco",
    invalidCPF: "CPF inválido",
    invalidCNPJ: "CNPJ inválido",
    invalidDocument: "Documento inválido",
    invalidZIPCode: "CEP inválido",
    invalidPhone: "Telefone inválido",
    invalidEmail: "E-mail inválido",
    lowerMoney: "deve ser maior que 0,01",
    genericError: "Erro ao realizar a ação",
  },

  warningMessages: {
    notActiveAccount:
      "Sua conta não está ativa, por favor entre em contato com o administrador.",
  },

  orderStatus: {
    selectOption: "Selecione uma opção",
    new: "Novo",
    readyToShip: "Pronto para enviar",
    pickUp: "Pegar",
    shipped: "Enviado",
    inTransit: "Em trânsito",
    failedAttempt: "Tentativa falhada",
    outForDelivery: "Saíu para entrega",
    delivered: "Entregue",
    deliveryFailed: "Falha na entrega",
    partialDelivery: "Entrega parcial",
    partialDeliveryFailed: "Falha na entrega parcial",
  },

  hsCodes: {
    acessoryNoBattery: "Acessório (sem bateria)",
    acessory: "Acessório (com bateria)",
    audioVideo: "Audio Video",
    bagsLuggages: "Sacos e Bagagens",
    boardGames: "Jogos de tabuleiro",
    booksCollectibles: "Livros e Colecionáveis",
    cameras: "Câmeras",
    computersLaptops: "Computadores e laptops",
    documents: "Documentos",
    dryFoodsSupplements: "Alimentos Secos e Suplementos",
    fashion: "Moda",
    gaming: "Jogos",
    healthBeauty: "Saúde e Beleza",
    homeAppliances: "Eletrodomésticos",
    homeDecor: "Decoração da casa",
    jewelry: "Joias",
    mobilePhone: "Celular",
    petAccessory: "Acessórios para animais de estimação",
    shoes: "Sapatos",
    sportLeisures: "Esporte e Lazer",
    tablets: "Tablets",
    toys: "Brinquedos",
    watches: "Relógios",
    others: "Outros",
  },

  //countries
  countries: {
    unitedStates: "Estados Unidos",
    china: "China",
    france: "França",
    unitedKingdom: "Reino Unido",
  },

  kilograms: "Quilogramas (kg)",
  meters: "Metros (m)",
  inches: "Polegadas (in)",
  libras: "Libras (lb)",

  //pages
  quotePage: {
    title: "Faça uma cotação",
    subtitle:
      'Insira os dados e clique em "ver cotação" ao final para checar prazos e valores',
    shipmentInformation: "Informação de envio",
    shipFrom: "Envio de (origem)",
    pleaseEnterData: "Please enter data",
    shipDestination: "Enviar para (destino)",
    orderInformation: "Informações do pedido",
    boxInformation: "Informações da Caixa",
    createOrder: "Criar pedido",
    goToOrderCreate:
      "Gostaria de criar um pedido? Clique para continuar de onde parou",
  },

  shippingRulesPage: {
    title: "Regras de Remessa",
    subtitle:
      "Nesta página você poderá definir valores padrão para campos específicos, desse modo sempre que você acessar uma determinada página ela já estará com os campos definidos pré-preenchidos.",
    newRule: "Nova regra",
    editRule: "Editar regra",
    guide: {
      title:
        "Para começar, adicione ao menos uma condição na coluna da esquerda",
      subtitle:
        "Se preferir, clique no botão a seguir para ver as regras já configuradas.",
      goToShippingRules: "Ir para regra de remessas",
    },
    shippingRuleTab: {
      description:
        "Confira e altere (se necessário) campos que você configurou anteriormente. Regras com maior prioridade podem sobrescrever regras de menor prioridade.",
      createShippingRule: "Criar regra de remessa",
    },
    newRuleTab: {
      firstStepTitle: "Defina as condições da sua regra",
      firstStepDescription:
        "Escolha uma condição para definir quando sua ação será chamada (ex.: todos os pedidos).",
      lastStepTitle: "Defina a ação que irá ocorrer",
      lastStepDescription:
        'Neste campo você irá escolher o que deve ocorrer de acordo com a sua condição. Por exemplo, para a condição "Todos os pedidos" você pode selecionar "sempre enviar do Local X".',
      newAction: "Nova Ação",
      defineAnAction: "Definir uma ação",
      ruleName: "Nome da regra",
      newCondition: "Nova condição",
    },
    fields: {
      priority: "Prioridade",
      name: "Nome",
      condition: "Condição",
      action: "Ação",
      expiration: "Validade",
      is_active: "Ativo",
      quickActions: "Ações rápidas",
    },
    tips: {
      separatedByCommas: "separados por vírgulas",
      itemSku: "Você pode passar uma lista de skus, separados por vírgula",
      customerPostalCode:
        "Você pode passar uma lista de códigos postais, separados por vírgula",
      defineFreightValueToBe:
        "O valor informado será na moeda do pedido: USD ou BRL",
      priority:
        "No caso de 2 regras contraditórias, a prioridade definirá qual delas aplicar",
      expiration: "Até quando a regra deve ser aplicada",
      is_active:
        "Caso queira criar a regra sem aplicá-la por enquanto, basta desmarcar este campo",
    },
    operator: "Operador",
    value: "Valor",
    conditions: {
      allShipments: "Todos os envios",
      containsCategory: "Contém categoria",
      destinationCountry: "País de destino",
      destinationState: "Estado de destino",
      itemSku: "SKU do item",
      numberOfItems: "Número de itens",
      receiverPostalCode: "Código Postal do Destinatário",
      sellerPrice: "Preço do vendedor",
      shipmentWeight: "Peso do envio",
      channel: "Canal",
    },
    actions: {
      alwaysSortByCourier: "Sempre ordenar por Courier",
      sortByCourierIf: "Ordenar por Courier se nenhum dado fornecido",
      alwaysUseIncoterm: "Sempre usar este Incoterm",
      useIncotermIf: "Usar este Incoterm se nenhum dado fornecido",
      alwaysUseThisCourier: "Sempre usar este Courier",
      useThisCourierIf: "Usar este Courier se nenhum dado fornecido",
      neverUseCourier: "Nunca usar Courier(s)",
      alwaysUseThisPackage: "Sempre usar este Pacote",
      useThisPackageIf: "Usar este Pacote se nenhum dado fornecido",
      neverUsePackage: "Nunca usar Pacote(s)",
      alwaysShipFrom: "Sempre enviar de",
      shipFromIf: "Se nenhum dado fornecido, enviar de",
      defineFreightValueToBe: "Define frete fixo de",
      freightValueFromCost: "Define valor de frete com custo de frete",
    },
    conditionOperators: {
      greaterThan: "Maior que",
      greaterThanOrEqualTo: "Maior ou igual a",
      lessThan: "Menor que",
      lessThanOrEqualTo: "Menor ou igual a",
      EqualsTo: "Igual a",
      isAnyOf: "Qualquer um",
      isNoneOf: "Não é nenhum",
      startsWith: "Comece com",
      shipmentContains: "A remessa contém",
      shipmentDoesNotContain: "A remessa não contém",
      shipmentOnlyContains: "A remessa contém apenas",
    },
    rulePriorityOptions: {
      veryHigh: "Muito alta",
      high: "Alta",
      medium: "Média",
      low: "Baixa",
      veryLow: "Muito baixa",
    },
  },
  pickupPage: {
    title: "Minhas coletas/despachos",
    pickupDate: "Coleta agendada para",
    nextPickup: "Próxima Coleta",
    createPickup: "Criar Coleta",
    deletePickup: "Apagar Coleta(s)",
    informDate: "Informe a data para criar coleta",
    sureDeletePickup: "Tem certeza que quer apagar essa(s) coleta(s)",
    informType: "Informe o Tipo, e para Coleta, escolha a data",
    pu: "Coleta",
    do: "Despacho",
    nothing: "Nada",
    fields: {
      orderId: "ID Ordem",
      orderNo: "Num Ordem",
      type: "Tipo",
      pickupID: "ID Coleta",
      pickupNumber: "Número Coleta",
      pickupData: "Data Coleta",
    },
  },
  ordersListPage: {
    title: "Gerenciamento de pedidos",
    newOrder: "Novo pedido",
    createOrder: "Criar pedido",
    quotation: "Cotação",
    ordersDeleted: "Order(s) deleted",
    found: "nenhum pedido encontrado | pedido | pedidos",
    orderNo: "Nº do Pedido",
    salesChOrderNo: "Nº do Pedido do Canal de Vendas",
    value: "Valor",
    incoterm: "Termos Internacionais de Comércio",
    freightCost: "Custo de frete",
    postalCode: "CEP",
    salesChannel: "Canal de vendas",
    customerName: "Nome do cliente",
    country: "País",
    city: "Cidade",
    contractName: "Nome do contrato",
    creationDate: "Data de criação",
    estimatedDeliveryDate: "Data de entrega estimada",
    label: "Etiqueta",
    firstMileLabel: "Etiqueta da primeira milha",
    invoice: "Fatura",
    shipperConfirmationPDF: "PDF de confirmação do remetente",
    dangerousGoodPDF: "PDF de produtos perigosos",
    status: "Situação",
    trackingNumber: "Numero de rastreio",
    insuranceCoverage: "Cobertura do seguro",
    overpacked: "Empacotado",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    lastMileCarrierNumber: "Número da transportadora da última milha",
    firstMileCarrier: "Primaira milha da Transportadora",
    firstMileTrackingNumber: "Número de rastreamento da primeira milha",
    mini: "MINI",
    packet: "PACKET",
    dispatchOrders: "Despachar Pedido(s)",
    dispatchOrderError: "Erro ao despachar pedido(s): ",
    downloadOrders: "Baixar Pedidos",
    downloadVolumes: "Baixar Volumes",
    withoutOverpack: "Sem Pacote",
    trackNumberNotFound: "Nº de Rastreio não encontrado",
    lastMileCarrierNotFound: "Última operadora de milha não encontrada",
    andMore: "e {val} mais",
    generateOverpack: "Gerar Pacote(s)",
    actions: "Ações",
    downloadOrderCosts: "Download Order Costs",
    deleteOrders: "Remover Pedido(s)",
    generateLabel: "Gerar rótulo(s)",
    generateInvoice: "Gerar fatura(s)",
    generateDocument: "Gerar Documento",
    generatePackingSlip: "Gerar nota de embalagem(s)",
    generateOnePackingSlip: "Gerar guia de remessa",
    generateDangerousMultiple: "Gerar etiquetas de produtos perigosos UN3481",
    ifDangerousPersonal: "Contato em relação aos produtos perigosos",
    generateCN35: "Gerar CN35",
    printingAll: "Imprimindo tudo",
    toTrackingPage: "Para Página de Rastreio",
    confirmDeleteOrder: "Tem certeza de que deseja excluir estes pedidos?",
    collectedIcms: "ICMS recolhido",
    collectedIi: "II recolhido",
    isCommercialDestination: "É destino comercial",
    sellerRfbCode: "Código RFB do Vendedor",
    sellerLegalName: "Razão Social do Vendedor",
    downloadModal: {
      title:
        "Todas os downloads em processamento estão na pagina de downloads.",
      startDownload: "Começar Download",
    },
    sellersName: "Nome do vendedor",
    sellerTaxNumber: "CPF/CNPJ do vendedor",
    sellerInformations: "Informações do vendedor",
    cn35GenerateFailed: "Falha ao gerar cn35 para {orderId}.",
    goToDownloadPage:
      "Arquivo de download criado, você pode ir para a página de download para verificar",
  },
  ordersImportPage: {
    title: "Importar pedidos",
    selectFile: "Encontre seu arquivo .csv e arraste até a área a seguir.",
    downloadHere: "Baixe aqui",
    instructionsXLSFile: "o arquivo XLS de instruções",
    sendFile: "Enviar arquivo",
    downloadExampleFile: "Baixar planilha modelo",
  },
  importResultPage: {
    title: "Resumo de importação",
    summary: "Resumo",
    cancelImport: "Cancelar Importação",
    totalProduct: "Total de produtos",
    totalOrders: "Total de pedidos",
    totalValid: "Total válido",
    totalRejected: "Total rejeitado",
    noValidProducts:
      "Nenhum produto válido foi encontrado, verifique suas informações e tente novamente.",
    gotoProducts: "Ir para Produtos",
    importLog: "Importar Log",
    productNumber: "Número do produto",
    productName: "Nome do produto",
    orderNumber: "Numero do pedido",
    status: "Situação",
    log: "Log",
    success: "Sucesso",
    error: "Erro",
    confirmCancel: "Tem certeza de que deseja cancelar esta importação?",
    return: "Retornar",
  },
  orderCreatePage: {
    documentsAreMissing: "Alguns documentos importantes estão faltando!!!",
    includeDocuments: "Adicione todos os documentos para criar um pedido.",
    orderCreated: "Pedido criado. ",
    orderUpdated: "Pedido atualizado. ",
    rejectOrderUpdated: "Pedido rejeitado atualizado. ",
    dontForgetOverpack:
      "Não se esqueça de criar um pacote! | Nao se esqueça de dispachar o pedido!",
    receiver: "Destinatário",
    sender: "Sender",
    shipment: "Envio",
    courier: "Correio",
    overview: "Visão geral",
    boxes: "Caixas",
    finish: "Concluir",
    receiverInformation: {
      title: "Por favor, informe os dados do destinatário",
      receiverName: "Nome do destinatário",
      helpText:
        "Here, please put all the information of whom will receive the products",
      saveContact: "Salvar contato",
      cpfOrCnpjHelpText:
        "Número fiscal utilizado para identificar o cliente perante a alfândega do país de destino ({country}). Esse número é chamado {documentName} e tem o seguinte formato: {documentFormat}",
      postalCodeHelpText:
        "Zip code of the customer's locality. In {country}, this number has following format: {cepFormat}",
      customerPhoneHelpText: "Only the phones from {country} are accepted",
      customeraddressHelpText: "Only the address street, avenue, place, ...",
      customeraddressnumberHelpText:
        "Only the street number. If it contains a letter (123A for example), put the number here and the letter in the address complement field",
      customeraddresscomplementHelpText:
        "Additional information to the street number, building name, floor, apartment, door, mailbox, ...",
      customeraddressreferenceHelpText:
        "Point of reference to help the postman (Behind the bakery, for example)",
      rfbCodeHelpText: "código RFB é obrigatório quando a order é PRC",
      sellertaxnumberHelpText:
        "Tax number used to identify the sender towards the destination country's customs. Sometimes called VAT",
      sellerphoneHelpText:
        "Por favor, inclua o código do país junto com um '+' (+55991888282, por exemplo)",
      selleraddressHelpText: "Somente o endereço da rua, avenida, local, ...",
      selleraddressnumberHelpText:
        "Somente o número da rua. Se contiver uma letra (por exemplo, 123A), coloque o número aqui e a letra no campo de complemento de endereço",
      selleraddresscomplementHelpText:
        "Additional information to the street number, building name, floor, apartment, door, mailbox, ...",
      remetenteiscpfHelptext:
        "Se o número de imposto pertencer a uma pessoa física, marque esta caixa. Se pertencer a uma empresa, deixe esta caixa desmarcada",
      phoneSameCountry:
        "O país do telefone do destinatário deve ser o mesmo que o país",
      customeraddressPlaceholder: "Rua Avenida da Vitória",
      customeraddressnumberPlaceholder: "1234",
      customeraddresscopmlementPlaceholder: "Apartamento 5, 17º andar",
      customeraddressreferencePlaceholder: "Atrás da padaria",
      sellerphonePlaceholder: "+17728991919",
      sellerpostalcodePlaceholder: "CA-1234",
      sellertaxnumberPlaceholder: "1234567890",
      selleraddressPlaceholder: "Rua Avenida da Vitória",
      selleraddressnumberPlaceholder: "1234",
      selleraddresscomplementPlaceholder: "Tower A, 6th floor",
      sellerwebsitePlaceholder: "www.seller.com",
      sellerLogoUrlPlaceholder: "https://www.seller-site.com/images/logo.png",
    },
    originInformation: {
      title: "Informações de origem",
      helpText:
        "Coloque aqui todas as informações de quem irá enviar os produtos",
      buttonCompanyInfo: "Preencha com as informações da Empresa",
      infoPhone: "Inclua o código do país. Ex: +55 22 22222222",
    },
    shipmentInformation: {
      title: "Por favor, informe os dados do produto a ser enviado.",
      helpText:
        "Coloque aqui todas as informações sobre produtos e caixas, bem como informações gerais",
      landedCost: "Custo com Imposto",
      PRC: "Remessa Conforme (PRC)",
      landedCostWarning: "Obrigatório",
      PrcWarning: "Obrigatório",
      includeInsuranceNameHelpText:
        "Se você deseja incluir um seguro, marque esta caixa",
      ordernumberHelpText:
        "Número único para você identificar o pedido entre todos os seus pedidos",
      saleschannelHelpText: "O canal de vendas que encarregou você do pedido",
      saleschannelordernumberHelpText:
        "Número de pedido secundário, para seu próprio uso",
      incotermHelpText:
        "indica quem pagará os impostos, o cliente (DDU) ou o remetente (DDP)",
      landedcostHelpText: "Os preços dos itens incluem todos os impostos",
      currencyHelpText:
        "Todos os valores de preço informados abaixo serão considerados nesta moeda",
      taxesCurrencyHelpText:
        "Os valores de preço informados para os impostos ICMS e II",
      itemdescriptionHelpText:
        "descrição detalhada do item, deve ser o mais abrangente possível e incluir marca e modelo, pois essas informações serão usadas para declaração alfandegária",
      itemnameHelpText: "descrição curta do item, para seu próprio uso",
      itemhscodeHelpText:
        "código tarifário harmonizado, 6 ou 8 dígitos identificando a natureza do produto",
      itemskuHelpText: "para seu próprio uso",
      itemboxHelpText:
        "Indica em qual caixa este item será inserido. Se você tiver apenas uma caixa, preencha com '1'",
      boxemptyweightHelpText:
        "o peso da caixa vazia apenas, sem nenhum produto dentro dela",
      USBcharger: "carregador USB",
      productDescriptionPlaceholder:
        "Carregador USB mini 2500 mA Sony - modelo MXS2009",
      knowYouCan: "Você sabia que você pode",
      registerBoxes: "registrar suas caixas",
    },
    courierInformation: {
      title: "Escolha seu correio",
      helpText:
        "Here, please choose a courier and fill in the last pieces of information",
      fillAllOrderInfo:
        "Preencha todas as informações do pedido antes de solicitar um orçamento",
      changeToDdu: "Para esse contrato e valor, use DDU",
      changeToDdp: "Para esse contrat, use DDP",
      tooLowtoCover:
        "Valor dos itens é muito baixo para cobrir frete e impostos.",
      agreeSummary: "Eu concordo com este resumo de custos (obrigatório)",
      costSummary: "RESUMO DE CUSTOS",
      selectedContract: "Selected Contract",
      getQuote: "Get a Quote",
      newContract: "Cadastrar novo parceiro",
      lastmilenumberHelpText:
        "O número que devemos usar para rastrear o pedido com nossos parceiros",
      dutiestaxHelpText:
        "Imposto nacional de importação sobre os produtos e o frete",
      salestaxHelpText: "Imposto nacional sobre as vendas",
      freightvalueHelpText:
        "O frete que será efetivamente declarado à alfândega e aparecerá em todos os documentos legais. Você é responsável por estas informações, recomendamos fortemente usar o mesmo valor presente no campo de custo de frete.",
      freightcostHelpText:
        "O frete calculado por nosso sistema, correspondendo ao valor que você será cobrado por {val} por este pedido",
      lastMileNumberPlaceholder: "NX123456789",
      fields: {
        item: "Caixa/Produto",
        quantity: "Quantidade",
        taxed: "Unidade com Imposto",
        subtotalTaxed: "Subtotal com Imposto",
        untaxedDollar: "Unidade sem Imposto",
        subtotalUntaxed: "Subtotal sem Imposto",
      },
      fieldsSummary: {
        totalProductsValue: "Valor Total Sem Impostos",
        freightValue: "Valor Do Frete",
        totalDeclaredValue: "Valor Total Declarado",
        taxes: "Impostos",
        totalValueWithTaxes: "Valor Total Com Impostos",
      },
      quoteHelpTexts: {
        freightCost:
          "O frete calculado pelo nosso sistema, correspondendo ao valor que você será cobrado por {val} por este pedido",
        dutiesTax: "Imposto nacional de importação sobre os produtos e o frete",
        salesTax: "Imposto nacional sobre as vendas",
        totalCost:
          "Valor total que você terá que pagar, incluindo frete e impostos (apenas DDP)",
        deliveryTime:
          "Tempo estimado entre a criação do pedido e a entrega final ao cliente",
      },
    },
    footer: {
      howCreateOrder: "Como criar pedidos",
    },
    overviewInformation: {
      title: "Visão geral do pedido",
      moreInfo: "Mais informações",
    },
    stepsCardDetail: {
      stepOne: "Dados do pedido",
      stepTwo: "Conferência",
      stepThree: "Prosseguir",
      stepFour: "Etiqueta e nota",
      stepFive: "Despacho",
      stepSix: "Finalização",
    },
    titleColumns: {
      boxProducts: "CAIXAS E PRODUTOS",
      carrier: "TRANSPORTADORA",
      estimatedDelivery: "ENTREGA ESTIMADA",
      totalPartial: "TOTAL PARCIAL",
    },
    orderSummary: "Ver resumo do pedido",
  },
  ordersRejectedPage: {
    title: "Pedidos rejeitados",
    dateImport: "Data de importação",
    content: "Conteúdo",
    error: "Erro",
  },
  dutiesAndTaxesPage: {
    title: "Impostos e Taxas",
    orderStatus: "Status do pedido",
    status: {
      new: "Novo",
      solved: "Resolvido",
      inProgress: "Em andamento",
      waitingForPayment: "À espera de pagamento",
      priority: "Prioridade",
      needSelllerAction: "Precisa de ação do vendedor",
      received: "Recebida",
      sentAgain: "Enviado novamente",
      paymentDone: "Pagamento efetuado",
      pending: "Pendente",
      paidExternal: "Pago externamente",
    },
  },
  DutiesAndTaxesViewPage: {
    generalInformation: "Informações Gerais",
    taxesInformation: "Informações Fiscais",
    customerInformation: "Informação do Cliente",
    creationDate: "Data de criação do Volume",
    orderNumber: "Número do pedido",
    customerName: "Nome do cliente",
    customerCountry: "País do cliente",
    tracking: "Numero de rastreio",
    incoterm: "Termos Internacionais de Comércio",
    estimatedTax: "Valor do impostos estimado",
    realTax: "Valor do impostos real",
    payment: "Status de pagamento",
    message: "Mensagem",
    expectedTax: "Imposto Estimado",
    productsValue: "Valor do Produto",
    paymentCertificate: "Payment Certificate",
  },
  orderViewPage: {
    title: "Número do pedido",
    generateFirstMileLabel: "Gerar First Mile Label",
    generateLastMileLagel: "Gerar Last Mile Label",
    documentsLabel: "Documentos e etiqueta",
    suggestedAction: "Ação sugerida",
    orderData: "Order Data",
    shippingInformation: "Informações de envio",
    customerInformation: "Informações do cliente",
    generateFMLabel: "Gerar etiqueta da primeira milha",
    generateBoxLabel: "Gerar etiqueta da caixa",
    generateLMTN: "Gerar etiqueta da última milha",
    generateInvoice: "Gerar fatura",
    boxData: "Dados da caixa",
    trackingDetails: "Detalhes de rastreamento",
    orderNumber: "Número do Pedido",
    newOrderNumber: "Novo Número do Pedido",
    orderNumberMustBeUnique: "O número do pedido deve ser único",
    observations: "Observações",
    shipperConfirmation: "Confirmação do Remetente",
    shipping: "Envio",
    incoterm: "Termos Internacionais de Comércio",
    type: "Tipo",
    standard: "Padrão",
    haveInsurance: "Tem Seguro",
    overpackReference: "Referência de Pacote",
    shipFrom: "Vindo de",
    totalVolumes: "Volumes Totais",
    volume: "Volume",
    currentStatus: "Situação atual",
    trackingHistory: "Histórico de rastreio",
    carrierOrShipper: "Transportadora / Remetente",
    trackingStatus: "Situação de Rastreio",
    message: "Mensagem",
    attachment: "Anexo",
    addStatus: "Adicionar status",
    statusToAdd: "Status para adicionar",
    editOrder: "Editar Pedido",
    cloneOrder: "Clonar Pedido",
    deleteOrder: "Remover Pedido",
    isReversable: "Este contrato não permite criar volumes reversos",
    orderVolumes: "Volumes do Pedido",
    createVolumeReverse: "Criar Volume Reversa",
    volumesInReverse: "Volumes Already in Reverse Process",
    noVolumes: "Todos os volumes já estão em processo de reversa.",
    noReversedVolumes: "Nenhum volume em reversa.",
    deleteConfirm: "Tem certeza que quer deletar esse pedido?",
    fieldsOrderCosts: {
      freight_cost: "Custo do Frete",
      duties: "Taxas",
      insurance: "Seguro",
      general_costs: "Custos Gerails",
      fines: "Multas",
    },
    fieldsVolumeItems: {
      unitProducts: "Unit Products",
      unitFreight: "Unit Freight",
      unitTax: "Unit Tax",
    },
  },
  overpacksListPage: {
    title: "Meus overpacks",
    found: "nenhum pacote encontrado | pacote | pacotes ",
    readyToDispatch: "Pronto para despachar",
    downloadOverpacks: "Baixar pacotes",
    actions: "Ações",
    updateFirstMileInformations: "Atualizar informações da primeira milha",
    deleteOverpacks: "Excluir embalagem(s)",
    setOverpackAs: "Definir embalagem(s) como ",
    dispatchOverpack: "Despachar embalagem(s)",
    generateLabel: "Gerar rótulo(s)",
    carrierName: "Nome da transportadora",
    overpackHasNotDispatched:
      "Uma dos pacotes selecionados ainda não foi despachado.",
    OverpackHasAlreadyDispatched:
      "Um dos pacotes selecionados já foi despachado.",
    trackingNumber: "Número de rastreio",
    generateLabelAndInvoice: "Gerar etiqueta e invoice",
  },
  overpackViewPage: {
    title: "Referência do Pacote",
    dispatchOverpack: "Despachar Pacote",
    countryDestination: "País de destino",
    totalVolumes: "Volumes totais",
    totalOrders: "Total de pedidos",
    totalOrdersValue: "Valor total dos pedidos",
    totalWeight: "Peso total",
    contractName: "Nome do contrato",
    dispatchDate: "Data de envio",
    shipFrom: "Vindo de",
    originHub: "Origem Hub",
    addOder: "Adicionar pedido",
    noOrderToOverpack: "Nenhum pedido disponível para empacotar",
    carrierName: "Nome da Transportadora",
    noCarrier: 'Não há frete para contratos "Airport to Door"',
  },
  groupConfigurationPage: {
    title: "Configurações",
    secondaryTitle: "Notificações",
    configuration: "Configuração",
    group: "Grupo",
    groups: "Grupos",
    menu: "Menu",
    active: "Ativo",
    inactive: "Inativo",
    createConfiguration: "Criar configuração",
    deleteConfiguration: "Excluir configuração",
    addGroup: "Adicionar grupo",
    updateGroup: "Atualizar grupo",
    deleteGroup: "Excluir grupo",
    groupInformation: "Informações do Grupo",
    choosenSalesChannels: "Canais de vendas escolhidos",
    salesChannelsWithoutGroups: "Canais de vendas sem grupos",
    noChoosenSalesChannels: "Nenhum canal de vendas escolhido",
    noSalesChannelsWithoutGroups: "Nenhum canal de vendas sem grupos",
  },
  createGroupConfigurationPage: {
    title: "Criar configuração",
    countries: "Países",
    customer: "Cliente",
    hide: "ocultar",
    checkpoints: "Pontos de verificação",
    addConfiguration: "Adicionar configuração",
    showSeller: "Mostrar vendedor",
    showSalesChannels: "Mostrar canais de vendas",
    chosenCountries: "países escolhidos",
    newMenu: "Novo menu",
    existingMenu: "Menu existente",
  },
  groupConfigurationEditPage: {
    title: "Editar configuração",
    discardChanges: "Descartar alterações",
    leaveWithoutSaving: "Sair sem salvar",
    leave: "Sair",
    stay: "Ficar",
  },
  groupConfigurationView: {
    title: "Configuração",
  },
  trackingPageSettingsPage: {
    title: "Configurações da página de rastreamento",
    firstText:
      "Marque suas páginas de rastreamento para que seus clientes tenham uma experiência perfeita ao verificar o status de seus pedidos.",
    secondText: "Suas informações podem ser editadas a qualquer momento em",
    simulateYourTrackingPage: "Simule sua página de rastreamento",
    showLogo: "Mostrar logotipo",
    showContractInfo: "Mostrar informações de contato",
    textShowContractInfo:
      "Página de contato, mídia social, e-mail e número de telefone.",
    displayAdvertising: "Exibição de publicidade",
    textDisplayAdvertising:
      "Dimensões em pixels mínimas e recomendadas: 1200 x 630.",
    uploadedImage: "Imagem carregada:",
    displayFootnote: "Exibir nota de rodapé",
    footnote: "nota de rodapé",
    footnotePlaceholder: "Obrigado por apoiar nosso negócio!",
    settingsUpdated: "Configurações atualizadas",
    trackingPageSimulation: "Simualção da Página de rastreio",
    orderNumber: "Número do Pedido",
    estimatedDelivery: "Entrega estimada",
    latestStatus: "Última Situação",
    dateExample: "23 de agosto de 2020",
    orderHasBeenCreated: "O pedido foi criado",
    shippingInformation: "Informação de enviossss",
    correiosStandard: "Padrão CORREIOS",
    trackingNumber: "Numero de rastreio",
    socialLinksLabel: "Links sociais",
    colorSchemeLabel: "Esquema de cores",
  },
  trackingSettingsPage: {
    title: "Página de Rastreamento",
    subtitle:
      "Personaliza a página de rastreamento para ficar a cara da sua empresa",
    trackingPagePreview: "Preview da Página de Rastreamento",
    viewInFullScreen: "Ver página em tela cheia",
    colors: {
      title: "Esquema de cores",
      subtitle: "Escolha o esquema de cores da página",
      foregroundColor: "Cor de primeiro plano",
      backgroundColor: "Cor de fundo",
      text_color: "Cor do texto do cabeçalho",
      returnsToDefaultColors: "Retornar para as cores padrão",
    },
    brand: {
      title: "Mostrar marca",
      subtitle: "Para outra imagem",
      changeBrandIn: "altere a marca em",
      thereIsNoRegisteredBrand:
        "O que acontece se não houver uma marca cadastrada?",
      insertCompanyName: "Inserir o nome da empresa",
      insertOtherText: "Inserir outro texto:",
      insertOtherTextPlaceholder: "Outro Nome",
      onClickBrand: "O que acontece ao clicar na marca da empresa?",
      forwardToCompanyWebsite: "Encaminhar para o site da empresa",
      brandIsNotClickable: "Nada. A marca não é clicável.",
    },
    contacts: {
      title: "Mostrar informações de contato",
      subtitle: "Defina quais informações de contato mostrar.",
      selectSocialNetwork: "Selecione as redes sociais que gostaria de mostrar",
      contactUsClick: 'Selecione o que acontece ao clicar em "Fale Conosco"',
      sendMailTo: "Enviar email para:",
      forwartToWebsite: "Encaminhar para website:",
    },
    ad: {
      title: "Incluir anúncio",
      subtitle: "Adicionar um anúncio sobre sua empresa.",
      fileDropLabel:
        "Insira uma imagem com seu anúncio (recomendado: 932px x 240px)",
      chooseImage: "Clique ou arraste aqui sua imagem (.jpeg ou .png)",
      mainText: "Texto principal (caso deixe vazio, ele não irá aparecer)",
      linkText: "Texto do link (caso deixe vazio, ele não irá aparecer)",
      linkTextPlaceholder: "Conhecer agora",
      bannerLink: "Link do banner",
    },
    footer: {
      title: "Rodapé",
      subtitle: "Define o que inserir no rodapé",
      footnote: "Texto do rodapé",
      onClickAction:
        "Ao clicar no texto do Rodapé encaminhar para o site da empresa.",
    },
  },
  settingsPage: {
    title: "Configurações de Notificações de Rastreamento",
    subtitle:
      "Esta página permite que você altere algumas configurações para suas notificações de rastreamento, configuradas na página {link}",
    sendEmail: "Enviar e-mail",
    emailConfirmed: "E-mail confirmado",
    domainVerified: "Domínio verificado",
    howtoVerifyDomain: "Como verificar o domínio",
    textHowtoVerifyDomain1: "Para verificar seu domínio",
    textHowtoVerifyDomain2:
      "vá ao seu provedor de DNS e adicione um registro TXT com os seguintes valores:",
    textHowtoVerifyDomain3: "Isso nos permitirá enviar e-mails do seu domínio.",
    textResendVerificationEmail: "Reenviar e-mail de verificação",
    textYouWillSendVerificationEmail:
      "Você enviará um e-mail de verificação para",
    textSendFromEmail:
      "Enviaremos notificações de entrega a partir deste endereço de e-mail:",
    sendSms: "Enviar SMS",
    textSendSms:
      "Por padrão, enviaremos notificações de SMS do número da Nobordist Company.",
    sendFromYourCompanyPhoneNumber:
      "Se você quiser enviar do número de telefone da sua empresa, entre em contato com seu representante de vendas.",
    smsCostsWillBeCharged: "Os custos de SMS serão cobrados.",
    sendWhatsapp: "Enviar Whatsapp",
    textSendWhatsapp:
      "Por padrão, enviaremos notificações do Whatsapp a partir do número da Nobordist Company.",
    whatsappCostsWillBeCharged: "Os custos do Whatsapp serão cobrados.",
    sendWebhook: "Enviar Webhook",
    textSendWebhook:
      "Escolha aqui para qual endpoint você deseja enviar suas notificações de rastreamento por webhook",
    webhook: "Webhook",
    url: "Url",
    key: "Chave de autenticação",
    header: "Nome do cabeçalho de autenticação",
    addWebhookUrl: "Adicionar URL de webhook",
    disabling: "Desativando",
    enabling: "Ativando",
    editDomain: "Editar Domínio",
    notificationEmail: "E-mail de Notificação",
    noPublicEmail:
      "E-mails de domínios públicos, como yahoo, gmail, outlook, etc., são proibidos",
    enableSmsText:
      "A opção de SMS é opcional e acarretará custos adicionais no uso da plataforma Nobordist.",
    pleaseConfirmAdditionalCosts:
      "Por favor, confirme que você concorda com esses custos adicionais!",
    areYouSureWantToEnable: "Tem certeza de que deseja ativar?",
    areYouSureWantToDisable: "Tem certeza de que deseja desativar?",
    disableSmsText:
      "Nenhum SMS será enviado, mesmo que você os configure na página {link}.",
    disableWhatsappText:
      "Nenhum Whatsapp será enviado, mesmo que você os configure na página {link}.",
    textWarnCostsWhats:
      "A opção WhatsApp é opcional. A validação do seu modelo com o Facebook é paga e acarretará custos adicionais no uso da plataforma Nobordist. Por favor, confirme que você concorda com esses custos adicionais! <br> <br> Tem certeza de que deseja {val}",
    enableWhatsappText:
      "A opção WhatsApp é opcional e acarretará custos adicionais no uso da plataforma Nobordist.",
    method: "Método",
    value: "Valor",
    headers: "Cabeçalhos",
    endpoint: "Endpoint",
    noEmailConfigured: "Nenhum e-mail configurado",
    addEmail: "Adicionar e-mail",
    emailFormatIsInvalidOrNotAllowed:
      "Formato de e-mail é inválido ou não permitido",
    successfullyUpdatedSetting: "Configuração atualizada com sucesso",
    successfullyResentEmailVerification:
      "E-mail de verificação reenviado com sucesso",
    hostname: "Nome do host",
    type: "Tipo",
  },
  settingsViewPage: {
    title: "Perfil do usuário",
    warning: "Remover Perfil",
  },
  settingsEditPage: {
    title: "Atualizar perfil de usuário",
  },
  quickStartPage: {
    title: "Configurações de início rápido",
    firstText:
      "Defina aqui as configurações relacionadas para preencher automaticamente todos os campos relacionados nas páginas Cotações, Criação de pedidos, Importação de pedidos e produtos.",
    newConfiguration: "Novas Configurações",
    add: "Adicionar",
    pleaseSelectOne: "Selecione pelo menos um",
    done: "pronto",
    configurationAppliedTo: "Configuração aplicada a",
    noConfigApplied: "Nenhuma configuração aplicada nesta página",
    modify: "Modificar",
    confirmChanges: "Tem certeza que deseja salvar as alterações?",
    confirmDelete: "Tem certeza de que deseja excluir essas configurações?",
    deleteConfiguration: "Remover configuração",
    unsavedData: "Dados não salvos",
    saveUnsavedOrCancel: "Por favor, salve os dados não salvos ou cancele!",
    ok: "Ok",
    productCategory: "Categoria do Produto",
  },
  advancedPage: {
    title: "Configurações avançadas",
    subTitle: "Defina em qual sistema de unidades você deseja trabalhar",
    fistText: "Defina suas preferências de moedas e medidas.",
    weightsMeasures: "Pesos e medidas",
    weightSystem: "Sistema de Pesos",
    settingsAppliedAll:
      "Todas as medidas deverão ser passadas nessas unidades no momento da criação do pedido, por API, importação ou diretamente na plataforma. Todas as medidas do site e da API serão mostradas nessas unidades",
    changePlatformLanguage: "Alterar o idioma da plataforma",
    measurementSystem: "Sistema de Medição",
    dispatches: "Despachos",
    dispatchesHelpText:
      "Para iniciar a operação com um pedido, é necessário despachá-lo (clicando em “Despacho” ou via API. Aqui você pode automatizar esse envio. O pedido será despachado automaticamente assim que for criado. Atenção: uma vez despachado, você não poderá mais editá-lo ou removê-lo",
    autoSingle: "Despachar pedidos automaticamente quando criados",
    cpfValidation: "Validação de CPF",
    allowsCpfValidation:
      "Forçar validação nos CPFs dos clientes durante a criação do pedido",
    cpfHelpText:
      "Para cada pedido para o Brasil, verificamos se o formato do CPF é válido e retornamos um erro caso não seja. Aqui você pode definir algumas validações adicionais: Verificaremos sistematicamente junto à Receita Federal do Brasil se o Cliente está autorizado a receber pacotes do exterior e se o número fiscal passado é válido e pertence a ele. Caso haja algum problema, retornaremos uma mensagem de erro. Esta configuração pode acarretar custos adicionais",
  },
  boxesProductsPage: {
    title: "Caixas e Produtos",
    subTitle:
      "Navegue pelas abas a seguir para configurar modelos de caixas e produtos",
    boxModels: "Modelos de Caixas",
    productModels: "Modelos de Produtos",
  },
  boxesPage: {
    title: "Padrões de Caixa",
    subTitle:
      'Nesta página você pode cadastrar modelos pré-definidos de caixa para reutilizar na hora de criar seus pedidos. Para cadastrar um novo modelo clique em "Adicionar caixa"',
    found: "nenhuma caixa encontrada | caixa | caixas",
    autoSelect: "Selecionar Automaticamente",
    autoSelectToolTip:
      "A caixa com o rótulo automático será selecionada por padrão no formulário de criação de pedido.",
    auto: "Automatico",
    addBox: "Adicionar caixa",
    noBoxFound: "Você ainda não tem nenhuma caixa registrada.",
    createNewBox: "Criar uma nova caixa",
    newBoxTemplate: "Novo padrão de caixa",
    updateBox: "Atualizar informações da caixa",
    editBox: "Editar Caixa",
  },
  printingsPage: {
    title: "Configurações de impressão",
    standardConfig:
      "Defina aqui as configurações padrão para as opções de impressão da documentação",
    documentFormat: "Formato e configurações do documento",
    commercialInvoice: "Fatura comercial",
    baterryConfirmation: "Confirmação de bateria",
    ifDangerousPersonal: "Contato em relação aos produtos perigosos",
    packingSlip: "Talão de embalagem",
    dangerousGood: "Etiqueta para produtos perigosos(Un3481)",
    msds: "MSDS",
    shippingLabel: "Etiqueta de envio",
    shippingLabelSubtitle: "Esta é a etiqueta que irá nas suas caixas",
    commercialInvoiceSubtitle:
      "Purchase and sale transaction document between the importer and the exporter",
    packingSlipLabelSubtitle: "Lista os produtos incluídos em uma remessa",
    batterySubtitle:
      "Documento de autorização necessário quando a remessa contém baterias",
    dangerousGoodSubtitle:
      "Autorização listando os documentos perigosos na remessa",
    msdsSubtitle: "Fichas de segurança",
    includeApendixLabel: "Incluir Etiqueta do Apêndice",
    overpackLabel: "Etiqueta do Pacote",
    devolutionAddress: "Endereço de Devolução",
    userDevolutionAddress: "Usar seu endereço para devoluções",
    //options
    letterSizeFull: "Tamanho da carta (página inteira)",
    letterSizeHalf: "Tamanho da carta (meia página)",
    thermalPrinter: '"4x6” (Impressora Térmica)',
    //messages
    saveSuccess: "Configurações de impressão salvas com sucesso",
    saveTryLater: "Não foi possível salvar, tente novamente mais tarde",
    switchHelpText: "Incluir em todas impressões",
    devolutionCustomHelpText: "Aplicar para todos os documentos",
    ifYouPrefer: "Se preferir, você também pode",
    theTrackingPage: "personalizar a página de rastreamento",
    documentExample: "Exemplo de documento",
    wantToLearnAboutLabels: "Quer aprender tudo sobre etiquetas e documentos",
  },
  salesChannelsPage: {
    title: "Configurações dos canais de vendas",
    found:
      "nenhum canal de vendas encontrado | canal de vendas | canais de vendas ",
    addSalesChannel: "Adicionar canal de vendas",
    newSalesChannel: "Novo canal de vendas",
    updateSalesChannel: "Atualizar canal de vendas",
    createSalesChannel: "Criar um novo canal de vendas",
    salesChannelInfo: "Informações do canal de vendas",
  },
  productsPage: {
    title: "Meus produtos",
    subTitle:
      'Nesta página você pode cadastrar modelos de produtos para reutilizar na hora de criar seus pedidos. Para cadastrar um novo modelo selecione uma das opções disponíveis em "Adicionar Produto"',
    newProductTemplate: "Novo modelo de produto",
    found: "nenhum produto encontrado | produto | produtos",
    addProduct: "Adicionar Produto",
    downloadProducts: "Baixar produto(s)",
    deleteProduct: "Remover produto(s)",
    selectHowAddProducts: "Selecione como gostaria de adicionar produtos",
    manual: "Manualmente",
    import: "Importar Arquivo",
    browseFilesToUpload: "Navegue pelos seus arquivos e envie seus produtos.",
    selectCsv: "Selecione um arquivo csv para fazer upload.",
    downloadHere: "Baixe aqui",
    csvFile: "o modelo de arquivo CSV",
    createProductTitle: "Preencha as informações para criar seu produto",
    updateProduct: "Atualizar informações do produto",
    skuHelptext: "codgo unico para identificar o produto",
    nameHelptext:
      "Breve descrição do produto (por exemplo, sapatos brancos) usado apenas para seu uso interno",
    descriptionHelptext:
      "Usado para declaração aduaneira. Seja o mais específico possível, incluindo a marca e o modelo do produto",
    categoryHelptext:
      "Categoria do produto. O HsCode será preenchido automaticamente dependendo desta escolha. Use “Outros” se desejar definir um Código HS específico",
    hs_codeHelptext:
      "Código fiscal de 6 ou 8 dígitos que identifica o produto. Consulte os Códigos do Sistema Harmonizado (HS) para obter mais informações",
    dimensionsHelptext:
      "As dimensões são informadas no seguinte formato: Largura x Altura x Comprimento",
  },
  userListPage: {
    title: "Gerenciamento de usuários",
    found: "nenhum usuário encontrado | usuário | usuários",
    addUser: "Adicionar usuário",
    addUserTitle: "Criar novo usuário",
  },
  userCreatePage: {
    title: "Criar conta",
    create: "Criar",
    account: "Conta",
    companyInformation: "Informações da Empresa",
  },
  customersPage: {
    title: "Clientes",
    subtitle: "Confira os clientes cadastrados e adicione novos.",
    subtitle2:
      "Se preferir clique no nome de um cliente para editar e ver outras ações.",
    found: "nenhum cliente encontrado | cliente | clientes",
    newCustomer: "Novo cliente",
    addCustomer: "Cadastrar cliente",
    downloadCustomers: "Baixar cliente(s)",
    deleteCustomer: "Remover cliente(s)",
    selectHowAddCustomer: "Selecione como gostaria de adicionar clientes",
    browseFilesToUpload: "Navegue pelos seus arquivos e envie seus clientes.",
    addCustomerTitle: "Preencha as informações para adicionar um cliente",
    documentTypeCaption: "Identificação fiscal exigida apenas para o Brasil",
    onlyNumberCaption: "apenas número",
    addressQuarter: "Bairro",
    addressReference: "Referência de endereço",
    updateCustomer: "Confira e edite, se preciso, os dados do cliente",
    customerData: "Dados do cliente",
    importingFile: "Importando arquivo",
    importedFile: "Arquivo importado",
    failedToImportFile: "Falha ao importar o arquivo",
  },
  documentsPage: {
    title: "Documentos",
    subtitle:
      "Confira os documentos pré-cadastrados e adicione os documentos que você já possui, atrelados a categorias e HS Codes.",
    documentName: "Nome do documento",
    newDocument: "Novo documento",
    addDocument: "Adicionar Documento",
  },
  insurancePace: {
    title: "Seguro",
    subtitle: "Escolha como deverá ser calculado o seguro.",
    insuranceValue: "Cobertura do frete",
    insuranceValueCaption:
      "Defina se o Seguro deve, ou não, cobrir o valor do frete.",
    choosHowInsuranceShouldBeCalculated:
      "Escolha como o seguro deverá ser calculado",
    useSellingPrice: "Usar preço de venda",
    alwaysInsure: "Usar preço fixo de",
    use: "Usar porcentagem fixa de",
    ofSellingPrice: "do preço de venda.",
    includeShippingCost: "Incluir Custo de Frete na cobertura?",
    yesShipCoveredClain: "Sim, cobrir o custo do frete (recomendado).",
    noCoverItemOnly: "Não, cobrir apenas o valor do item",
    calculationBase: "Base de cálculo",
    calculationBaseHelpText:
      "Informação objetiva explicando melhor o contexto.",
    freightCoverageHelpText:
      "Informação objetiva explicando melhor o contexto.",
  },
  integrationsPage: {
    title: "Integrações",
    firstText:
      "Conecte-se às suas outras contas para gerenciar todos os seus pedidos na plataforma Nobordist",
    connectMabang: "Conectar ao ERP MABANG",
    getToken: "Gerar Token",
    getTokenCaption:
      "Clique no token para copiá-lo para a área de transferência",
    getTokenText:
      "Obtenha seu token e use-o para conectar sua conta Mabang ao Nobordist.",
    getTokenMoreInfo: "Para obter mais informações, verifique nosso tutorial",
    dhlTitle: "Conecte sua conta DHL.",
    dhlText:
      "Você precisa de uma conta existente da DHL Express e de credenciais de desenvolvedor para preencher este formulário.",
    dhlOkButton: "Conecte-se à DHL",
    anjunTitle: "Conecte sua conta Anjun.",
    anjunText:
      "Você precisa de uma conta Anjun existente e credenciais de desenvolvedor para preencher este formulário.",
    anjunOkButton: "Conectar à Anjun",
    csatTitle: "Conecte sua Conta CSAT.",
    csatText:
      "Você precisa de uma conta CSAT existente e credenciais de desenvolvedor para preencher este formulário.",
    csatOkButton: "Conectar à CSAT",
    totalTitle: "Conecte sua Conta Total Express.",
    totalText:
      "Você precisa de uma conta Total Express existente e credenciais de desenvolvedor para preencher este formulário.",
    totalOkButton: "Conectar à Total Express",
    bringerTitle: "Conecte sua Conta Bringer.",
    bringerText:
      "Você precisa de uma conta Bringer existente e credenciais de desenvolvedor para preencher este formulário.",
    bringerOkButton: "Conectar à Bringer",
    hercoTitle: "Conecte sua Conta Herco.",
    hercoText:
      "Você precisa de uma conta Herco existente e credenciais de desenvolvedor para preencher este formulário.",
    hercoOkButton: "Conectar à Herco",
    kparcelTitle: "Conecte sua Conta K-PARCEL.",
    kparcelText:
      "Você precisa de uma conta K-PARCEL existente e credenciais de desenvolvedor para preencher este formulário.",
    kparcelOkButton: "Conectar à K-PARCEL",
    mabangTitle: "Conecte sua Conta MABANG.",
    mabangText:
      "Você precisa de uma conta MABANG existente e credenciais de desenvolvedor para preencher este formulário.",
    mabangOkButton: "Conectar à MABANG",
    sinerlogTitle: "Conecte sua Conta SINERLOG.",
    sinerlogText:
      "Você precisa de uma conta SINERLOG existente e credenciais de desenvolvedor para preencher este formulário.",
    sinerlogOkButton: "Conectar à SINERLOG",
    skypostalTitle: "Conecte sua Conta Sky Postal.",
    skypostalText:
      "Você precisa de uma conta Sky Postal existente e credenciais de desenvolvedor para preencher este formulário.",
    skypostalOkButton: "Conectar à Sky Postal",
    bilTitle: "Conecte sua Conta Bil.",
    bilText:
      "Você precisa de uma conta Bil existente e credenciais de desenvolvedor para preencher este formulário.",
    bilOkButton: "Conectar à Bil",
    correiosTitle: "Conecte sua Conta dos Correios.",
    correiosText:
      "Você precisa de uma conta existente dos Correios Express e credenciais de desenvolvedor para preencher este formulário.",
    correiosOkButton: "Conectar aos Correios",
    reverse: " Reverse",
    fedexTitle: "Conecte sua conta Fedex.",
    fedexText:
      "Você precisa de uma conta Fedex Express existente e de credenciais de desenvolvedor para preencher este formulário.",
    fedexOkButton: "Conecte a Fedex",
    shipstationTitle: "Conecte sua conta Shipstation.",
    shipstationText:
      "Você precisa de uma conta Shipstation Express existente e credenciais de desenvolvedor para preencher este formulário.",
    shipstationOkButton: "Conecte a Shipstation",
    shopifyTitle: "Conecte sua conta do Shopify.",
    shopifyText:
      "Você precisa de uma conta existente do Shopify Express e de credenciais de desenvolvedor para preencher este formulário.",
    shopifyOkButton: "Conecte a Shopify",
    upsTitle: "Conecte sua conta Ups.",
    upsText:
      "Você precisa de uma conta Ups Express existente e de credenciais de desenvolvedor para preencher este formulário.",
    upsOkButton: "Conecte a Ups",
    vtexTitle: "Conecte sua conta Vtex.",
    vtexText:
      "Você precisa de uma conta Vtex Express existente e credenciais de desenvolvedor para preencher este formulário.",
    vtexOkButton: "Conecte ao Vtex",
    lionTitle: "Conecte a sua conta 360 Lion.",
    lionText:
      "Você precisa de uma conta 360 Lion existente e credenciais de desenvolvedor para preencher este formulário.",
    lionOkButton: "Conecte a 360 Lion",
    mailAmericasTitle: "Conecte a sua conta Mail Americas.",
    mailAmericasText:
      "Você precisa de uma conta existente do Mail Americas Express e credenciais de desenvolvedor para preencher este formulário.",
    mailAmericasOkButton: "Conecte a Mail Americas",
    br1ExpressTitle: "Conecte a sua conta BR1 Express Account.",
    br1ExpressText:
      "Você precisa de uma conta BR1 Express Express existente e credenciais de desenvolvedor para preencher este formulário.",
    br1ExpressOkButton: "Conecte a BR1 Express",
    accNick: "Apelido da conta",
    accPass: "Senha",
    accNumber: "Número da conta",
    siteId: "ID do site",
    user: "Usuário",
    admCode: "Código Administrativo",
    contract: "Contrato",
    name: "Nome",
    carrier: "Transportadora",
    cooperation: "Cooperação",
    price_table: "Tabela de Preços",
    delivery_time: "Prazo de Entrega",
    key: "Chave de Autenticação",
    value: "Valor",
    senha: "Senha",
    usuario: "Usuário",
    loginHash: "Login",
    cartaoPostagem: "Cartão de Postagem",
    authorization: "Autorização",
    token: "Token",
    line_code: "Código da Linha",
    remetenteId: "ID do Remetente",
    externalCustomerId: "ID do Cliente Externo",
    externalReferenceCode: "Código de Referência Externa",
    dispatchServiceCode: "Código de serviço de despacho",
    shipperAccountNumber: "Número da Conta do Remetente",
    billingAccountNumber: "Número da Conta de Cobrança",
    channelCode: "Código do Canal",
    userCode: "Código do Usuário",
    userKey: "Chave do Usuário",
    copaId: "ID do Copa",
    boxId: "ID da Caixa",
    firstMileServiceCode: "Código do Serviço de Primeira Milha",
    serviceCode: "Código do Serviço",
    userId: "ID do Usuário",
    logisticsServerToken: "Token do servidor de logística",
    contractDeleted: "Contract is deleted",
    contractNotdeleted: "Contract CAN'T be deleted!",
    contractNotCreated:
      "The connection could not be created, please try again later",
  },
  onboardPage: {
    title: "Bem-vindo ao Nobordist <b>{val}</b>. Vamos começar!",
    linkText: "Para obter mais informações, consulte nosso tutorial",
    quickStart: "Tutorial",
    quickStartText:
      "Definir configurações relacionadas para preencher automaticamente todos os campos relacionados nas páginas Cotações, Criação de pedidos, importação de pedidos e produtos",
    quickStartBtn: "Tutorial",
    order: "Pedido",
    orderText:
      "Criar pedidos através de um formulário ou via importação de arquivo",
    orderBtn: "Criar pedido",
    printing: "Impressão",
    printingText:
      "Definir configurações padrão para opções de impressão da documentação",
    printingBtn: "Impressão",
    connectSellingChannel: "Conecte um canal de vendas",
    connectSellingChannelText:
      "Conecte-se a canais de vendas integrados. Reunimos todos os seus pedidos para simplificar o gerenciamento de pedidos",
    connectSellingChannelBtn: "Conectar Canal de Venda",
    notification: "Notificações",
    notificationText:
      "Crie mensagens inteligentes e melhore a experiência pós-venda de seus clientes",
    notificationBtn: "Notificações",
  },
  insightsPage: {
    volumesByRegion: "Volumes por região",
    shippedVolumes: "Volumes enviados",
    customerEngagement: {
      pannel: "Painel",
    },
    customerOverview: {
      topFiveCustomers: "Top 5 clientes",
      orderByBuyer: "Pedidos por Estado",
    },
    overview: {
      volumeStatus: "Situação de Volumes",
    },
    salesTrend: {
      sales: "Vendas",
      topSellingProducts: "Produtos mais vendidos",
      salesByCategory: "Vendas por categoria",
      salesBySalesChannel: "Vendas por Canal de Vendas",
    },
  },
  companyPage: {
    title: "Minha Empresa",
    subTitle: "Confira e atualize, se preciso, as informações da sua empresa",
    generalData: "Dados Gerais",
    brandSignature: "Marca e assinatura",
    legalName: "Nome Legal",
    rfbCode: "Código de RFB",
    thisInfoWllBeUsed:
      "Esta informação será usada nas comunicações da sua marca",
    addSignature: "Adicionar assinatura",
    showSignature: "Mostrar assinatura",
    addLogo: "Adicionar logotipo",
    companyInformation: "Company Information",
    companyName: "Nome da Empresa",
    companyID: "Internal Nobordist Reference",
    checkBrandTab: "This field can be changed in the Brand and signature tab",
    usedByNB: "Used by Nobordist as internal reference",
    customerID: "ID do cliente",
    websiteURL: "URL do site",
    taxNumber: "Número de identificação fiscal",
    authorizeText:
      "Autorizo ​​o uso desta assinatura em documentos de envio obrigatórios",
    devolutionAddress: "Endereço de Devolução",
    devolutionAddressSubtitle:
      "Pedidos com problemas não resolvidos retornarão para este endereço",
    ownDevolutionAddress: "Usar este endereço como endereço de devolução",
    editCompany: "Editar Empresa",
    addressComplement: "Complemento de Endereço",
    sellerAddress: "Endereços do Vendedor",
    addSellerAddress: "Adionar endereço do vendedor",
    noSellerAddressFound: "Você ainda não possui um endereço cadastrado.",
    createNewAddSellerAddress: "Crie um novo endereço.",
    found:
      "nenhum endereço de vendedor encontrado | endereço do vendedor | endereços do vendedor",
    editAddSellerAddress: "Atualizar as informações do endereço",
    generalDataPage: {
      subTitle: "Confira e edite dados da empresa, se preciso",
      companySubTitle: "Check and edit company data, if necessary",
      companyAddress: "Company Address",
      companyAddressSubTitle: "This is the company address",
    },
    brandSignaturePage: {
      visualTitle: "Visual identity",
      visualSubTitle: "Configure company colors, branding and signature",
      signatureTitle: "Signature",
      signatureSubTitle:
        "Configure the signature to appear on company documents",
      noImageAdded: "No image added",
      clickOrDrag: "Click or drag your image here",
      companyBrand: "Company brand",
      companySignature: "Company signature",
      recomendedWidth: "Recommended: horizontal, 180px width, png",
      companyColors: "Company colors",
      reflectOn: "Will be reflected on",
      and: "and",
      foregroundColor: "Foreground color",
      backgroundColor: "Background color",
      textColor: "Text color",
      returnTo: "Return to ",
      defaultColors: "default colors",
      authUse: "Authorize use",
    },
  },
  error404Page: {
    title: "Número de rastreamento não encontrado!",
    tryAgain: "Tente novamente",
  },
  homePage: {
    subtitle: "O que você gostaria de fazer hoje?",
    integrateEcommerce: "Integrar e-commerce",
    quotation: "Cotação",
    newQuotation: "Nova cotação",
    newOrder: "Novo pedido",
    addOrders: "Adicionar pedidos",
    addOrdersManually: "Adicione pedidos manualmente.",
    simulateYourDelivery: "Simule sua entrega.",
    uploadMultipleOrders: "Faça upload de vários pedidos.",
    addYourFavoriteStores: "Adicione suas lojas preferidas.",
    connectEcommerce: "Conectar e-commerce",
    freightValue: "Valor do frete",
    freightCost: "Custo do frete",
    averageOrderCost: "Custo médio por pedido",
    rejectedOrders: "Pedidos rejeitados",
    createdOrders: "Pedidos criados",
    totalAwaitingYourAction: "{total} pedido(s) aguardando sua ação",
    totalAwaitingPickup: "{total} pedido(s) aguardando coleta",
    totalShippedOrders: "{total} pedido(s) enviado",
    last30Days: "Últimos 30 dias",
  },
  loginPage: {
    login: "Login",
    welcome: "Bem-vindo",
    pleaseYourAcc: "Por favor, insira os dados da sua conta",
    forgotPassword: "Esqueceu sua senha",
    notRegisteredYet: "Ainda não é cadastrado",
    checkOur: "Confira nossos",
    generalConditionsService: "Termos de Serviço",
    resetPasswordSent:
      "O e-mail de redefinição de senha foi enviado! Verifique sua caixa de entrada ou span.",
    sendResetPasswordInstructions: "Receber instruções de redefinição de senha",
    iAgree: "Eu concordo",
    agreementText:
      "Ao marcar esta caixa, você reconhece que leu e concorda com as nossas Condições Gerais de Serviço em ficar vinculado a este Acordo.",
    createYourAccount: "Create your account",
    fillInfoToCreateAcc: "Preencha os campos a seguir para criar sua conta",
    userData: "Dados do usuário",
    pleaseYourData: "Por favor, informe seus dados",
    companyData: "Dados da empresa",
    pleaseCompanyData: "Por favor, informe os dados da empresa",
    hasRegistration: "Já possui cadastro?",
    accessMyAcc: "Acessar minha conta",
    needHelp: "Precisa de ajuda",
    talkWithOur: "Fale com um de nossos consultores",
    ifCheckOut: "Se preferir, confira nossos",
  },
  passwordNewPage: {
    title: "Redefinir minha senha",
    registeredEmail: "Email registrado",
    newPassword: "Nova senha",
    confirmNewPassword: "Confirme a nova senha",
    passwordDosentMatch: "A senha e a confirmação da senha não são iguais",
    passwordChanged: "Senha alterada",
  },
  trackMyOrderPage: {
    title: "Rastrear Meu Pedido",
    subtitle: "Esperando por algo? Rastreie seu pedido agora!",
    trackingNumber: "Número de Rastreio",
    placeholderTrackingNumber: "Exemplo: BRBR771909457310",
    taxIdentificationNumber: "Número de identificação fiscal (CPF/CNPJ)",
    track: "Rastrear",
    enterTtrackingNumber: "Por favor, insira um número de rastreio",
    enterIdentificationNumber:
      "Por favor, insira o número de identificação fiscal correspondente (CPF/CNPJ)",
    trackingNotFound: "Número de rastreio não encontrado",
    taxIdentificationNotFound: "Número de identificação fiscal errado",
  },
  trackMyOrderViewPage: {
    title: "Track My Order",
    contactUs: "Fale conosco",
    statusBar: {
      created: "Criado",
      shiped: "Enviado",
      inTransit: "Em trânsito",
      outOfDelivery: "Saiu para entrega",
      delivery: "Entregue",
    },
    deliveredBby: "Entregue por",
    estimatedDelivery: "Entrega estimada",
    destination: "Destino",
    fromNow: "a partir de hoje",
    days: "dias",
    late: "late",
    latestStatus: "Última Situação",
    lastMileTrackingNumber: "Número de rastreamento da última milha",
    expandOrderHistory: "Expandir histórico de pedidos",
    updateByEmail: "ATUALIZAÇÕES VIA E-MAIL",
    authEmail: "Autorizo o envio de e-mails com atualizações sobre este pedido",
    shippingInformation: "INFORMAÇÃO DE ENVIO",
    lastMilePartner: "Parceiro de última milha",
  },
  userProfilePage: {
    title: "Meu Perfil",
    firstText: "Confira e atualize, se preciso, suas informações pessoais",
    checkAndEditUser: "Confira e edite seus dados, se preciso",
    loginInfo: "Informações de login",
    checkAndEditLogin:
      "Se preciso, edite os dados que utiliza para acessar a plataforma",
  },
  userProfileEditPage: {
    title: "Atualizar meu perfil",
    updatedText: "Perfil atualizado",
  },
  downloadsPage: {
    title: "Arquivos",
    subtitle: "Todos os downloads são centralizados nesta página",
    errors: "Erros",
    found: "nenhum download encontrado | download | downloads",
    status: {
      failed: "falhou",
      processing: "carregando",
      done: "completo",
      starting_upload: "iniciando upload",
      checking_csv_format: "verificando formato CSV",
      checking_columns: "verificando colunas",
      checking_equal_orders_volumes: "verificando volumes de pedidos iguais",
      checking_cells: "verificando células",
      validation_success: "validações concluídas com sucesso! criando a tabela",
    },
    fields: {
      id: "ID",
      type: "Tipo",
      status: "Situação",
      fileName: "Nome do Arquivo",
      creationDate: "Data de Criação",
      messages: "Mensagens",
    },
  },
  volumesReversePage: {
    title: "Volumes Reversa",
    viewTitle: "Reverse ID",
    reverseNumber: "Reverse Number",
    reverseVolume: "Reverse Volume",
    reference: "Número de Rastreamento",
    salesChannelName: "Nome do canal de vendas",
    volumeId: "Volume ID",
    found:
      "nenhum volume reversa encontrado | volume reversa | volumes reversa",
    fields: {
      id: "ID",
      volumeId: "Volume ID",
      reverseNumber: "Numero da Reversa",
      addressComplement: "Complemento de Endereço",
      orderItemsName: "Nome do Item",
      orderItemsDescription: "Descrição do Item",
      orderItemsHsCode: "HS Code do Item",
    },
    orderErroTicket: "pedidos estão com erro na geração do e-ticket",
    orderWaitingTicket: "pedidos aguardando criaçao do e-ticket",
  },
  myNotificationsPage: {
    found: "nenhuma notificação encontrada | notificação | notificações",
    readBtn: "marcar como lido",
    notReadBtn: "marcar como nova",
    viewAllNotifications: "Ver Todas as Notificações",
    showAll: "Ver todas",
    response: "Resposta",
    reply: "Responder",
    fields: {
      title: "Assunto",
      body: "Notificação",
      created_at: "Data",
      isNew: "Nova",
      isRead: "Lido",
      type: "Tipo",
    },
    noNewNotifications: "Você não possui novas notificações",
    seconds: "segundos",
    minuteAgo: "1 minuto atrás",
    minutes: "minutos",
    hourAgo: "1 hora atrás",
    hours: "horas",
    yesterday: "ontem",
    days: "dias",
    lastWeek: "Última semana",
    weeks: "semanas",
    lastMonth: "Último mês",
    months: "mêses",
    lastYear: "Último ano",
    years: "anos",
  },
  myNotificationsListPage: {
    title: "Notificações",
    found: "nenhuma notificação encontrada | notificação | notificações",
    filters: {
      isNew: "Nova ou Lida",
    },
    allNotifications: "Ver todas as notificações",
    view: "Visualizar",
    youHaveNewNotification: "Você tem novas notificações não lidas.",
  },
  priceTablesPage: {
    titlePrice: "Tabela de Preços",
    titleDelivery: "Prazos de Entrega",
    importPricesTable: "Importar Tabela de Preços",
    importDeliveryTime: "Importar Prazo de Entrega",
    suretodelete: "Você tem certeza que quer deletar",
    selectCsv: "Selecione um arquivo csv para carregar",
    fileUploaded: "Arquivo enviado",
    downloadHere: "Baixe Aqui",
    ablankTable: "uma tabela vazia",
    import: "Importar",
    name: "Nome",
    filesUpload: "Navegue pelos seus arquivos e carregue o seu",
    needsSupervisorApproval: "Esta ação precisa da aprovação de um supervisor",
    chooseToSendRequest:
      "Por favor, escolha para quem você vai enviar a solicitação",
    fields: {
      name: "Nome",
      actions: "Ações",
    },
  },
  accessibilitySettingsPage: {
    subtitle: "Configure tipografia, preferências, idioma e mais.",
    typography: "Tipografia",
    textPreferenceDescription: "Defina a seguir suas preferências de texto.",
    textSize: "Tamanho do texto",
    language: "Idioma",
    mainLanguageDescription:
      "Defina qual sera o idioma principal da Plataforma.",
    selectLanguage: "Selecione o idioma",
    changeUnitsText: "alterar unidades de pesos e medidas",
    stillHavingDifficulties: "Ainda com dificuldades?",
    weWantToHelpYou:
      "Queremos te ajudar! Entre em contato conosco através do modo que preferir",
    contactViaPhone: "Contato via telefone",
    contactViaEmail: "Contato via e-mail",
    contactViaChat: "Contato via chat",
  },
  usersPage: {
    title: "Usuários",
    subtitle: "Confira os usuários conectados e adicione novos.",
    all: "Todos",
    sellerAdmin: "Vendedor Adm",
    sellerOperation: "Vendedor Operador",
    sellerViewer: "Vendedor Visualizador",
    blocked: "Bloqueados",
    newUser: "Novo usuário",
    addUser: "Adicionar usuário",
    editUser: "Editar usuário",
    allowAccess: "Permitir acesso",
    blockedTooltip:
      "Se marcar esta caixa, o Usuário ficará temporariamente proibido de acessar a plataforma. Você pode desmarcar isso a qualquer momento.",
    groupTooltip: `O Seller-Viewer só pode visualizar dados, mas não tem permissão para criar pedidos, alterar configurações,…
    O Seller-Operation pode basicamente fazer qualquer coisa, exceto criar/bloquear/remover usuários.
    O Vendedor-Admin pode fazer qualquer coisa, incluindo criar/bloquear/remover usuários.`,
  },
  contractsPage: {
    title: "Contratos",
    subtitle:
      "Os contratos são a certificação da parceria entre empresa e transportadora.",
    inconterm: "Inconterm",
    taxingType: "Tributação",
    startDate: "Data inicial",
    endDate: "Data final",
    downloadPdf: "Baixar PDF",
    downloadCsv: "Baixar CSV",
    footerHelpText: "Saiba mais sobre transportadoras e contratos",
  },
  dashboardPage: {
    title: "Dashboard",
    subtitle: "Navegue pelas abas para conferir os dados.",
    overview: "Visão geral",
    salesTrend: "Tendência de vendas",
    consumers: "Consumidor | Consumidores",
    totalNewCustomers: "{count} novos clientes",
    totalCustomersReturned: "{count} clientes retornaram",
    new: "Novos",
    recurrent: "Recorrentes",
    topConsumers: "Top consumidores",
    showMore: "Mostrar mais",
    showLess: "Mostrar menos",
    volumesByRegion: "Volumes por região",
    volumes: "Volumes",
    ordersCreated: "Pedidos criados",
    ordersByStatus: "Pedidos por status",
    seeOrders: "Ver pedidos",
    seeTableWithAllOrders: "Ver tabela com todos os pedidos",
    cards: {
      rejectOrder: "Pedidos rejeitados",
      freightValue: "Valor do frete",
      freightCost: "Custo de frete",
      averageOrderCost: "Custo médio do pedido",
    },
    totalOrders: "Total de pedidos",
    totalSales: "Total de vendas",
    currentPeriod: "Período atual",
    bestSellers: "Mais vendidos",
    ordersByCategory: "Pedidos por categoria",
    salesByChannel: "Vendas por e-commerce",
    ecommerce: "e-commerce",
    period: "Período",

    noMoreOnboardText: `Clique aqui se não quiser ver esta página (você ainda pode acessar isso no menu lateral em <i class =" fas fa-user-graduate "> </i> <b> Onboard </b> ) .`,
    customerOverview: "Visão geral do cliente",
    startDate: "Data de início",
    endDate: "Data de término",
    clear: "Limpar",
    checkpoints: {
      new: "Novo",
      orderInformationsReceived: "INFORMAÇÕES DE PEDIDO RECEBIDAS",
      readyToShip: "PRONTO PARA ENVIAR",
      pickup: "RECOLHA",
      shipped: "ENVIADO",
      inTransit: "EM TRÂNSITOIT",
      outForDelivery: "SAIU PARA ENTREGA",
      failedAttempt: "FALHA NA TENTATIVA",
      delivered: "ENTREGUE",
      exception: "EXCEÇÃO",
      exceptionInPickup: "EXCEÇÃO NA RECOLHA",
      partialDelivery: "ENTREGA PARCIAL",
      partialDeliveryFailed: "FALHA NA ENTREGA PARCIAL",
      expired: "EXPIRADO",
      pending: "PENDENTE",
    },

    noShippingInThisState: "Você não tem envio para este estado 0..",
    createOrderHere: "Você pode criar um pedido aqui.",
    moreInformation:
      "Para obter mais informações, consulte a nossa página de tutorial aqui.",
    checkYourInternet:
      "Verifique sua conexão com a Internet e tente novamente.",
  },
  userProfileBalancePage: {
    title: "Saldo atual",
    statement: "Extrato",
    downloadReport: "Baixar relatório",
    fields: {
      category: "Categoria",
      type: "Tipo",
      eventType: "Evento",
      method: "Método",
      value: "Valor",
      description: "Descrição",
      createdAt: "Criado em",
    },
  },
  trackingRuleNotificationsPage: {
    title: "Regras de notificação de rastreamento",
    addConfig: "Adicionar configuração",
    createRule: "Criar regra",
    giveRuleName: "Dê um nome à sua regra",
    ruleName: "Nome da regra",
    applyRuleTo: "Você quer aplicar a regra para:",
    allShipments: "Todas as remessas",
    someShipmentsOnly: "Apenas algumas remessas",
    chooseSellers: "Primeiro, escolha os vendedores que usarão essas regras",
    WhenNewSellersIsCreated:
      "O que você quer fazer quando um novo vendedor for criado",
    addThisRuleAutomatically: "Adicionar automaticamente a esta regra",
    addManually:
      "Não adicione a esta regra, eles serão adicionados manualmente se necessário",
    notifications: "Notificações",
    conditions: "Condições",
    wouldYouLikeToRemoveRule: "Você gostaria de remover a regra?",
    wouldYouLikeToDisableRule: "Você gostaria de desativar a regra?",
    wouldYouLikeToEnableRule: "Você gostaria de ativar a regra?",
    removalIsFinal: "A remoção é definitiva",
    disableRuleMessage:
      "A regra será salva no sistema, mas não será aplicada a nenhuma ordem, com efeito imediato. Isso pode ser desfeito a qualquer momento.",
    enableRuleMessage:
      "A regra será aplicada aos pedidos envolvidos com efeito imediato. Isso pode ser desfeito a qualquer momento.",
    sellersPermission: "Permissão dos vendedores",
    sellersThatCanConfigureTheirOwnRules:
      "Vendedores que podem configurar suas próprias regras",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "Para esses vendedores, nenhuma das regras criadas aqui será aplicada e eles poderão configurar as suas próprias",
    sellersThatWillUseRulesConfiguredHere:
      "Vendedores que usarão as regras configuradas aqui",
    sellersThatWillUseRulesConfiguredHereDescription:
      "Esses vendedores não poderão configurar suas próprias regras",
    createOrUseExistingRule:
      "Você gostaria de criar ou usar uma regra existente?",
    createFromScratch: "Criar do zero",
    useExistingRule: "Usar uma regra existente como pré-configuração",
    selectRuleToUse: "Selecione a regra que você deseja usar",
    defineNotificationRules:
      "Define regras de notificação para grupos de checkpoints",
    allSelected: "Todos selecionados",
    selectAll: "Selecionar todos",
    onSellerCreation: "O que fazer quando um novo vendedor for criado?",
    onContractCreation: "O que fazer quando um novo contrato for criado?",
    toEditSellersList:
      "Para editar a lista de vendedores, você precisa escolher:",
    toEditContractsList:
      "Para editar a lista de contratos, você precisa escolher:",
    autoAddRule: "Adicionar automaticamente a esta regra",
    notAddThisRule:
      "Não adicione a esta regra, eles serão adicionados manualmente se necessário",
    searchByNameOrId: "Buscar: encontrar por nome ou id",
    noItemsSelected: "Nenhum item selecionado",
    selectedItems: "Itens selecionados",
    chooseContracts:
      "Agora, escolha a quais dos contratos abaixo você quer aplicar a regra",
    allShipmentWarning:
      "Você está prestes a criar uma regra aplicável a todas as remessas",
    keepEditing: "Continuar editando",
    goIt: "Entendido",
    wantToCreateRule: "Você deseja criar a regra configurada?",
    totalSellersLinked: "Número total de vendedores vinculados a esta regra",
    totalContractsLinked: "Número total de contratos vinculados a esta regra",
    allSellersIncluded:
      "Todos os vendedores foram incluídos nesta regra, incluindo novos vendedores que serão adicionados posteriormente.",
    allContractsIncluded:
      "Todos os contratos foram incluídos nesta regra, incluindo novos contratos que serão adicionados posteriormente.",
    almostThere: "Quase lá..",
    ruleCreatedSuccessfully: "Regra criada com sucesso!",
    youWantActivate:
      "Está desativado no momento. Deseja ativar esta regra agora?",
    maybeLater: "Talvez mais tarde",
    notifsConfigured: "{total} notificações configuradas",
    allSellers: "Todos os vendedores",
    allContracts: "Todos os contratos",
    totalSellers: "{total} vendedores",
    totalContracts: "{total} contratos",
    updateRule: "Atualizar regra",
    wantToUpdateRule: "Você deseja atualizar a regra configurada?",
    ruleUpdatedSuccessfully: "Regra atualizada com sucesso!",
    activateUpdatedRule:
      "A regra foi atualizada com sucesso e foi desabilitada por motivos de segurança. Você quer habilitar a regra agora?",
    noContractSelected: "Nenhum contrato selecionado",
    contractsSelected: "contratos selecionado",
    youDontHaveContractAllowed:
      "Você não tem nenhum contrato permitido. Entre em contato com {plataforma} para ter acesso aos seus primeiros contratos",
    ruleNameAlreadyExists: "O nome da regra informada já existe",
  },
  fiscalPaymentsPage: {
    title: "Pagamentos fiscais",
    fiscalInfo: "Informações fiscais",
    fiscalParcelData: "Dados da parcela fiscal",
    customerData: "Dados do cliente",
    fields: {
      reference_number: "Referência",
      barcode: "Código de barras",
      authentication: "Authenticação",
      status: "Status",
      payment_type: "Tipo de pagamento",
      value: "Valor",
      fiscal_parcel_id: "ID parcela fiscal",
      payment_date: "Data de pagamento",
      created_at: "Data de criação",
      dir_number: "Número do dir",
      customer_name: "Nome do cliente",
      customer_cpf: "CPF do cliente",
      customer_cep: "CEP do cliente",
      tracking_number: "Número de rastreamento",
      products_description: "Descrição dos produtos",
      customer_state: "Estado do cliente",
      seller_id: "ID do vendedor",
      dir_date: "Data dir",
      start_payment_date: "Data de ínico do pagamento",
      end_payment_date: "Data fim do pagamento",
      start_created_at: "Data de início da criação",
      end_created_at: "Data fim da criação",
      start_value: "Valor inicial",
      end_value: "Valor final",
      start_dir_date: "Data inicial DIR",
      end_dir_date: "Data fim DIR",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Data do pagamento",
      masterNumber: "Master Number",
      messages: "Mensagens",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "Processando",
      processed: "Processado",
      error: "Erro",
    },
    downloadBoletoGNRE: "Baixar Boletos",
  },
};
//{{ $t('userProfileEditPage.title') }}
