<template>
  <span> &nbsp;{{ humnaReadable }} </span>
</template>

<script>
export default {
  name: "UpcaseString",
  props: {
    string: {
      type: String,
      require: true,
    },
  },
  computed: {
    humnaReadable: function () {
      const split_string = this.string.split("_");
      let final_string = "";
      split_string.forEach((s) => {
        final_string += s.charAt(0).toUpperCase() + s.slice(1) + " ";
      });
      return final_string;
    },
  },
};
</script>
