<template>
  <div class="" :class="error[0] ? 'is-invalid' : ''">
    <label v-if="name" class="input-label" :for="id">
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </label>
    <input
      :type="type"
      class="form-control input mr-2"
      :class="error[0] ? `is-invalid ${size}` : `${size}`"
      :id="id"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      v-model="textContent"
    />
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbBorderTextInput",
  components: {
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    prepend: {
      type: String,
      required: false,
    },
    append: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textContent: this.value,
    };
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  watch: {
    //altera o input
    textContent(newValue) {
      this.$emit("input", newValue);
    },
    //altera o modal do pai
    value(newValue) {
      this.textContent = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control.input:not(:placeholder-shown),
.form-control.input.is-invalid:not(:placeholder-shown) {
  border: 1px solid #000000;
}
.form-control.input {
  //height: calc(2em + 0.75rem + 2px);
  height: 2.5rem;
  box-shadow: none;
  border: 1px solid var(--gray-30);
  border-radius: 4px;
  font: normal normal normal 16px/20px Nunito Sans;
  padding: 9px 12px;
}
.form-control.input:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:focus {
  box-shadow: none;
  border: 1px solid #000000;
}
.form-control.input::placeholder {
  font: normal normal normal 16px/28px Nunito Sans;
  color: var(--gray-40);
}

.form-control.input.is-invalid {
  border: 1px solid var(--danger);
  background-image: none;
}
.form-control.input.is-valid {
  border: 1px solid var(--success);
  background-image: none;
}
.form-control.input.is-invalid:hover {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.form-control.input:disabled,
.form-control.input[disabled] {
  background-color: var(--gray-20) !important;
  color: var(--gray-30) !important;
  border: 1px solid var(--gray-20) !important;
}
.input-label {
  font: normal normal normal 16px/28px Nunito Sans;
}

.form-control.input.sm {
  //height: calc(2em + 0.75rem + 2px);
  height: 1.75rem;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  padding: 9px 12px;
}
.form-control.input.sm::placeholder {
  font: normal normal 600 12px/16px Nunito Sans;
  color: var(--gray-40);
}

@media only screen and (max-width: 855px) {
  .input-label {
    font: normal normal 600 16px/24px Nunito Sans;
  }
}
@media only screen and (max-width: 480px) {
}
</style>
