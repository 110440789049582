<template>
  <base-template>
    <h5 class="font-weight-light text-uppercase mb-4">Billing</h5>
    <div class="mb-4 mt-4">
      <div>
        <b-card class="seller-account-card" no-body>
          <b-card-header
            class="d-flex justify-content-between align-items-center"
            header-tag="nav"
          >
            <b-nav card-header tabs>
              <b-nav-item
                @click="
                  currentTab = 'balance';
                  getBalance();
                "
                :active="currentTab === 'balance'"
                >Balance</b-nav-item
              >
              <b-nav-item
                @click="
                  currentTab = 'credits';
                  getEvents();
                "
                :active="currentTab === 'credits'"
                >Credits</b-nav-item
              >
              <b-nav-item
                @click="
                  currentTab = 'invoices';
                  getInvoices();
                "
                :active="currentTab === 'invoices'"
                >Invoices</b-nav-item
              >
            </b-nav>
            <div
              class="d-flex flex-row align-items-center justify-content-center"
            ></div>
          </b-card-header>
          <b-card-body class="seller-account-view-frame text-center">
            <div v-if="currentTab === 'balance'">
              <div
                v-if="
                  Object.keys(balance).length === 0 ||
                  Object.keys(settings).length === 0
                "
                class="d-flex justify-content-center align-items-center mb-3"
                style="min-height: 200px"
              >
                <b-spinner type="grow" variant="green" />
              </div>
              <view-balance
                v-else
                :balance="balance"
                :settings="settings"
                :categories="categories"
                @clicked="onClickChild"
              />
            </div>
            <div v-if="currentTab === 'credits'">
              <div
                v-if="Object.keys(events).length === 0"
                class="d-flex justify-content-center align-items-center mb-3"
                style="min-height: 200px"
              >
                <b-spinner type="grow" variant="green" />
              </div>
              <view-events
                v-else
                :events="events"
                :date_range="date_range_events"
                :categories="categories"
                @update="reloadEvents"
              />
            </div>
            <div v-if="currentTab === 'invoices'">
              <div
                v-if="Object.keys(invoices).length === 0"
                class="d-flex justify-content-center align-items-center mb-3"
                style="min-height: 200px"
              >
                <b-spinner type="grow" variant="green" />
              </div>
              <view-invoices
                v-else
                :invoices="invoices"
                :categories="categories"
              />
            </div>
          </b-card-body>
        </b-card>
        <modal-add-credit
          :sellerId="seller_id"
          :selectedCategory="selectedCategory"
          :categories="categories"
          @newEvent="newEvent"
        />
        <modal-settings :settings="selectedSettingsProp" />
      </div>
    </div>
  </base-template>
</template>

<script>
import SellerAccountService from "@/services/SellerAccountService";
import SellerService from "@/services/SellerService";
import ModalAddCredit from "./components/ModalAddCredit";
import ModalSettings from "./components/ModalSettings";
import ViewEvents from "./view/ViewEvents";
import ViewBalance from "./view/ViewBalance";
import ViewInvoices from "./view/ViewInvoices";
import BaseTemplate from "@/views/BaseTemplate";

const sellerAccountService = new SellerAccountService();
const sellerService = new SellerService();

export default {
  name: "SellerAccountView",
  components: {
    BaseTemplate,
    ModalAddCredit,
    ModalSettings,
    ViewEvents,
    ViewBalance,
    ViewInvoices,
  },
  data: () => {
    return {
      seller_id: null,
      selectedCategory: "global",
      selectedSettings: {},
      seller_account_activated: null,
      event_attachment: null,
      balance: {},
      events: [],
      invoices: [],
      settings: {},
      global_settings: {},
      statement: [],
      categories: {},
      currentTab: "balance",
      date_range_events: {
        start_date: "",
        end_date: "",
      },
    };
  },
  created() {
    this.getSeller();
  },
  methods: {
    getSeller() {
      sellerService.getCurrentSeller().then((response) => {
        const seller = response.data.data;
        this.seller_id = seller.id;
        this.pageReload();
      });
    },
    setInitialState() {
      (this.selectedCategory = "global"),
        (this.selectedSettings = {}),
        (this.seller_account_activated = null),
        (this.event_attachment = null),
        (this.balance = {}),
        (this.events = []),
        (this.invoices = []),
        (this.settings = {}),
        (this.statement = []),
        (this.categories = {}),
        (this.currentTab = "balance"),
        (this.date_range_events.start_date = "");
    },
    pageReload() {
      this.setInitialState();
      this.getSellerAccount();
      this.getCategories();
      this.getBalance();
      this.getEvents();
      this.getSettings();
    },
    getSellerAccount() {
      sellerAccountService.getSellerAccount(this.seller_id).then((response) => {
        this.seller_account = response.data.data;
        this.seller_account_activated = response.data.data.activated;
      });
    },
    getCategories() {
      sellerAccountService.getCategories(this.seller_id).then((response) => {
        this.categories = response.data.data;
      });
    },
    getBalance() {
      sellerAccountService.getBalance(this.seller_id).then((response) => {
        this.balance = response.data.data;
      });
    },
    reloadEvents(start_date, end_date) {
      this.date_range_events.start_date = start_date;
      this.date_range_events.end_date = end_date;
      this.getEvents();
    },
    reloadStatement(start_date, end_date) {
      this.date_range_statement.start_date = start_date;
      this.date_range_statement.end_date = end_date;
      this.getStatement();
    },
    getEvents() {
      sellerAccountService
        .getEvents(this.seller_id, this.currentPage, this.date_range_events)
        .then((response) => {
          this.events = response.data.seller_account_events;
          this.date_range_events = response.data.meta;
        });
    },
    getInvoices() {
      sellerAccountService.getInvoices(this.seller_id).then((response) => {
        this.invoices = response.data.seller_account_invoices;
      });
    },
    getSettings() {
      sellerAccountService.getSettings(this.seller_id).then((response) => {
        this.settings = response.data.seller_account_settings;
        this.global_settings = response.data.meta.global;
        if (this.settings) {
          let setting = this.settings.filter(
            (item) => item.category === this.selectedCategory
          )[0];
          this.selectedSettings = setting;
          this.global_settings = this.settings.filter(
            (item) => item.category === "global"
          )[0];
        }
      });
    },
    onClickChild(category) {
      this.selectedCategory = category;
      this.selectedSettings = this.settings.filter(
        (item) => item.category === this.selectedCategory
      )[0];
    },
    newEvent() {
      this.getBalance();
    },
    createEvent(value, description) {
      let formData = new FormData();
      formData.append("ad", this.event_attachment);
      this.$refs.modalAddMoney.hide();
      const data = {
        value: value,
        category: this.modalCategory,
        description: description,
        seller_id: this.seller_id,
      };
      sellerAccountService.createEvent(this.seller_id, data).then(() => {
        this.getBalance();
      });
    },
    goToSeller(sellerId) {
      this.$router.push({ path: `/seller_account/seller/${sellerId}` });
      this.pageReload();
    },
  },
  computed: {
    selectedSettingsProp() {
      if (typeof this.selectedSettings == "undefined") {
        return "global";
      } else {
        return this.selectedSettings;
      }
    },
  },
  watch: {
    selectedCategory: function () {
      this.selectedSetting = this.settings.filter(
        (item) => item.category === this.selectedCategory
      )[0];
    },
  },
};
</script>

<style scoped>
.nav-link.active {
  color: #2f5b70 !important;
  font-weight: 500 !important;
}

.seller-account-card {
  min-height: 500px;
  border: 1px solid #2f5b705c;
}

.seller-account-view-frame {
  padding-top: 2em;
}
</style>
