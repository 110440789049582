import i18n from "../../i18n";

export const accessibilityModule = {
  namespaced: true,
  state: () => ({
    size: 100,
    sizes: [80, 100, 150, 90, 120, 200],
    currentLocale: null,
    locales: [
      { iso: "GB", lang: "en", number: 0, text: "english" },
      { iso: "CN", lang: "zh_CN", number: 1, text: "chinese" },
      { iso: "BR", lang: "pt_BR", number: 2, text: "portuguese" },
    ],
  }),
  mutations: {
    UPDATE_SIZE(state, payload) {
      state.size = payload;
    },
    UPDATE_LOCALE(state, payload) {
      state.currentLocale = payload;
    },
  },
  actions: {
    handleSize({ commit }, payload) {
      const root = document.querySelector(":root");
      root.style.setProperty("font-size", payload + "%");
      localStorage.setItem("@nb:size", payload);

      commit("UPDATE_SIZE", payload);
    },
    load({ dispatch, state }) {
      const typography = Number(localStorage.getItem("@nb:size"));

      if (!isNaN(typography) && state.sizes.includes(typography)) {
        dispatch("handleSize", typography);
      }

      dispatch("checkLocaleFromStorage");
    },
    setLocale({ commit }, payload) {
      commit("UPDATE_LOCALE", payload);
      localStorage.setItem("savedLang", JSON.stringify(payload));
      i18n.locale = payload.lang;
    },
    checkLocaleFromStorage({ state, dispatch }) {
      const locale = JSON.parse(localStorage.getItem("savedLang"));
      let fallbackLocale = state.locales[0];
      if (locale) {
        dispatch("setLocale", locale);
        return;
      }

      let browserLang = navigator.language || navigator.userLanguage;
      if (browserLang == "pt-BR") {
        browserLang = browserLang.replaceAll("-", "_");
      }

      const browserLocale = state.locales.find(
        (item) => item.lang === browserLang
      );
      dispatch("setLocale", browserLocale || fallbackLocale);
    },
  },
  getters: {},
};
