<template>
  <div
    class="alert01 d-flex"
    :class="`
      alert01-${this.type}
      ${size}
      align-items-${verticalAling ? verticalAling : ''}
      justify-content-${aling ? aling : 'start'}
    `"
    :style="`height: ${height}px; width: ${width}px;`"
  >
    <NbIcon
      :class="`alert-icon text-${this.type}`"
      :icon="icon"
      :attributes="{
        class: 'mb-2',
        width: '1rem',
        height: '1rem',
      }"
    />
    <span>
      <span class="body-4" @click="$emit('click')">
        {{ message }}
      </span>

      <div v-if="link && link.src && link.text && link.newLine">
        <router-link :to="`${link.src}`" class="link-1" :target="link.target">
          {{ link.text }}
        </router-link>
      </div>
      <router-link
        v-else-if="link && link.src && link.text"
        :to="`${link.src}`"
        :target="link.target"
        class="link-1"
      >
        {{ link.text }}
      </router-link>
    </span>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "NbAlert",
  components: { NbIcon },
  props: {
    message: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      require: true,
    },
    icon: {
      type: String,
      require: false,
    },
    aling: {
      type: String,
      require: false,
    },
    verticalAling: {
      type: String,
      require: false,
    },
    width: {
      type: [String, Number],
      require: false,
    },
    height: {
      type: [String, Number],
      require: false,
    },
    size: {
      type: String,
      require: false,
    },
    link: {
      type: Object,
      require: false,
    },
  },
  data: () => ({}),
};
</script>

<style scoped>
.alert01 {
  padding: 0.56rem 1rem;
  border-radius: 0.25rem;
  color: var(--black);
  font: normal normal normal 16px/24px var(--font-family-base);
  letter-spacing: 0px;
}
.alert01:hover > span {
  text-decoration: underline;
}
.alert01-success {
  background: var(--success-10);
}
.alert01-attention,
.alert01-warning {
  background: var(--attention-10);
}
.alert01-error,
.alert01-danger {
  background: var(--error-10);
}
.text-error {
  color: var(--error) !important;
}
.text-attention {
  color: var(--attention) !important;
}
.text-success {
  color: var(--success) !important;
}
.alert-icon {
  vertical-align: middle;
  height: 1.06rem;
  margin-top: 0.2rem;
  margin-right: 0.68rem;
}
.alert01.lg.alert01-success {
  border-left: 3px solid var(--success);
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}
.alert01.lg.alert01-attention,
.alert01.lg.alert01-warning {
  border-left: 3px solid var(--attention);
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}
.alert01.lg.alert01-error,
.alert01.lg.alert01-danger {
  border-left: 3px solid var(--error);
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}
</style>
