<template>
  <BaseTemplate>
    <h2 class="text-center mt-5">v1.13</h2>
  </BaseTemplate>
</template>

<script>
import BaseTemplate from "./BaseTemplate";

export default {
  name: "Current-Version",
  components: { BaseTemplate },
};
</script>
