var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert01 d-flex",class:`
    alert01-${this.type}
    ${_vm.size}
    align-items-${_vm.verticalAling ? _vm.verticalAling : ''}
    justify-content-${_vm.aling ? _vm.aling : 'start'}
  `,style:(`height: ${_vm.height}px; width: ${_vm.width}px;`)},[_c('NbIcon',{class:`alert-icon text-${this.type}`,attrs:{"icon":_vm.icon,"attributes":{
      class: 'mb-2',
      width: '1rem',
      height: '1rem',
    }}}),_c('span',[_c('span',{staticClass:"body-4",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.link && _vm.link.src && _vm.link.text && _vm.link.newLine)?_c('div',[_c('router-link',{staticClass:"link-1",attrs:{"to":`${_vm.link.src}`,"target":_vm.link.target}},[_vm._v(" "+_vm._s(_vm.link.text)+" ")])],1):(_vm.link && _vm.link.src && _vm.link.text)?_c('router-link',{staticClass:"link-1",attrs:{"to":`${_vm.link.src}`,"target":_vm.link.target}},[_vm._v(" "+_vm._s(_vm.link.text)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }