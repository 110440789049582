import HttpService from "./HttpService";

export default class TablesService {
  constructor() {
    this.httpService = HttpService;
  }

  createColumns(data) {
    return this.httpService.post("/v1/table_columns", data);
  }
  getColumns() {
    return this.httpService.get("/v1/table_columns/");
  }
  setColumns(data) {
    const dataToSend = { user: { column_settings: data } };
    return this.httpService.put("/v1/table_columns/", dataToSend);
  }
  deleteColumns(id) {
    return this.httpService.delete("/v1/table_columns/" + id);
  }
}
