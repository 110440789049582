var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline-block"},[_c('button',{staticClass:"dropdown-toggle button-rows",attrs:{"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.$t("components.maxRows.button"))+": "),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.val)+" "),_c('NbIcon',{attrs:{"icon":"chevron-down","attributes":{
          class: 'mb-1',
          'stroke-width': 3,
          width: '.9rem',
          height: '.9rem',
        }}})],1)]),_c('div',{staticClass:"dropdown-menu nb-rows"},[_c('a',{staticClass:"dropdown-item button-options",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.val = 10;
        _vm.saveItemsPerPage();}}},[_vm._v("10")]),_c('a',{staticClass:"dropdown-item button-options",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.val = 20;
        _vm.saveItemsPerPage();}}},[_vm._v("20")]),_c('a',{staticClass:"dropdown-item button-options",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.val = 50;
        _vm.saveItemsPerPage();}}},[_vm._v("50")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }