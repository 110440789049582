<template>
  <div>
    <nav
      class="navbar fixed-top navbar-light bg-header dark"
      :class="showSidenav ? 'splitscreen-topBar' : 'fullScreen-topBar'"
    >
      <div class="d-inline-flex align-items-center">
        <NbSearchAheadInput
          :items="routes"
          :serializer="serializer"
          class="global-search-input"
          id="top-search"
          name=""
          placeholder="What are you looking for today?"
          :error="errors.globalSearch"
          size="sm"
          v-model="globalSearch"
          @selected="goTo($event)"
        />
        <div v-if="topBarOption === 1">
          <div
            v-if="typeof isSaving === 'boolean' && !isSaving"
            class="mx-3 body-2-semibold text-gray-60 anim-fade-one"
          >
            <NbIcon
              icon="save"
              :attributes="{
                class: 'mr-1 mb-1',
                width: '1rem',
                height: '1rem',
              }"
            />
            {{ $t("components.baseTemplate.topBar.lastEdit") }}
            <span class="body-2-bold text-gray-60">{{
              $t("components.baseTemplate.topBar.saved")
            }}</span>
          </div>
          <!-- v-if="typeof isSaving === 'boolean' && isSaving" -->
          <div
            v-if="typeof isSaving === 'boolean' && isSaving"
            class="mx-3 body-2-semibold text-gray-60 anim-fade-two"
          >
            <NbIcon
              icon="loader"
              :attributes="{
                class: 'mr-1 mb-1 spin',
                width: '1rem',
                height: '1rem',
              }"
            />
            {{ $t("components.baseTemplate.topBar.saving") }}...
          </div>
        </div>
      </div>
      <!-- 
    <transition name="slide-fade" >
      <NbTopMenu v-if="!showSidenav"/>
    </transition> 
    -->

      <div class="user-menu d-inline-flex align-items-center">
        <NbOption
          id="user-options"
          class="mx-1"
          :options="buttonOptions"
          optionsWidth="200"
          expandTo="right"
          size="sm"
          @input="userOptionClicked($event)"
        >
          {{ user.name }}
        </NbOption>
        <router-link to="/user_balance" class="btn-balance">
          {{ $t("balance") }}:
          <span :data-balance="balance > 0 ? 'credit' : 'debit'">
            {{ balance | currency }}
          </span>
        </router-link>
        <!-- <NbOption id="balance-options" class="mx-1" :options="balanceOptions" optionsWidth="150" expandTo="right" size="sm" @input="userOptionClicked($event)"><span class="balance">Balance: </span><span class="balance-price">R$: 0,00</span></NbOption> -->
        <NbNotificationBtn
          id="notification-options"
          class="mx-1"
          :options="buttonOptions"
          optionsWidth="345"
          expandTo="right"
          size="sm"
          @input="userOptionClicked($event)"
        >
          <NbIcon icon="bell" />
        </NbNotificationBtn>
        <LangButton ml="2" @loadSearchData="loadSearchData()" :user="user" />
        <!-- LangButton usar variavel para construir esse componente por ultimo -->
      </div>
    </nav>
    <ConditionsService />
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import SellerService from "@/services/SellerService";
import ConditionsService from "@/components/conditionsservice/ConditionsService.vue";
/* import NbTopMenu from "../menus/NbTopMenu.vue"; */
import NbSearchAheadInput from "@/components/input/text/NbSearchAheadInput.vue";

import NbIcon from "@/components/icons/NbIcon.vue";
import NbOption from "@/components/buttons/NbOption.vue";
import NbNotificationBtn from "@/components/buttons/NbNotificationBtn.vue";
import LangButton from "@/components/buttons/LangButton.vue";

import SellerAccountService from "@/services/SellerAccountService";

/* import MyNotifications from '@/components/mynotifications/MyNotifications.vue'; */

const userService = new UserService();
const sellerService = new SellerService();
const sellerAccountService = new SellerAccountService(); // remove checagem de subscription active

export default {
  name: "TopBar",
  components: {
    ConditionsService,
    /* NbSearchInput, */ NbSearchAheadInput,
    NbIcon,
    NbOption,
    NbNotificationBtn,
    LangButton /* NbTopMenu, */,
  },
  props: {
    showSidenav: {
      type: Boolean,
    },
  },
  filters: {
    currency(value) {
      const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formatter?.format(value);
    },
  },
  data() {
    return {
      user: userService.getLoggedInUser(),
      subscription_settings: null,
      showApp: false,
      routes: [],
      fontSize: 0.9,
      fontCommand: "large",
      flags: [
        { iso: "GB", lang: "en", number: 0, text: "english" },
        { iso: "CN", lang: "zh_CN", number: 1, text: "chinese" },
        { iso: "BR", lang: "pt_BR", number: 2, text: "portuguese" },
      ],
      docState: "saved",
      sellerNotifications: {
        count: 0,
        ids: null,
        elements: [],
      },
      errors: {},
      globalSearch: "",
      /* buttonOptionChosen: '', */
      isSaving: null,
      topBarOption: 0,
      balance: null,
      topBarVisibleForRoutes: [
        "order_create",
        "create_order",
        "Printing",
        "Insurance",
        "Accessibility",
        "Advanced",
        "TrackingPage",
        "company",
        "TrackingPageSettings",
      ],
      sellerId: null,
    };
  },
  created() {
    this.addEventListener();
    this.setTopBarOption();
    this.getCurrentSeller();
  },
  mounted() {
    this.checkFontSize();
  },
  methods: {
    showModalConditionService() {
      this.$helpers.openModal("conditions");
    },
    async getCurrentSeller() {
      const { data } = await sellerService.getCurrentSeller();
      this.sellerId = data.data.id;
      this.getBalance();
    },
    buildsearchData(route) {
      let buildedRoute = route;
      buildedRoute.meta.searchData = this.$t(
        `components.searchData.${buildedRoute.meta.searchData}`
      );
      return buildedRoute;
    },
    loadSearchData() {
      this.routes = [];
      const startRoutes = JSON.parse(
        JSON.stringify(this.$router.options.routes)
      );
      const finalRoutes = [];
      startRoutes.forEach((route) => {
        if (route.children) {
          route.children.forEach((children) => {
            if (children.meta && children.meta.searchData) {
              const current = this.buildsearchData(children);
              finalRoutes.push(current);
            }
          });
          return;
        }
        if (route.meta && route.meta.searchData) {
          const current = this.buildsearchData(route);
          finalRoutes.push(current);
        }
      });

      this.routes = finalRoutes;
    },
    goTo(event) {
      this.$router.push({ path: event.path });
    },
    serializer(route) {
      if (route.meta && route.meta.searchData) {
        return route.meta.searchData;
      }
      if (route.children) {
        route.children.forEach((children) => {
          if (children.meta && children.meta.searchData) {
            return children.meta.searchData;
          }
        });
      }
      return "";
    },
    userOptionClicked(command) {
      if (command === "logout" || command === "showModalConditionService") {
        this[command]();
        return;
      }
      this.$router.push({ path: `/${command}` });
    },
    checkActiveLang(lang) {
      if (lang == this.$i18n.locale) {
        return "active";
      }
      return "";
    },
    logout() {
      localStorage.removeItem("columns_configuration"); //force reset columns_configuration
      userService.deleteLoggedInUser();
      let prefix = "nb";
      const platform = this.$helpers.getPlatform();
      if (platform && platform != "nobordist") {
        prefix = platform;
      }
      this.$router.push(`/${prefix}-login`);
    },
    switchShowSidenav() {
      this.showSidenav = !this.showSidenav;
      localStorage.setItem("showSidenav", JSON.stringify(this.showSidenav));
    },
    checkFontSize() {
      if (JSON.parse(localStorage.getItem("fontSize"))) {
        this.fontCommand = JSON.parse(localStorage.getItem("fontSize"));
        this.changeFont();
        return;
      }
    },
    changeFont() {
      const pageContent = document.getElementById("main-container");
      if (this.fontCommand === "smaller") {
        pageContent.style.fontSize = `${this.fontSize - 0.25}rem`;
        localStorage.setItem("fontSize", JSON.stringify(this.fontCommand));
        return;
      }
      if (this.fontCommand === "small") {
        pageContent.style.fontSize = `${this.fontSize - 0.125}rem`;
        localStorage.setItem("fontSize", JSON.stringify(this.fontCommand));
        return;
      }
      if (this.fontCommand === "large") {
        pageContent.style.fontSize = `${this.fontSize}rem`;
        localStorage.removeItem("fontSize");
        return;
      }
      if (this.fontCommand === "larger") {
        pageContent.style.fontSize = `${this.fontSize + 0.125}rem`;
        localStorage.setItem("fontSize", JSON.stringify(this.fontCommand));
        return;
      }
    },
    getPlatform() {
      return this.$helpers.getPlatform();
    },
    /* remove checagem de subscription active
    getSettings() {
      sellerService.getCurrentSeller().then((response) => {
        this.seller_id = response.data.data["id"];
        sellerAccountService.getSettings(this.seller_id).then((response) => {
          this.subscription_settings = response.data.seller_account_settings.filter(item => item.category === 'subscription')[0];
        }); 
      })
    },
    */
    displayMaxCharacters(word, maxChars) {
      if (word.length > maxChars) {
        return word.substring(0, maxChars - 5) + "(...)";
      }
      return word;
    },
    timeAgo(time) {
      if (typeof time === "string") {
        time = +new Date(time);
      } else if (typeof time === "object") {
        if (time.constructor === Date) time = time.getTime();
      } else {
        time = +new Date();
      }

      const timeFormat = [
        [60, this.$t("myNotificationsPage.seconds"), 1],
        [120, this.$t("myNotificationsPage.minuteAgo"), "1 minute from now"],
        [3600, this.$t("myNotificationsPage.minutes"), 60],
        [7200, this.$t("myNotificationsPage.hourAgo"), "1 hour from now"],
        [86400, this.$t("myNotificationsPage.hours"), 3600],
        [172800, this.$t("myNotificationsPage.yesterday"), "Tomorrow"],
        [604800, this.$t("myNotificationsPage.days"), 86400],
        [1209600, this.$t("myNotificationsPage.lastWeek"), "Next week"],
        [2419200, this.$t("myNotificationsPage.weeks"), 604800],
        [4838400, this.$t("myNotificationsPage.lastMonth"), "Next month"],
        [29030400, this.$t("myNotificationsPage.months"), 2419200],
        [58060800, this.$t("myNotificationsPage.lastYear"), "Next year"],
        [2903040000, this.$t("myNotificationsPage.years"), 29030400],
        [5806080000, "Last century", "Next century"],
        [58060800000, "centuries", 2903040000],
      ];
      let seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      let i = 0;
      let format = 0;
      //Eslint maldito sacaneando a atribuição do valor em format
      while ((format = timeFormat[i++]))
        // eslint-disable-line
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
    addEventListener() {
      this.$root.$on("isSaving", (event) => {
        if (typeof event === "boolean") {
          this.isSaving = event;
        }
      });
    },
    setTopBarOption() {
      if (this.topBarVisibleForRoutes.includes(this.$route.name)) {
        this.topBarOption = 1;
        return;
      }
      this.topBarOption = 0;
    },
    getBalance() {
      sellerAccountService.getBalance(this.sellerId).then((response) => {
        this.balance = response.data.balance;
      });
    },
  },
  computed: {
    fields() {
      return [
        { key: "title", label: this.$t("myNotificationsPage.fields.title") },
        { key: "body", label: this.$t("myNotificationsPage.fields.body") },
        {
          key: "created_at",
          label: this.$t("myNotificationsPage.fields.created_at"),
        },
      ];
    },
    buttonOptions() {
      const conditionService = {
        text: this.$t("components.baseTemplate.profile.conditions"),
        value: "showModalConditionService",
      };
      const finalOptions = [
        {
          text: this.$t("components.baseTemplate.profile.myProfile"),
          value: "user_profile",
        },
        {
          text: this.$t("components.baseTemplate.profile.myCompany"),
          value: "company",
        },
        /* { text: this.$t('components.baseTemplate.profile.myNotifications'), value: 'notifications/notifications_list' }, */
        {
          text: this.$t("components.baseTemplate.profile.myContracts"),
          value: "accounts/contracts",
        },
        {
          text: `${this.$t("components.baseTemplate.logout")}`,
          value: "logout",
        },
        { text: "Versao 1.2.5", value: "action2", disabled: true },
      ];

      if (this.$helpers.getPlatform() !== "e-cross") {
        finalOptions.splice(finalOptions.length - 2, 0, conditionService);
      }

      return finalOptions;
    },
    balanceOptions() {
      return [
        { text: "My Plan", value: "user_profile" },
        { text: "Transactions", value: "user_profile" },
        { text: "Upgrade Plan", value: "user_profile" },
      ];
    },
  },
  watch: {
    //adicionar opcao para o topbar
    $route: {
      //deep: true,
      handler() {
        this.setTopBarOption();
        this.getBalance();
      },
    },
  },
};
</script>

<style lang="scss">
.global-search-input {
  margin-left: 2.4rem;
  width: 16.25rem;
}

.container-fluid .bg-header {
  z-index: 5;
  background: var(--backgroundTopBar); //zoiar dps
  //fill: var(--white) !important;
  /* box-shadow: 0px  12px 12px -15px #0000008f; removido** */
  //box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}
/* 
.container-fluid .bg-header svg,
.container-fluid .bg-header img {
  box-shadow: 0px 0px 0px 0px #333;
} 
*/

.navbar.splitscreen-topBar {
  transition: all 0.4s ease;
  padding-left: var(--sideNavBarWidth);
}
.navbar.fullScreen-topBar {
  transition: all 0.4s ease;
  padding-left: 1rem;
}

.navbar > .user-menu {
  color: var(--navText);
  font-weight: 600;
}
.navbar > .user-menu > span > small {
  color: var(--navText);
  font-weight: 600;
}
.navbar > .user-menu > .dropdown > a {
  transition: all 0.3s ease;
  color: var(--navText);
  font-weight: 600;
  padding: 0.4rem 0.7rem;
  margin: 0 0.2rem;
}
.navbar > .user-menu > .dropdown > a:hover {
  color: var(--navText);
  transition: all 0.8s ease;
  background-color: var(--topNavHover) !important;
  border-radius: 15px;
}

.navbar > .user-menu > .dropdown.show > a {
  background-color: var(--topNavHover) !important;
  border-radius: 15px;
  padding: 0.4rem 0.7rem;
  margin: 0 0.2rem;
}
.navbar > .user-menu > .dropdown.show > .dropdown-menu {
  background-color: var(--navBg) !important;
}
.navbar > .user-menu > .dropdown.show > .dropdown-menu > a,
.dropdown.show > .dropdown-menu > .dropdown-item {
  background-color: var(--navBg) !important;
  color: var(--navText);
  .icon-fontsize {
    cursor: pointer;
  }
}
.navbar > .user-menu > .dropdown.show > .dropdown-menu > a:hover,
.dropdown.show > .dropdown-menu > .dropdown-item:hover {
  color: var(--navText);
  background: var(--navItemHover) !important;
  //text-decoration: underline;
}

//Top-active
div > div > .router-link-exact-active {
  color: var(--activeMenu) !important;
  font-weight: 900;
}
div > div > .router-link-exact-active:hover {
  color: var(--activeMenuHover) !important;
}

.btn-navswitch {
  border: 1px solid var(--topNavHover);
  transition: all 0.8s ease;
  background: var(--navBg);
  font-weight: 600;
  /* background: linear-gradient(to right, var(--darkGreen), var(--navBg) 100%); */
  color: var(--navText) !important;
}
.btn-navswitch:hover {
  transition: all 0.8s ease;
  background: var(--topNavHover);
  /* background: linear-gradient(to right, var(--lightGreen), #2b3990 100%) !important; */
}

.nav-button {
  font-size: 25px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-title {
  font-size: 12px;
}

.balance-price {
  color: var(--success);
  text-align: left;
  font: normal normal bold 12px/18px Nunito Sans;
  letter-spacing: 0px;
}
.balance {
  color: var(--black);
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1050px) {
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out !important;
}

.slide-up-enter-from {
  opacity: 0 !important;
  transform: translateY(30px) !important;
}

.slide-up-leave-to {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
}

.btn-balance {
  background-color: var(--gray-05);
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  color: var(--black);
  transition: all 0.3s;
  &:hover {
    background-color: var(--gray-10);
    text-decoration: none;
    color: var(--black);
  }
  span {
    font-weight: bold;
    &[data-balance="credit"] {
      color: var(--success);
    }
    &[data-balance="debit"] {
      color: var(--danger);
    }
  }
}
</style>
