<template>
  <span>
    <NbIcon
      :id="id"
      :class="`${size} cursor-help`"
      icon="help-circle"
      :attributes="computedAttributes"
    />
    <b-popover
      :placement="placement"
      :target="externalId || id"
      triggers="hover focus"
      variant="dark"
    >
      <slot />
    </b-popover>
  </span>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "nbPopover",
  components: { NbIcon },
  props: {
    id: {
      type: String,
      required: true,
    },
    externalId: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "md",
    },
    placement: {
      type: String,
      default: "top",
    },
  },
  computed: {
    computedAttributes() {
      let finalWidthHeight = "1.1rem";

      if (this.size === "sm") {
        finalWidthHeight = ".8rem";
      }
      if (this.size === "lg") {
        finalWidthHeight = "1.6rem";
      }
      return {
        class: "mb-1",
        width: finalWidthHeight,
        height: finalWidthHeight,
      };
    },
  },
};
</script>

<style>
.cursor-help {
  cursor: help;
}
.b-popover-dark.popover {
  background-color: var(--gray-60) !important;
  border-color: var(--gray-60) !important;
}
.b-popover-dark.bs-popover-right > .arrow::before,
.b-popover-dark.bs-popover-right > .arrow::after {
  border-right-color: var(--gray-60) !important;
}
.b-popover-dark.bs-popover-bottom > .arrow::before,
.b-popover-dark.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--gray-60) !important;
}
.b-popover-dark.bs-popover-top > .arrow::before,
.b-popover-dark.bs-popover-top > .arrow::before,
.b-popover-dark.bs-popover-top > .arrow::after {
  border-top-color: var(--gray-60) !important;
}

.b-popover-dark .popover-body {
  padding: 1rem !important;

  color: var(--white) !important;
  font: normal normal normal 12px/18px var(--font-family-base) !important;
  letter-spacing: 0px;
  border-radius: 4px;
  background: var(--gray-60);
}

.sm {
  font-size: 0.75rem;
}
.md {
  font-size: 1rem;
}
.lg {
  font-size: 1.5rem;
}

.tooltip {
  position: absolute;
  top: 10px;
  transform: translateY(-50%);
  border-radius: 20px;
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: var(--white);
  color: black;
  /* box-shadow: 0 5px 10px var(--shadow); */
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  transition: all 0.7s ease;
}

li:hover .tooltip {
  top: -10px;
  transition: all 0.7s ease;
  opacity: 1;
  color: var(--primary);
  font-weight: bold;
}

.tooltip-mark:hover .tooltip {
  top: -20px;
  transition: all 0.7s ease;
  opacity: 1;
}
</style>
