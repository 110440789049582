export default {
  //components i18n.doc para encontrar a area desejada busque por components, words ou pages
  components: {
    nav: {
      HomeDashboard: "Home",
      Dashboard: "Dashboard",
      Quotes: "Quotes",
      New: "New",
      Orders: "Orders",
      OrdersManagement: "Management",
      Import: "Import",
      Create: "Create",
      Rejected: "Rejected",
      VolumeReverse: "Reverse Volumes",
      PickupDropoff: "Pickup / Dropoff",
      DutiesAndTaxes: "Duties & Taxes",
      Overpacks: "Overpacks",
      OverpacksManagement: "Management",
      Notifications: "Notifications",
      RulesConfiguration: "Rules Configuration",
      TrackingPage: "Tracking Page",
      NotificationSettings: "Tracking Notifications Settings",
      Settings: "Settings",
      ShippingRules: "Shipping Rules",
      QuickStart: "Quick Start",
      Advanced: "Advanced",
      BoxesProducts: "Products and Boxes",
      Boxes: "Boxes",
      Printing: "Printing",
      SalesChannels: "Sales Channels",
      Products: "Products",
      Users: "Users",
      Customers: "Customers",
      Documents: "Documents",
      Insurance: "Insurance",
      includeInsurance: "include insurance",
      Integrations: "Integrations",
      NewIntegration: "New Integration",
      CouriersList: "Couriers",
      ChannelsList: "Channels",
      PriceTablesDeliveryTime: "Price Tables & Delivery Time",
      Onboard: "Onboard",
      Downloads: "Downloads",
      support: "Support",
      myExperience: "Complete my experience",
      Archives: "Archives",
      Accessibility: "Accessibility",
      Account: "Account",
      UserProfile: "My Profile",
      Company: "My Company",
      NotificationsList: "Notifications",
      Contracts: "Contracts",
      TrackingRuleNotifications: "Tracking Notification Rules",
      FiscalPayments: "Fiscal Payments",
    },
    searchData: {
      settingsTracking: "Tracking Settings",
      home: "Home",
      userProfile: "My Profile",
      userProfileEdit: "User Profile Edit",
      company: "My Company",
      resetPassword: "Reset Password",
      trackOrders: "Track Order",
      newQuote: "New Quote",
      ordersList: "Order List",
      orderImport: "Order Import",
      orderCreateNew: "Order Create New",
      volumeReverseList: "Volume Reverse List",
      orderPickupDropoff: "Order Pickup Dropoff",
      overpacksList: "Overpacks List",
      notificationsConfig: "Notifications Config",
      notificationsCreate: "Notifications Create",
      notificationsTracking: "Notifications Tracking",
      notificationsSettings: "Tracking Notifications Settings",
      notificationsList: "Notifications List",
      settingsQuickstart: "Settings Quickstart",
      settingsShippingRules: "Settings Shipping Rules",
      boxesProductsList: "Boxes and Products List",
      boxesList: "Boxes List",
      settingsPrinting: "Settings Printing",
      settingsSalesChannels: "Settings Sales Channels",
      settingsProducts: "Settings Products",
      usersList: "Users List",
      usersCreateNew: "Users Create New",
      settingsCustomers: "Settings Customers",
      settingsDocuments: "Settings Documents",
      settingsInsurance: "Settings Insurance",
      userBalance: "User Balance",
      settingsAccessibility: "Settings Accessibility",
      contracts: "Contracts",
      dashboard: "Dash Board",
      trackingRuleNotifications: "Tracking Notification Rules",
      fiscalPayments: "Fiscal payments",
    },
    baseTemplate: {
      logout: "Logout",
      topBar: {
        sideNav: "Side Nav",
        sideNavPopover: "Shifts to TOP nav-bar",
        topNav: "Top Nav",
        topNavPopover: "Shifts to SIDE nav-bar",
        lastEdit: "Last changes",
        saved: "saved",
        saving: "Saving changes",
      },
      profile: {
        myProfile: "My Profile",
        myBalance: "My Balance",
        myCompany: "My Company",
        fontSize: "FontSize: ",
        myContracts: "My Contracts",
        conditions: "Conditions",
        myNotifications: "My Notifications",
      },
      termsOfService: "Terms of service",
    },
    chart: {
      bar: "Bar",
      line: "Line",
      area: "Area",
    },
    donutChart: {
      pie: "Pie",
      donut: "Donut",
      polarArea: "Polar Area",
    },
    mapChart: {
      hoverOverState: "Hover over a state",
    },
    onboardRow: {
      moreAbout: "More about {val} here.",
    },
    stepper: {
      step: "Step",
      agreeBeforeContinue:
        "Agree with the cost summary declaration before continue.",
    },
    boxesList: {
      newBox: "new box",
      newItem: "new item",
      specificationsOf: "Specifications of",
      containsHarmfulProducs: "This box contains harmful products",
      harmfilExample: "Examples of harmful products: lithium batteries,...",
      ifDoubt: "If you are in doubt, please ",
      seeHarmfulList: "see the full list of harmful materials",
      moreAbourShippingRules: "Click here to learn more about Shipping Rules",
      notContainsHarmfulProduct:
        "No, this box does not contain harmful products.",
      containsHarmfulProduct: "Yes, this box contains harmful products",
      productData: "Product Data",
      saveProduct: "Save product data for future orders",
      metric: "Metric (cm & kg)",
      imperial: "Imperial System (in & lb)",
      howAboutCreatingRule:
        "Did you know you could automate some settings? How about creating a rule?",
    },
    modalEditSellerMetric: {
      title: "Edit Metric Settings",
    },
    modalCreateCustomBox: {
      title: "Create a new box",
    },
    tableFilters: {
      title: "Filters",
      subTitle: "Select the values below to create a filter",
      addNewFilter: "Add new filter",
      clearFilters: "Clear Filters",
      filterBy: "Filter by: ",
      filteredBy: "Filtered by:",
      openFilterOptions: "Open Filter Options",
      clickToClearFilter: "Click to Clear All Filters",
      clickToFilter: "Click to Filter",
      trueText: "True",
      falseText: "False",
    },
    selectTableColumns: {
      title: "Select Table Column",
      button: "Table Columns",
      unSelectAll: "Unselect All Columns",
      selectAll: "Select All Columns",
      shownColumns: "Shown columns",
      hiddenColumns: "Hidden columns",
    },
    maxRows: {
      button: "Rows",
    },
    pagination: {
      prevText: "Prev",
      nextText: "Next",
    },
    boxesOverview: {
      mustHaveOne: "Boxes must have at least 1 item",
      packageDimenstions: "Package dimensions",
    },
    destructiveModal: {
      deleteBox: {
        title: "Delete box",
        cancel: "No, cancel",
        ok: "Yes, Delete",
        information: "Do you want to delete this box?",
      },
      addCustomBox: {
        title: "Add a new box",
        cancel: "No, thanks",
        ok: "Add now",
      },
      editMetric: {
        title: "Edit Metric Mode",
        cancel: "Back",
        ok: "Confirm",
      },
    },
    unsavedWorkModal: {
      discard: "Discard",
      draft: {
        title: "Resume previous draft?",
        resume: "Resume",
        information: "You have a previous draft last updated",
      },
      otherDraft: {
        title: "Wait while we are working",
        information: "Loading... ",
      },
    },
    nbTable: {
      wereFound: "were found",
      all: "All",
      onThisPage: "on this page",
      isSelected: "is selected | selected",
      worksOnlyCurrentPage:
        "This filter only works for the {onThisPageItems}  items shown on this page of the table, not the all {totalItems} items. To do this, use the filter button",
      sortBtn:
        "Sort the items on this page of the table. (Does not sort all items found)",
      properties: "Properties",
      tableOptions: "Table Options",
      changeVisibilityReorderColumns: "Change visibility and reorder columns",
    },
    footer: {
      poweredBy: "Powered by ",
      needHelp: "Need help",
      configureYourAcc: "Learn how to set up your account",
      moreAboutCompany: "Learn more about company data",
      moreAboutSignature: "Learn more about branding and signature",
      checkDocumentsAndTags: "Check out our guides on documents and labels",
      checkOurSupport: "Check out our guides or send us a message",
      learMoreAboutInsurance: "Learn more about insurance",
      learnMoreAboutWeightAndMeasurements:
        "Learn more about weight and measurements",
      learnMoreAboutAccessibilityOptions:
        "Learn more about accessibility options",
    },
  },

  locales: {
    ptBR: "Portuguese (BR)",
    en: "English",
    cn: "Chinese",
  },

  //words
  warning: "Warning",
  selectAnOption: "Select an option",
  remove: "Remove",
  next: "Next",
  back: "Back",
  generalData: "General data",
  suggestedAction: "Suggested Action",
  suggestedActions: "Suggested Actions",
  products: "Products",
  freight: "Freight",
  view: "View",
  actions: "Actions",
  confirmation: "Confirmation",
  confirm: "Confirm",
  cancel: "Cancel",
  cubedWeight: "Cubed Weight",
  yes: "Yes",
  no: "No",
  save: "Save",
  close: "Close",
  upload: "Upload",
  uploading: "Uploading",
  update: "Update",
  updating: "Updating",
  lastUpdate: "Last Update",
  loading: "Loading",
  ignore: "Ignore",
  invalid: "Invalid",
  enable: "Enable",
  disable: "Disable",
  edit: "Edit",
  create: "Create",
  creating: "Creating",
  delete: "Delete",
  deleted: "Deleted",
  clone: "Clone",
  resend: "Resend",

  site: "Site",
  postalCode: "Postal Code",
  country: "Country",
  city: "City",
  state: "State",
  insurance: "Insurance",
  generalDescription: "General Description",
  includeInsurance: "include insurance",
  hasInsurance: "Has Insurance",
  currency: "Currency",
  collectedTaxesCurrency: "Taxes Currency",
  orderCurrency: "Order Currency",
  senderWord: "Sender",
  sender: "Sender (DDP)",
  receiver: "Receiver (DDU)",
  justReceiver: "Receiver",
  taxDuties: "Tax and Duties",
  box: "Box",
  item: "Item",
  items: "Items",
  itemOrItems: "item | items",
  added: "added",
  created: "created",
  width: "Width",
  height: "Height",
  length: "Length",
  weight: "Weight",
  abbreviatedWidth: "W",
  abbreviatedHeight: "H",
  abbreviatedLength: "L",
  abbreviatedWeight: "W",
  emptyWeight: "Empty Weight",
  totalWeight: "Total Weight",
  sku: "Sku",
  name: "Name",
  fullName: "Full Name",
  email: "Email",
  placeholderEmail: "example@email.com",
  connect: "Connect",
  disConnect: "Disconnect",
  connected: "Connected",
  disConnected: "Disconnected",
  phone: "Phone",
  whatsapp: "Whatsapp",
  sms: "SMS",
  profile: "Profile",
  password: "Password",
  confirmPassword: "Confirm Password",
  confirmOldPassword: "Confirm Old Password",
  confirmEmail: "Confirm Email",
  description: "Description",
  originCountry: "Origin Country",
  category: "Category",
  hsCode: "HS Code",
  unitPrice: "Unit Price",
  unitWeight: "Unit Weight",
  taxValue: "Tax Value",
  quantity: "Quantity",
  total: "Total",
  totalValue: "Total Value",
  declaredValue: "Declared Value",
  salesTaxValue: "Sales Tax Value",
  dutiesTaxValue: "Duties Tax Value",
  freightValue: "Freight Value",
  freightCost: "Freight Cost",
  productsValue: "Products Value",
  dutiesTax: "Duties Tax",
  salesTax: "Sales Tax",
  totalCost: "Total Cost",
  deliveryTime: "Delivery Time",
  insuranceCost: "Insurance Cost",
  taxCost: "Tax Cost",
  ddpCost: "DDP Cost",
  details: "Details",
  page: "page",
  pages: "Pages",
  new: "New",
  dispatch: "Dispatch",
  dispatched: "Dispatched",
  notDispatched: "Not Dispatched",
  label: "Label",
  correios: "Correios",
  others: "Others",
  contract: "Contract",
  handlingTime: "Handling Time",
  dimensions: "Dimensions",
  date: "Date",
  add: "Add",
  rule: "Rule",
  optional: "Optional",
  group: "Group",
  download: "Download",
  addedIn: "Added in",
  invalidFileFormat: "Invalid file format",
  dragDropFile: "Drag and drop or click here",
  this: "this",
  these: "these",
  customer: "Customer",
  local: "Local",

  order: "Order",
  orders: "Orders",
  orderNumber: "Order Number",
  orderNumberSales: "Order number sales",
  orderCreate: "Order Create",
  orderImport: "Order import",
  overpack: "Overpack",
  overpacks: "Overpacks",
  product: "Product",
  quotes: "Quotes",
  taxes: "Taxes",
  taxPayer: "Tax payer",
  customerName: "Customer Name",
  customerCountry: "Customer Country",
  salesChannel: "Sales Channel",
  salesChannels: "Sales Channels",
  salesChannelOrderNumber: "Sales Channel Order Number",
  salesChannelOrderNumberShort: "Sales Channel Order Nº",
  trackingNumber: "Tracking Number",
  lastMileTrackingNumber: "Last Mile Tracking Number",
  firstMileTrackingNumber: "First Mile tracking Number",
  status: "Status",
  shipmentConfirmation: "Shipment Confirmation",
  overpacked: "Overpacked?",
  betweenDates: "Between Dates",
  startDate: "Start Date",
  endDate: "End Date",
  registrationDate: "Registration date",
  startRegistrationDate: "Start Registration Date",
  endRegistrationDate: "End Registration Date",
  creationDate: "Creation date",
  myCompanyName: "My Company",
  company: "Company",
  document: "Document",
  documentType: "Document Type",
  documentNumber: "Document Number",
  cpf: "CPF",
  cnpj: "CNPJ",
  cpfOrCnpj: "CPF/CNPJ",
  taxNumber: "Tax Number",
  number: "Number",
  address: "Address",
  addressNumber: "Address Number",
  complement: "Complement",
  comments: "Comments",
  contactUs: "Contact us",
  complete: "Complete",
  incomplete: "Incomplete",
  erro: "Erro",
  sellerName: "Seller's Name",
  sellerEmail: "Seller's Email",
  sellerPhone: "Seller's Phone",
  sellerCountry: "Seller's Country",
  sellerZipCode: "Seller's Zip Code",
  sellerCity: "Seller's City",
  sellerState: "Seller's State",
  sellerAddress: "Seller's Address",
  sellerAddressNumber: "Seller's Address Number",
  sellerAddressComplement: "Seller's Address Complement",
  sellerTaxNumber: "Seller's Tax Number",
  TaxNumberCpf: "Tax number is CPF",
  recommendedCnpj: "Is physical person",
  sellerWebsite: "Seller's Website",
  sellerLogoUrl: "Seller Logo URL (JPG or PNG)",
  isCommercialDestinationMandatory: "Is commercial destination?",
  sellerRfbCode: "Seller RFB Code",
  sellerLegalName: "Seller Legal Name",
  collectedIcms: "Collected ICMS",
  collectedIi: "Collected II",

  macro: "Macro",
  macroStatus: "Macro Status",
  showMacro: "Show Macro",
  micro: "Micro",
  microStatus: "Micro Status",
  showMicro: "Show Micro",
  active: "Active",
  inactive: "Inactive",
  blocked: "Blocked",
  allowed: "Allowed",

  nobordist: "Nobordist",
  carrier: "Carrier",
  seller: "Seller",

  selectOption: "Select an option",
  selectAll: "Select All",
  selectAllOf: "Select All",
  clear: "Clear",
  clearSelected: "Clear Selected",
  selected: "selected",
  typography: "Typography",
  ad: "ad",

  quickActions: "Quick Actions",
  necessaryAction: "Necessary Action",
  all: "All",
  allItems: "All Items",
  hello: "Hello",
  value: "Value",
  totalItems: "Total Items",
  destination: "Destination",
  table: "Table",

  checkOurTutorial: "Check out our tutorial",
  confirmDeletes: "Are you sure you want to delete these {val}",
  confirmDelete: "Are you sure you want to delete {val}",
  balance: "Balance",
  errorMessages: {
    isNotPDF: "File is not .pdf",
    tryAgain: "Please, check your information and try again",
    required: "Can't be blank",
    invalidCPF: "Invalid CPF",
    invalidCNPJ: "Invalid CNPJ",
    invalidDocument: "Invalid document",
    invalidZIPCode: "Invalid zip code",
    invalidPhone: "Invalid phone",
    invalidEmail: "Invalid E-mail",
    lowerMoney: "must be greater than 0.01",
    genericError: "發生了錯誤",
  },

  warningMessages: {
    notActiveAccount:
      "Your account is not active, please contact the adminstrator.",
  },

  orderStatus: {
    selectOption: "Select an option",
    new: "New",
    readyToShip: "Ready to ship",
    pickUp: "Pick Up",
    shipped: "Shipped",
    inTransit: "In transit",
    failedAttempt: "Failed attempt",
    outForDelivery: "Out for delivery",
    delivered: "Delivered",
    deliveryFailed: "Delivery failed",
    partialDelivery: "Partial delivery",
    partialDeliveryFailed: "Partial delivery failed",
  },

  hsCodes: {
    acessoryNoBattery: "Acessory (no-battery)",
    acessory: "Acessory (with battery)",
    audioVideo: "Audio Video",
    bagsLuggages: "Bags & Luggages",
    boardGames: "Board Games",
    booksCollectibles: "Books & Collectibles",
    cameras: "Cameras",
    computersLaptops: "Computers & Laptops",
    documents: "Documents",
    dryFoodsSupplements: "Dry Foods & Supplements",
    fashion: "Fashion",
    gaming: "Gaming",
    healthBeauty: "Health & Beauty",
    homeAppliances: "Home Appliances",
    homeDecor: "Home Decor",
    jewelry: "Jewelry",
    mobilePhone: "Mobile Phone",
    petAccessory: "Pet Accessory",
    shoes: "Shoes",
    sportLeisures: "Sport & Leisures",
    tablets: "Tablets",
    toys: "Toys",
    watches: "Watches",
    others: "Others",
  },

  //countries
  countries: {
    unitedStates: "United States",
    china: "China",
    france: "France",
    unitedKingdom: "United Kingdom",
  },

  kilograms: "Kilograms",
  meters: "Meters",
  inches: "Inches",
  libras: "Pounds",

  //pages
  quotePage: {
    title: "Get a quote",
    subtitle:
      'Enter the data and click on "see quote" at the end to check deadlines and values',
    shipmentInformation: "Shipment Information",
    shipFrom: "Ship From (origin)",
    pleaseEnterData: "Please enter data",
    shipDestination: "Ship To (destination)",
    orderInformation: "Order Information",
    boxInformation: "Box Information",
    createOrder: "Create Order",
    goToOrderCreate:
      "Would you like to create an order? Click here to continue from where you left off",
  },

  shippingRulesPage: {
    title: "Shipping Rules",
    subtitle:
      "On this page you can define default values for specific fields, so whenever you access a certain page it will already have the defined fields pre-filled.",
    newRule: "New rule",
    editRule: "Edit rule",
    guide: {
      title: "To get started, add at least one condition in the left column",
      subtitle:
        "If you prefer, click the button below to see the rules already configured.",
      goToShippingRules: "Go to shipping rule",
    },
    shippingRuleTab: {
      description:
        "Check and change (if necessary) fields that you previously configured. Rules with higher priority can override rules with lower priority.",
      createShippingRule: "Create shipping rule",
    },
    newRuleTab: {
      firstStepTitle: "Define your rule conditions",
      firstStepDescription:
        "Choose a condition to define when your action will be called (e.g.: all orders)",
      lastStepTitle: "Define the action that will occur",
      lastStepDescription:
        'In this field you will choose what should happen according to your condition. For example, for the "All orders" condition you can select "always ship from Location X".',
      newAction: "New Action",
      defineAnAction: "Define an action",
      ruleName: "Rule name",
      newCondition: "New condition",
    },
    fields: {
      priority: "Priority",
      name: "Name",
      condition: "Condition",
      action: "Action",
      expiration: "Expiration",
      is_active: "Active",
      quickActions: "Quick Actions",
    },
    tips: {
      separatedByCommas: "separated by commas",
      itemSku: "You can pass a list of skus, separated by comma",
      customerPostalCode:
        "You can pass a list of postal codes, separated by comma",
      defineFreightValueToBe:
        "The value entered will be in the order currency: USD or BRL",
      priority:
        "In case of 2 contradictory rules, the priority will define which one to apply",
      expiration: "Until when should the rule be applied",
      is_active:
        "If you want to create the rule without applying it for now, just de-select this field",
    },
    operator: "Operator",
    value: "Value",
    conditions: {
      allShipments: "All Shipments",
      containsCategory: "Contains category",
      destinationCountry: "Destination country",
      destinationState: "Destination state",
      itemSku: "Item SKU",
      numberOfItems: "Number of items",
      receiverPostalCode: "Receiver postal code",
      sellerPrice: "Seller price",
      shipmentWeight: "Shipment weight",
      channel: "Channel",
    },
    actions: {
      alwaysSortByCourier: "Always sort by courier",
      sortByCourierIf: "Order by Courier if no data provided",
      alwaysUseIncoterm: "Always use this Incoterm",
      useIncotermIf: "Use this Incoterm if no data provided",
      alwaysUseThisCourier: "Always use this Courier",
      useThisCourierIf: "Use this Courier if no data provided",
      neverUseCourier: "Never use Courier(s)",
      alwaysUseThisPackage: "Always use this package",
      useThisPackageIf: "Usar este Pacote se nenhum dado fornecido",
      neverUsePackage: "Never use Package(s",
      alwaysShipFrom: "Always send from",
      shipFromIf: "If no data provided, send",
      defineFreightValueToBe: "Defines fixed shipping",
      freightValueFromCost: "Defines shipping value with shipping cost",
    },
    conditionOperators: {
      greaterThan: "Greater than",
      greaterThanOrEqualTo:
        "@:shippingRulesPage.conditionOperators.greatherThan or equal to",
      lessThan: "Less than",
      lessThanOrEqualTo:
        "@:shippingRulesPage.conditionOperators.lessThan or equal to",
      EqualsTo: "Equals to",
      isAnyOf: "Is any of",
      isNoneOf: "Is none of",
      startsWith: "Starts with",
      shipmentContains: "Shipment contains",
      shipmentDoesNotContain: "Shipment does not contain",
      shipmentOnlyContains: "Shipment only contains",
    },
    rulePriorityOptions: {
      veryHigh: "Very high",
      high: "High",
      medium: "Medium",
      low: "Low",
      veryLow: "Very low",
    },
  },
  pickupPage: {
    title: "My pickup/dropoff",
    nextPickup: "Next pickup/dropoff",
    pickupDate: "Pickup scheduled for",
    createPickup: "Create Pickup",
    deletePickup: "Delete Pickup(s)",
    informDate: "Inform the date and time to create the Pickup",
    sureDeletePickup: "Are you sure you want to delete theses Pickups",
    informType: "Inform the Type, and for pickup, choose a date",
    pu: "Pickup",
    do: "Dropoff",
    nothing: "Nothing",
    fields: {
      orderId: "Order ID",
      orderNo: "Order No.",
      type: "Type",
      pickupID: "Pickup ID",
      pickupNumber: "Pickup Number",
      pickupData: "Pickup Date",
    },
  },
  ordersListPage: {
    title: "Orders Management",
    newOrder: "New order",
    createOrder: "Create order",
    quotation: "Quotation",
    ordersDeleted: "Order(s) deleted",
    found: "no order found | order | orders",
    orderNo: "Order No.",
    salesChOrderNo: "Sales Ch. Order No.",
    value: "Value",
    incoterm: "Incoterm",
    freightCost: "Freight Cost",
    postalCode: "Postal Code",
    salesChannel: "Sales Channel",
    customerName: "Customer Name",
    country: "Country",
    city: "City",
    contractName: "Contract name",
    creationDate: "Creation date",
    estimatedDeliveryDate: "Estimated delivery date",
    label: "Label",
    firstMileLabel: "First Mile Label",
    invoice: "Invoice",
    shipperConfirmationPDF: "Shipper Confirmation PDF",
    dangerousGoodPDF: "Dangerous Good PDF",
    status: "Status",
    trackingNumber: "Tracking Number",
    insuranceCoverage: "Insurance Coverage",
    overpacked: "Overpacked",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    lastMileCarrierNumber: "Last Mile Carrier Number",
    firstMileCarrier: "First Mile Carrier",
    firstMileTrackingNumber: "First Mile Tracking Number",
    mini: "MINI",
    packet: "PACKET",
    dispatchOrders: "Dispatch Order(s)",
    dispatchOrderError:
      "The following orders cannot be dispatched with this contract: ",
    downloadOrders: "Download orders",
    withoutOverpack: "Without Overpack",
    downloadVolumes: "Download Volumes",
    trackNumberNotFound: "Track NÂº not found",
    lastMileCarrierNotFound: "Last Mile Carrier NÂº not found",
    andMore: "and {val} more",
    generateOverpack: "Generate Overpack(s)",
    actions: "Actions",
    downloadOrderCosts: "Download Order Costs",
    deleteOrders: "Delete order(s)",
    generateLabel: "Generate label(s)",
    generateInvoice: "Generate invoice(s)",
    generateDocument: "Generate Document",
    generatePackingSlip: "Generate packing slip(s)",
    generateOnePackingSlip: "Generate packing slip",
    generateDangerousMultiple: "Generate Dangerous Goods Label(s)",
    ifDangerousPersonal: "Dangerous Good contact number",
    generateCN35: "Generate CN35",
    printingAll: "Printing All",
    toTrackingPage: "To Tracking Page",
    confirmDeleteOrder: "Are you sure you want to delete these orders?",
    collectedIcms: "Collected ICMS",
    collectedIi: "Collected II",
    isCommercialDestination: "Is Commercial Destination",
    sellerRfbCode: "Seller RFB Code",
    sellerLegalName: "Seller Legal Name",
    downloadModal: {
      title: "Check the processing downloads at... downloads page.",
      startDownload: "Start Download",
    },
    sellersName: "Seller's name",
    sellerTaxNumber: "Seller's tax number",
    sellerInformations: "Seller's informations",
    cn35GenerateFailed: "Generate cn35 for {orderId} failed.",
    goToDownloadPage:
      "Download file created, you can go to download page to check",
  },
  ordersImportPage: {
    title: "Import Orders",
    selectFile: "Find your .csv file and drag it to the area below.",
    downloadHere: "Download here",
    instructionsXLSFile: "the instructions XLS file",
    sendFile: "Enviar arquivo",
    downloadExampleFile: "Download template spreadsheet",
  },
  importResultPage: {
    title: "Import Summary",
    summary: "Summary",
    cancelImport: "Cancel Import",
    totalProduct: "Total Products",
    totalOrders: "Total Orders",
    totalValid: "Total valid",
    totalRejected: "Total Rejected",
    noValidProducts:
      "No valid products were found, please check your information and try again.",
    gotoProducts: "Go to Products",
    importLog: "Import Log",
    productNumber: "Product Number",
    productName: "Product Name",
    orderNumber: "Order Number",
    status: "Status",
    log: "Log",
    success: "Success",
    error: "Error",
    confirmCancel: "Are you sure you want to cancel this import?",
    return: "Return",
  },
  orderCreatePage: {
    documentsAreMissing: "Some important documents are missing!!!",
    includeDocuments: "Include all documents to create an order.",
    orderCreated: "Order created. ",
    orderUpdated: "Order updated. ",
    rejectOrderUpdated: "Reject Order updated. ",
    dontForgetOverpack:
      "Do not forget to create an overpack! | Do not Forget to dispatch this order!",
    receiver: "Receiver",
    sender: "Sender",
    shipment: "Shipment",
    courier: "Courier",
    overview: "Overview",
    boxes: "Boxes",
    finish: "Finish",
    receiverInformation: {
      title: "Please enter receiver data",
      receiverName: "Receiver name",
      helpText:
        "Here, please put all the information of whom will receive the products",
      saveContact: "Save contact",
      cpfOrCnpjHelpText:
        "Tax number used to identify the customer towards the destination country's customs. In {country}, this number is called {documentName} and has following format: {documentFormat}",
      postalCodeHelpText:
        "Zip code of the customer's locality. In {country}, this number has following format: {cepFormat}",
      customerPhoneHelpText: "Only the phones from {country} are accepted",
      customeraddressHelpText: "Only the address street, avenue, place, ...",
      customeraddressnumberHelpText:
        "Only the street number. If it contains a letter (123A for example), put the number here and the letter in the address complement field",
      customeraddresscomplementHelpText:
        "Additional information to the street number, building name, floor, apartment, door, mailbox, ...",
      customeraddressreferenceHelpText:
        "Point of reference to help the postman (Behind the bakery, for example)",
      rfbCodeHelpText: "RFB code is mandatory when the order is PRC",
      sellertaxnumberHelpText:
        "Tax number used to identify the sender towards the destination country's customs. Sometimes called VAT",
      sellerphoneHelpText:
        "Please pass the country code together with a '+' (+55991888282 for example)",
      selleraddressHelpText: "Only the address street, avenue, place, ...",
      selleraddressnumberHelpText:
        "Only the street number. If it contains a letter (123A for example), put the number here and the letter in the address complement field",
      selleraddresscomplementHelpText:
        "Additional information to the street number, building name, floor, apartment, door, mailbox, ...",
      remetenteiscpfHelptext:
        "If the tax number is of an actual person, check this box. If it is of a company, let this unchecked",
      phoneSameCountry:
        "Receiver's phone country must be the same as the country",
      customeraddressPlaceholder: "Victory Avenue street",
      customeraddressnumberPlaceholder: "1234",
      customeraddresscopmlementPlaceholder: "Apartment 5, 17th floor",
      customeraddressreferencePlaceholder: "Behind the bakery",
      sellerphonePlaceholder: "+17728991919",
      sellerpostalcodePlaceholder: "CA-1234",
      sellertaxnumberPlaceholder: "1234567890",
      selleraddressPlaceholder: "Victory Avenue street",
      selleraddressnumberPlaceholder: "1234",
      selleraddresscomplementPlaceholder: "Tower A, 6th floor",
      sellerwebsitePlaceholder: "www.seller.com",
      sellerLogoUrlPlaceholder: "https://www.seller-site.com/images/logo.png",
    },
    originInformation: {
      title: "Please enter sender data",
      helpText:
        "Here, please put all the information of whom will send the products",
      buttonCompanyInfo: "Fill with My Company's information",
      infoPhone: "Include the country code. For example: +55 22 22222222",
    },
    shipmentInformation: {
      title: "Please inform the data of the product to be sent",
      helpText:
        "Here, please put all the information regarding products and boxes as well as general informations",
      landedCost: "Cost with Tax",
      PRC: "Conforming Shipping (PRC)",
      landedCostWarning: "Required",
      PrcWarning: "Required",
      includeInsuranceNameHelpText:
        "If you want to include an insurance, check this box",
      ordernumberHelpText:
        "Unique number for you to identify the order among all your orders",
      saleschannelHelpText:
        "The sales channel that encharged you with the order",
      saleschannelordernumberHelpText:
        "Secondary order number, for your own use",
      incotermHelpText:
        "indicates who will pay the duties the customer (DDU) or the sender (DDP)",
      landedcostHelpText: "The item prices include all the taxes.",
      currencyHelpText:
        "All the price values informed below will be considered to be in this currency except ICMS and II taxes",
      taxesCurrencyHelpText: "The price values informed for ICMS and II taxes",
      itemdescriptionHelpText:
        "long description of the item, it should be as comprehensive as possible and include mark and model, as this information will be used for customs declaration",
      itemnameHelpText: "short description of the item, for your own use",
      itemhscodeHelpText:
        "harmonized tariff code, 6 or 8 digits identifying the product's nature",
      itemskuHelpText: "for your own use",
      itemboxHelpText:
        "indicates in which of the order boxes this item will be put. If you only use one box, just put '1'",
      boxemptyweightHelpText:
        "the weight of the empty box only, without any product in it",
      USBcharger: "USB charger",
      productDescriptionPlaceholder:
        "USB mini charger 2500 mA Sony - model MXS2009",
      knowYouCan: "Did you know that you can",
      registerBoxes: "register your boxes",
    },
    courierInformation: {
      title: "Choose your courier",
      helpText:
        "Here, please choose a courier and fill in the last pieces of information",
      fillAllOrderInfo:
        "Please fill all the order information before request a quote",
      changeToDdu: "For this contract and this value, please use DDU",
      changeToDdp: "For this contract, please use DDP",
      tooLowtoCover:
        "The value you put for the items is too low to cover freight and taxes",
      agreeSummary: "I agree with this cost summary declaration (required)",
      costSummary: "COST SUMMARY",
      selectedContract: "Selected Contract",
      getQuote: "Get a Quote",
      newContract: "New Contract",
      lastmilenumberHelpText:
        "The number we should use to track the order with our partners",
      dutiestaxHelpText:
        "National importation tax on the products and the freight",
      salestaxHelpText: "National tax on the sales",
      freightvalueHelpText:
        "The freight that will effectively be declared to the customs and will appear on all legal documents. You are responsible for this information, we highly recommend to use to same value present in teh Freight Cost field.",
      freightcostHelpText:
        "The freight calculated by our system, corresponding to the amount you will be charged for by {val} for this order",
      lastMileNumberPlaceholder: "NX123456789",
      fields: {
        item: "Box/Item",
        quantity: "Quantity",
        taxed: "Unit taxed",
        subtotalTaxed: "Subtotal taxed",
        untaxedDollar: "Unit untaxed",
        subtotalUntaxed: "Subtotal untaxed",
      },
      fieldsSummary: {
        totalProductsValue: "Total Untaxed Value",
        freightValue: "Freight Value",
        totalDeclaredValue: "Total Declared Value",
        taxes: "Taxes",
        totalValueWithTaxes: "Total Value With Taxes",
      },
      quoteHelpTexts: {
        freightCost:
          "The freight calculated by our system, corresponding to the amount you will be charged for by {val} for this order",
        dutiesTax: "National importation tax on the products and the freight",
        salesTax: "National tax on the sales",
        totalCost:
          "Total amount you will have to pay, including freight and (DDP only) taxes",
        deliveryTime:
          "Estimated time between order creation and final delivery to the customer",
      },
    },
    footer: {
      howCreateOrder: "How to create orders",
    },
    overviewInformation: {
      title: "Order overview",
      moreInfo: "More info",
    },
    stepsCardDetail: {
      stepOne: "Order data",
      stepTwo: "Check",
      stepThree: "Resume",
      stepFour: "Label and note",
      stepFive: "Dispatch",
      stepSix: "Finish",
    },
    titleColumns: {
      boxProducts: "BOXES AND PRODUCTS",
      carrier: "CARRIER",
      estimatedDelivery: "ESTIMATED DELIVERY",
      totalPartial: "TOTAL PARTIAL",
    },
    orderSummary: "Order summary",
  },
  ordersRejectedPage: {
    title: "Orders Rejected",
    dateImport: "Date of import",
    content: "Content",
    error: "Error",
  },
  dutiesAndTaxesPage: {
    title: "Duties and Taxes",
    orderStatus: "Order status",
    status: {
      new: "New",
      solved: "Solved",
      inProgress: "In Progress",
      waitingForPayment: "Waiting For Payment",
      priority: "Priority",
      needSelllerAction: "Need Selller Action",
      received: "Received",
      sentAgain: "Sent Again",
      paymentDone: "Payment Done",
      pending: "Pending",
      paidExternal: "Paid External",
    },
  },
  DutiesAndTaxesViewPage: {
    generalInformation: "General Information",
    taxesInformation: "Taxes Information",
    customerInformation: "Customer Information",
    creationDate: "Volume Creation Date",
    orderNumber: "Order Number",
    customerName: "Customer Name",
    customerCountry: "Customer Country",
    tracking: "Last Mile Tracking Number",
    incoterm: "Incoterm",
    estimatedTax: "Charged Tax",
    realTax: "Real Tax",
    payment: "Payment Status",
    message: "Message",
    expectedTax: "Expected Tax",
    productsValue: "Products Value",
    paymentCertificate: "Payment Certificate",
  },
  orderViewPage: {
    title: "Order Number",
    generateFirstMileLabel: "Generate First Mile Label",
    generateLastMileLagel: "Generate Last Mile Label",
    documentsLabel: "Documents and label",
    suggestedAction: "Suggested action",
    orderData: "Order Data",
    shippingInformation: "Shipping Information",
    customerInformation: "Customer Information",
    generateFMLabel: "Generate F. Mile label",
    generateBoxLabel: "Generate Box label",
    generateLMTN: "Generate L. Mile Label",
    generateInvoice: "Generate Invoice",
    boxData: "Box Data",
    trackingDetails: "Tracking details",
    orderNumber: "Order Number",
    newOrderNumber: "New Order Number",
    orderNumberMustBeUnique: "Order Number must be unique",
    observations: "Observations",
    shipperConfirmation: "Shipper Confirmation",
    shipping: "Shipping",
    incoterm: "Incoterm",
    type: "Type",
    standard: "Standard",
    haveInsurance: "Have Insurance",
    overpackReference: "Overpack Reference",
    shipFrom: "Ship From",
    totalVolumes: "Total Volumes",
    volume: "Volume",
    currentStatus: "Current status",
    trackingHistory: "Tracking History",
    carrierOrShipper: "Shipper",
    trackingStatus: "Tracking Status",
    message: "Message",
    attachment: "Attachment",
    addStatus: "Add Status",
    statusToAdd: "Status to Add",
    editOrder: "Edit Order",
    cloneOrder: "Clone Order",
    deleteOrder: "Delete Order",
    isReversable: "This contract doesn't allow to create reverse volumes",
    orderVolumes: "Order Volumes",
    createVolumeReverse: "Create Volume Reverse",
    volumesInReverse: "Volumes Already in Reverse Process",
    noVolumes: "All Volumes Have Been Added to Reverse Process.",
    noReversedVolumes: "No Volumes Have Been Added to Reverse Process.",
    deleteConfirm: "Are you sure you want to delete this order?",
    fieldsOrderCosts: {
      freight_cost: "Freight Cost",
      duties: "Duties",
      insurance: "Insurance",
      general_costs: "General Costs",
      fines: "Fines",
    },
    fieldsVolumeItems: {
      unitProducts: "Unit Products",
      unitFreight: "Unit Freight",
      unitTax: "Unit Tax",
    },
  },
  overpacksListPage: {
    title: "My overpacks",
    found: "no overpack found | overpack | overpacks ",
    readyToDispatch: "Ready to dispatch",
    downloadOverpacks: "Download overpacks",
    actions: "Actions",
    updateFirstMileInformations: "Update First Mile Information",
    deleteOverpacks: "Delete overpack(s)",
    setOverpackAs: "Set Overpack(s) as ",
    dispatchOverpack: "Dispatch Overpack(s)",
    generateLabel: "Generate label(s)",
    carrierName: "Carrier Name",
    overpackHasNotDispatched:
      "One of the selected overpacks has not been dispatched yet.",
    OverpackHasAlreadyDispatched:
      "One of the selected overpacks has already been dispatched.",
    trackingNumber: "Tracking number",
    generateLabelAndInvoice: "Generate label and invoice",
  },
  overpackViewPage: {
    title: "Overpack Reference",
    dispatchOverpack: "Dispatch Overpack",
    countryDestination: "Country of destination",
    totalVolumes: "Total volumes",
    totalOrders: "Total Orders",
    totalOrdersValue: "Total Orders Value",
    totalWeight: "Total Weight",
    contractName: "Contract Name",
    dispatchDate: "Dispatch date",
    shipFrom: "Ship From",
    originHub: "Origin Hub",
    addOder: "Add Order",
    noOrderToOverpack: "No orders available to overpack",
    carrierName: "Carrier Name",
    noCarrier: 'There is no freight for "Airport to Door" contracts',
  },
  groupConfigurationPage: {
    title: "Configurations",
    secondaryTitle: "Notifications",
    configuration: "Configuration",
    group: "Group",
    groups: "Groups",
    menu: "Menu",
    active: "Active",
    inactive: "Inactive",
    createConfiguration: "Create configuration",
    deleteConfiguration: "Delete configuration",
    addGroup: "Add group",
    updateGroup: "Update group",
    deleteGroup: "Delete group",
    groupInformation: "Group Information",
    choosenSalesChannels: "Choosen sales channels",
    salesChannelsWithoutGroups: "Sales channels without groups",
    noChoosenSalesChannels: "No choosen sales channel",
    noSalesChannelsWithoutGroups: "No sales channels without groups",
  },
  createGroupConfigurationPage: {
    title: "Create configuration",
    countries: "Countries",
    customer: "Customer",
    hide: "hide",
    checkpoints: "Checkpoints",
    addConfiguration: "Add configuration",
    showSeller: "Show seller",
    showSalesChannels: "Show sales channels",
    chosenCountries: "Chosen countries",
    newMenu: "New menu",
    existingMenu: "Existing menu",
  },
  groupConfigurationEditPage: {
    title: "Edit configuration",
    discardChanges: "Discard changes",
    leaveWithoutSaving: "Leave without saving",
    leave: "Leave",
    stay: "Stay",
  },
  groupConfigurationView: {
    title: "Configuration",
  },
  trackingPageSettingsPage: {
    title: "Tracking Page Settings",
    firstText:
      "Brand your tracking pages so your customers get a seamless experience when checking the status of their order.",
    secondText: "Your information can be edited anytime in",
    simulateYourTrackingPage: "Simulate your tracking page",
    showLogo: "Show logo",
    showContractInfo: "Show contact info",
    textShowContractInfo: "Contact page, social media, email and phone number.",
    displayAdvertising: "Display advertising",
    textDisplayAdvertising:
      "Minimum and recommended pixel dimensions: 1200 x 630.",
    uploadedImage: "Uploaded image:",
    displayFootnote: "Display footnote",
    footnote: "Footnote",
    footnotePlaceholder: "Thank you for supporting our business!",
    settingsUpdated: "Settings updated",
    trackingPageSimulation: "Tracking Page Simulation",
    orderNumber: "Order Number",
    estimatedDelivery: "Estimated delivery",
    latestStatus: "Latest status",
    dateExample: "August 23rd 2020 ",
    orderHasBeenCreated: "The order has been created",
    shippingInformation: "Shipping information",
    correiosStandard: "CORREIOS Standard",
    trackingNumber: "Tracking number",
    socialLinksLabel: "Social Media Links:",
    colorSchemeLabel: "Color Scheme:",
  },
  trackingSettingsPage: {
    title: "Tracking Page",
    subtitle: "Customize the tracking page to match your company",
    trackingPagePreview: "Tracking Page Preview",
    viewInFullScreen: "View page in full screen",
    colors: {
      title: "Color scheme",
      subtitle: "Choose the page color scheme",
      foregroundColor: "Foreground color",
      backgroundColor: "Background color",
      text_color: "Heading Text color",
      returnsToDefaultColors: "Returns to default colors",
    },
    brand: {
      title: "Show brand",
      subtitle: "To another image",
      changeBrandIn: "change the brand in",
      thereIsNoRegisteredBrand: "What happens if there is no registered brand?",
      insertCompanyName: "Enter company name",
      insertOtherText: "Insert other text:",
      insertOtherTextPlaceholder: "Other name",
      onClickBrand: "What happens when you click on the company brand?",
      forwardToCompanyWebsite: "Forward to the company website",
      brandIsNotClickable: "Anything. The brand is not clickable.",
    },
    contacts: {
      title: "Show contact information",
      subtitle: "Define which contact information to show.",
      selectSocialNetwork: "Select the social networks you would like to show",
      contactUsClick: 'Select what happens when you click "Contact Us"',
      sendMailTo: "Send mail to",
      forwartToWebsite: "Forward to website",
    },
    ad: {
      title: "Add ad",
      subtitle: "Add an ad about your company.",
      fileDropLabel:
        "Insert an image with your ad (recommended: 932px x 240px)",
      chooseImage: "Click or drag your image (.jpeg or .png) here",
      mainText: "Main text (if you leave it empty, it will not appear)",
      linkText: "Link text (if you leave it empty, it will not appear)",
      linkTextPlaceholder: "Get to know now",
      bannerLink: "Banner link",
    },
    footer: {
      title: "Footer",
      subtitle: "Define what to insert in the footer",
      footnote: "Footnote",
      onClickAction:
        "By clicking on the text in the Footer, you will be taken to the company's website.",
    },
  },
  settingsPage: {
    title: "Tracking Notifications Settings",
    subtitle:
      "This page allows you to change some settings for your tracking notifications, set up in {link} page",
    sendEmail: "Send email",
    emailConfirmed: "Email confirmed",
    domainVerified: "Domain verified",
    howtoVerifyDomain: "How to verify domain",
    textHowtoVerifyDomain1: "To verify your domain",
    textHowtoVerifyDomain2:
      "go to your DNS provider and add a TXT record with the following values:",
    textHowtoVerifyDomain3:
      "This will allow us to send emails from your domain.",
    textResendVerificationEmail: "Resend verification email",
    textYouWillSendVerificationEmail: "You will resend a verification email to",
    textSendFromEmail:
      "We will send out delivery notifications from this email address:",
    sendSms: "Send SMS",
    textSendSms:
      "By default, we will send out SMS notifications from Nobordist Company Phone Number.",
    sendFromYourCompanyPhoneNumber:
      "If you want to send from your company phone number, please contact your sales representative.",
    smsCostsWillBeCharged: "SMS costs will be charged.",
    sendWhatsapp: "Send Whatsapp",
    textSendWhatsapp:
      "By default, we will send out Whatsapp notifications from Nobordist Company Phone Number.",
    whatsappCostsWillBeCharged: "Whatsapp costs will be charged.",
    sendWebhook: "Send Webhook",
    textSendWebhook:
      "Choose here to which endpoint you want to send your webhook tracking notifications",
    webhook: "Webhook",
    url: "Url",
    key: "Header",
    header: "Authentication header name",
    addWebhookUrl: "Add webhook URL",
    disabling: "Disabling",
    enabling: "Enabling",
    editDomain: "Edit Domain",
    notificationEmail: "Notification Email",
    noPublicEmail:
      "Emails from public domains such as yahoo, gmail, outlook, etc. are prohibited",
    enableSmsText:
      "The SMS option is optional and will incur additional costs in using the Nobordist platform.",
    pleaseConfirmAdditionalCosts:
      "Please confirm you agree to these additional costs!",
    areYouSureWantToEnable: "Are you sure you want to enable?",
    areYouSureWantToDisable: "Are you sure you want to disable?",
    disableSmsText:
      "No sms will be sent, even if you configure them in the {link} page.",
    disableWhatsappText:
      "No Whatsapp will be sent, even if you configure them in the {link} page.",
    enableWhatsappText:
      "The Whatsapp option is optional and will incur additional costs in using the Nobordist platform.",
    method: "Method",
    value: "Value",
    headers: "Headers",
    endpoint: "Endpoint",
    noEmailConfigured: "No email configured",
    addEmail: "Add email",
    emailFormatIsInvalidOrNotAllowed: "Email format is invalid or not allowed",
    successfullyUpdatedSetting: "Successfully updated setting",
    successfullyResentEmailVerification:
      "Successfully re-sent email verification",
    hostname: "Hostname",
    type: "Type",
  },
  settingsViewPage: {
    title: "User Profile",
    warning: "Delete Profile",
  },
  settingsEditPage: {
    title: "Update user profile",
  },
  quickStartPage: {
    title: "Quick start configurations",
    firstText:
      "Define here related configurations to prefill automatically every related fields in the pages Quotes, Order creation, Order import & Products.",
    newConfiguration: "New Configuration",
    add: "Add",
    pleaseSelectOne: "Please select at least one",
    done: "Done",
    configurationAppliedTo: "Configuration applied to",
    noConfigApplied: "No configurations applied on this page",
    modify: "Modify",
    confirmChanges: "Are you sure you want to save the changes?",
    confirmDelete: "Are you sure you want to delete these configurations?",
    deleteConfiguration: "Delete Configuration",
    unsavedData: "Unsaved data",
    saveUnsavedOrCancel: "Please save unsaved data or cancel!",
    ok: "Okay",
    productCategory: "Product Category",
  },
  advancedPage: {
    title: "Advanced Settings",
    subTitle: "Define in which unit system you want to work",
    fistText: "Define here advanced settings",
    weightsMeasures: "Weights and Measures",
    weightSystem: "Weight System",
    settingsAppliedAll:
      "All measures will have to be passed in these units when you create an order, by API, importation or directly in the platform. All measures in the site and API will be shown in these units",
    changePlatformLanguage: "Change the platform language",
    measurementSystem: "Measurement System",
    dispatches: "Dispatches",
    dispatchesHelpText:
      "To start the operation with an order, you need to dipatch it (either clicking on â€œDispatchâ€ or via API. Here, you can automatize this dispatch. The order will be dispatched automatically as soon as it is created. Attention: once dispatched, you will not be able to edit or remove it anymore",
    autoSingle: "Automatically dispatch orders when created",
    cpfValidation: "CPF Validation",
    allowsCpfValidation:
      "Force strong validations on customer CPFs during order creation",
    cpfHelpText:
      "For each order to Brazil, we verify if the tax numberâ€™s format (CPF) is valid and return an error it case it isnâ€™t. Here, you can set some additional validations: We will systematically verify with Brazilian IRS (Receita Federal) if the Customer is authorised to receive packages from abroad and if the tax number passed is valid and belonging to them. In case there is some issue, we will return an error message This setting can lead to additional costs",
  },
  boxesProductsPage: {
    title: "Products and Boxes",
    subTitle:
      "Navigate through the following tabs to configure case and product templates",
    boxModels: "Box Templates",
    productModels: "Product Templates",
  },
  boxesPage: {
    title: "Box Patterns",
    subTitle:
      'On this page you can register pre-defined box models to reuse when creating your orders. To register a new model, click on "Add Box"',
    found: "no box found | box | boxes",
    autoSelect: "Auto Select",
    autoSelectToolTip:
      "The box with Auto label will be selected by default in the order create form.",
    auto: "Auto",
    addBox: "Add Box",
    noBoxFound: "You don't have any registered box yet.",
    createNewBox: "Create a new box",
    newBoxTemplate: "New Box Template",
    updateBox: "Update box's information",
    editBox: "Edit Box",
  },
  printingsPage: {
    title: "Printings configurations",
    standardConfig:
      "Define here standard configurations to printing options of the documentation",
    documentFormat: "Document format and settings",
    commercialInvoice: "Commercial Invoice",
    baterryConfirmation: "Battery Confirmation",
    ifDangerousPersonal: "Dangerous Good contact number",
    packingSlip: "Packing Slip",
    dangerousGood: "Dangerous Good",
    msds: "MSDS",
    shippingLabel: "Shipping Label",
    shippingLabelSubtitle: "The label that will go on your boxes",
    commercialInvoiceSubtitle:
      "Purchase and sale transaction document between the importer and the exporter",
    packingSlipLabelSubtitle: "Lists the products included in a shipment",
    batterySubtitle:
      "Authorization document necessary when the shipment contains batteries",
    dangerousGoodSubtitle:
      "Authorization listing the dangerous documents in the shipment",
    msdsSubtitle: "Material Safety Data Sheets",
    includeApendixLabel: "Include  Apendix Label",
    overpackLabel: "Overpack Label",
    devolutionAddress: "Devolution Address",
    userDevolutionAddress: "User your own devolution address",
    //options
    letterSizeFull: '"8.5x11" Letter Size (Full Page)',
    letterSizeHalf: '"8.5x11" Letter Size (Half Page)',
    thermalPrinter: '"4x6â€ (Thermal Printer)',
    //messages
    saveSuccess: "Printing settings saved successfully",
    saveTryLater: "Could not save, try again later",
    switchHelpText: "Include in Printing All",
    devolutionCustomHelpText: "Apply to all documents",
    ifYouPrefer: "If you prefer, you can also",
    theTrackingPage: "customize the tracking page",
    documentExample: "Document example",
    wantToLearnAboutLabels: "Want to learn all about labels and documents",
  },
  salesChannelsPage: {
    title: "Sales channels settings",
    found: "no sales channel found | sales channel | sales channels ",
    addSalesChannel: "Add sales channel",
    newSalesChannel: "New Sales Channel",
    updateSalesChannel: "Update sales Channel",
    createSalesChannel: "Create a new Sales Channel",
    salesChannelInfo: "Sales Channel information",
  },
  productsPage: {
    title: "Products Patterns",
    subTitle:
      'On this page you can register product models to reuse when creating your orders. To register a new model, select one of the available options in "Add Product"',
    newProductTemplate: "New Product Template",
    found: "no product found | product | products",
    addProduct: "Add Product",
    downloadProducts: "Download product(s)",
    deleteProduct: "Delete product(s)",
    selectHowAddProducts: "Select how would you like to add products",
    manual: "Manual input",
    import: "Import from file",
    browseFilesToUpload: "Browse your files and upload your products.",
    selectCsv: "Select a csv file to upload.",
    downloadHere: "Download here",
    csvFile: "the CSV file template",
    createProductTitle: "Fill information to create your product",
    updateProduct: "Update product's information",
    skuHelptext: "unique code identifying your product",
    nameHelptext:
      "Short description of what the product is (e.g. White Shoes) used for your own internal use only",
    descriptionHelptext:
      "Used for customs declaration. Please be as specific as possible, including the mark and model of the product",
    categoryHelptext:
      "Category of product. The HsCode will be automatically filled up depending on this choice. Please use “Others” if you want to set a specific HS Code",
    hs_codeHelptext:
      "6 or 8 digits fiscal code identifying the product. Please see Harmonized System (HS) Codes",
    dimensionsHelptext:
      "The dimensions are informed follwong format: Width x Height x Length",
  },
  userListPage: {
    title: "Users management",
    found: "no user found | user | users",
    addUser: "Add User",
    addUserTitle: "Create a new user",
  },
  userCreatePage: {
    title: "Create account",
    create: "Create",
    account: "Account",
    companyInformation: "Company Information",
  },
  customersPage: {
    title: "My customers",
    subtitle: "Customer management.",
    subtitle2:
      "If you prefer, click on a customer's name to edit and see other actions.",
    found: "no customer found | customer | customers",
    newCustomer: "New customer",
    addCustomer: "Add Customer",
    downloadCustomers: "Download customer(s)",
    deleteCustomer: "Delete customer(s)",
    selectHowAddCustomer: "Select how would you like to add customers",
    browseFilesToUpload: "Browse your files and upload your customers.",
    addCustomerTitle: "Fill in the information to add your customer",
    documentTypeCaption: "Tax id required only for Brazil",
    onlyNumberCaption: "only number",
    addressQuarter: "Address Quarter",
    addressReference: "Address Reference",
    updateCustomer: "Update customer's information",
    customerData: "Customer Info",
    importingFile: "Importing file",
    importedFile: "Imported file",
    failedToImportFile: "Failed to import file",
  },
  documentsPage: {
    title: "Documents",
    subtitle:
      "Check the pre-registered documents and add the documents you already have, linked to categories and HS Codes.",
    documentName: "Document Name",
    newDocument: "New Document",
    addDocument: "Add document",
  },
  insurancePace: {
    title: "Insurance",
    subtitle: "Choose how insurance should be calculated.",
    insuranceValue: "Shipping coverage",
    insuranceValueCaption:
      "Define whether or not Insurance should cover the shipping cost",
    choosHowInsuranceShouldBeCalculated:
      "Choose how insurance should be calculated",
    useSellingPrice: "Use Sales price",
    alwaysInsure: "Use fixed price",
    use: "Use fixed percentage of",
    ofSellingPrice: "of the sales price.",
    includeShippingCost: "Include Shipping Cost in coverage?",
    yesShipCoveredClain: "Yes, cover the shipping cost (recommended).",
    noCoverItemOnly: "No, only cover the value of the item",
    calculationBase: "Calculation basis",
    calculationBaseHelpText:
      "Objective information better explaining the context.",
    freightCoverageHelpText:
      "Objective information better explaining the context.",
  },
  integrationsPage: {
    title: "Integrations",
    firstText:
      "Connect to your other accounts to manage all your orders on Nobordist platform",
    connectMabang: "Connect to MABANG ERP",
    getToken: "Get token",
    getTokenCaption: "Click on the token to copy it to clipboard",
    getTokenText:
      "Get your token and use it to connect your Mabang's account to Nobordist.",
    getTokenMoreInfo: "For more information, check our tutorial",
    dhlTitle: "Connect your DHL Account.",
    dhlText:
      "You need an existing DHL Express account and developer credentials to complete this form.",
    dhlOkButton: "Connect to DHL",
    anjunTitle: "Connect your Anjun Account.",
    anjunText:
      "You need an existing Anjun account and developer credentials to complete this form.",
    anjunOkButton: "Connect to Anjun",
    csatTitle: "Connect your CSAT Account.",
    csatText:
      "You need an existing CSAT account and developer credentials to complete this form.",
    csatOkButton: "Connect to CSAT",
    totalTitle: "Connect your Total Express Account.",
    totalText:
      "You need an existing Total Express account and developer credentials to complete this form.",
    totalOkButton: "Connect to Total Express",
    bringerTitle: "Connect your Bringer Account.",
    bringerText:
      "You need an existing Bringer account and developer credentials to complete this form.",
    bringerOkButton: "Connect to Bringer",
    hercoTitle: "Connect your Herco Account.",
    hercoText:
      "You need an existing Herco account and developer credentials to complete this form.",
    hercoOkButton: "Connect to Herco",
    kparcelTitle: "Connect your K-PARCEL Account.",
    kparcelText:
      "You need an existing K-PARCEL account and developer credentials to complete this form.",
    kparcelOkButton: "Connect to K-PARCEL",
    mabangTitle: "Connect your MABANG Account.",
    mabangText:
      "You need an existing MABANG account and developer credentials to complete this form.",
    mabangOkButton: "Connect to MABANG",
    sinerlogTitle: "Connect your SINERLOG Account.",
    sinerlogText:
      "You need an existing SINERLOG account and developer credentials to complete this form.",
    sinerlogOkButton: "Connect to SINERLOG",
    skypostalTitle: "Connect your Sky Postal Account.",
    skypostalText:
      "You need an existing Sky Postal account and developer credentials to complete this form.",
    skypostalOkButton: "Connect to Sky Postal",
    bilTitle: "Connect your Bil Account.",
    bilText:
      "You need an existing Bil account and developer credentials to complete this form.",
    bilOkButton: "Connect to Bil",
    correiosTitle: "Connect your Correios Account.",
    correiosText:
      "You need an existing Correios Express account and developer credentials to complete this form.",
    correiosOkButton: "Connect to Correios",
    reverse: " Reverse",
    fedexTitle: "Connect your Fedex Account.",
    fedexText:
      "You need an existing Fedex Express account and developer credentials to complete this form.",
    fedexOkButton: "Connect to Fedex",
    shipstationTitle: "Connect your Shipstation Account.",
    shipstationText:
      "You need an existing Shipstation Express account and developer credentials to complete this form.",
    shipstationOkButton: "Connect to Shipstation",
    shopifyTitle: "Connect your Shopify Account.",
    shopifyText:
      "You need an existing Shopify Express account and developer credentials to complete this form.",
    shopifyOkButton: "Connect to Shopify",
    upsTitle: "Connect your Ups Account.",
    upsText:
      "You need an existing Ups Express account and developer credentials to complete this form.",
    upsOkButton: "Connect to Ups",
    vtexTitle: "Connect your Vtex Account.",
    vtexText:
      "You need an existing Vtex Express account and developer credentials to complete this form.",
    vtexOkButton: "Connect to Vtex",
    lionTitle: "Connect your 360 Lion Account.",
    lionText:
      "You need an existing 360 Lion account and developer credentials to complete this form.",
    lionOkButton: "Connect to 360 Lion",
    mailAmericasTitle: "Connect your Mail Americas Account.",
    mailAmericasText:
      "You need an existing Mail Americas Express account and developer credentials to complete this form.",
    mailAmericasOkButton: "Connect to Mail Americas",
    br1ExpressTitle: "Connect your BR1 Express Account.",
    br1ExpressText:
      "You need an existing BR1 Express Express account and developer credentials to complete this form.",
    br1ExpressOkButton: "Connect to BR1 Express",
    accNick: "Account Nickname",
    accPass: "Password",
    accNumber: "Account Number",
    siteId: "Site ID",
    user: "User",
    admCode: "Administrative Code",
    contract: "Contract",
    name: "Name",
    carrier: "Carrier",
    cooperation: "Cooperation",
    price_table: "Price Table",
    delivery_time: "Delivery Time",
    key: "Key",
    value: "Value",
    senha: "Password",
    usuario: "User",
    loginHash: "Login",
    cartaoPostagem: "Post Card",
    authorization: "Authorization",
    token: "Token",
    line_code: "Line Code",
    remetenteId: "Remetente Id",
    externalCustomerId: "External Customer Id",
    externalReferenceCode: "External Reference Code",
    dispatchServiceCode: "Dispatch Service Code",
    shipperAccountNumber: "Shipper Account Number",
    billingAccountNumber: "Billing Account Number",
    channelCode: "Channel Code",
    userCode: "User Code",
    userKey: "User Key",
    copaId: "Copa Id",
    boxId: "Box Id",
    firstMileServiceCode: "First Mile Service Code",
    serviceCode: "Service Code",
    userId: "User Id",
    logisticsServerToken: "Logistics Server Token",
    contractDeleted: "Contract is deleted",
    contractNotdeleted: "Contract CAN'T be deleted!",
    contractNotCreated:
      "The connection could not be created, please try again later",
  },
  onboardPage: {
    title: "Welcome to Nobordist <b>{val}</b>. Let's Get Started!",
    linkText: "For more information, check our tutorial",
    quickStart: "QuickStart",
    quickStartText:
      "Define related configurations to prefill automatically every related fields in the pages Quotes, Order creation, Order import & Products",
    quickStartBtn: "Quick Start",
    order: "Order",
    orderText: "Create orders via the portal or via file import",
    orderBtn: "Create Order",
    printing: "Printing",
    printingText:
      "Define standard configurations to printing options of the documentation",
    printingBtn: "Printing",
    connectSellingChannel: "Connect a selling channel",
    connectSellingChannelText:
      "Connect to integrated sales channels. We gather all your orders to simplify order management",
    connectSellingChannelBtn: "Connect Selling Channel",
    notification: "Notifications",
    notificationText:
      "Create smart communications improve your customers after-sales experience",
    notificationBtn: "Notifications",
  },
  insightsPage: {
    volumesByRegion: "Volumes by region",
    shippedVolumes: "Shipped Volumes",
    customerEngagement: {
      pannel: "Pannel",
    },
    customerOverview: {
      topFiveCustomers: "Top 5 Most Valuable Customers",
      orderByBuyer: "Orders by Buyer's State",
    },
    overview: {
      volumeStatus: "Volume Status",
    },
    salesTrend: {
      sales: "Sales",
      topSellingProducts: "Top Selling Products",
      salesByCategory: "Sales by Category",
      salesBySalesChannel: "Sales by Sales Channel",
    },
  },
  companyPage: {
    title: "My Company",
    subTitle: "Check and update, if necessary, your company information",
    generalData: "General Data",
    brandSignature: "Brand and signature",
    legalName: "Legal Name",
    rfbCode: "RFB Code",
    thisInfoWllBeUsed:
      "This information will be used on your branded communications",
    addSignature: "Add Signature",
    showSignature: "Show Signature",
    addLogo: "Add Logo",
    companyInformation: "Company Information",
    companyName: "Company Name",
    companyID: "Internal Nobordist Reference",
    checkBrandTab: "This field can be changed in the Brand and signature tab",
    usedByNB: "Used by Nobordist as internal reference",
    customerID: "Customer ID",
    websiteURL: "Website URL",
    taxNumber: "Tax Number",
    authorizeText:
      "I authorize the use of this signature on mandatory shipping documents.",
    devolutionAddress: "Devolution Address",
    devolutionAddressSubtitle:
      "Orders with unresolved issues will be returned to this address",
    ownDevolutionAddress: "Use this address as the devolution address",
    editCompany: "Edit Company",
    addressComplement: "Address Complement",
    sellerAddress: "Seller Addresses",
    addSellerAddress: "Add Seller Address",
    noSellerAddressFound: "You don't have any registered address yet.",
    createNewAddSellerAddress: "Create a new address",
    found: "no seller address found | seller address  | seller addresses",
    editAddSellerAddress: "Update address's information",
    generalDataPage: {
      subTitle: "Confira e edite dados da empresa, se preciso",
      companySubTitle: "Check and edit company data, if necessary",
      companyAddress: "Company Address",
      companyAddressSubTitle: "This is the company address",
    },
    brandSignaturePage: {
      visualTitle: "Visual identity",
      visualSubTitle: "Configure company colors, branding and signature",
      signatureTitle: "Signature",
      signatureSubTitle:
        "Configure the signature to appear on company documents",
      noImageAdded: "No image added",
      clickOrDrag: "Click or drag your image here",
      companyBrand: "Company brand",
      companySignature: "Company signature",
      recomendedWidth: "Recommended: horizontal, 180px width, png",
      companyColors: "Company colors",
      reflectOn: "Will be reflected on",
      and: "and",
      foregroundColor: "Foreground color",
      backgroundColor: "Background color",
      textColor: "Text color",
      returnTo: "Return to ",
      defaultColors: "default colors",
      authUse: "Authorize use",
    },
  },
  error404Page: {
    title: "Tracking Number not found!",
    tryAgain: "Try again",
  },
  homePage: {
    subtitle: "What would you like to do today?",
    integrateEcommerce: "Integrate e-commerce",
    quotation: "Quotation",
    newQuotation: "New quotation",
    newOrder: "New order",
    addOrders: "Add orders",
    addOrdersManually: "Add orders manually.",
    simulateYourDelivery: "Simulate your delivery.",
    uploadMultipleOrders: "Upload multiple orders.",
    addYourFavoriteStores: "Add your favorite stores.",
    connectEcommerce: "Connect e-commerce",
    freightValue: "Freight value",
    freightCost: "Freight cost",
    averageOrderCost: "Average order cost",
    rejectedOrders: "Rejected orders",
    createdOrders: "Created orders",
    totalAwaitingYourAction: "{total} orders awaiting your action",
    totalAwaitingPickup: "{total} orders awaiting pickup",
    totalShippedOrders: "{total} shiped orders",
    last30Days: "Last 30 days",
  },
  loginPage: {
    login: "Login",
    welcome: "Welcome",
    pleaseYourAcc: "Please enter your account",
    forgotPassword: "Forgot your password",
    notRegisteredYet: "Not registered yet",
    checkOur: "Check out our",
    generalConditionsService: "Conditions of Service",
    resetPasswordSent: "Reset password email has sent! Check your inbox.",
    sendResetPasswordInstructions: "Send me reset password instructions",
    iAgree: "I agree",
    agreementText:
      "By cheking this box, you acknowledge that you have read and agree     to our General Conditions of Service to be bound by this Agreement.",
    createYourAccount: "Create your account",
    fillInfoToCreateAcc: "Fill in the following fields to create your account",
    userData: "User data",
    pleaseYourData: "Please fill your data",
    companyData: "Company data",
    pleaseCompanyData: "Please fill company data",
    hasRegistration: "Already registered",
    accessMyAcc: "Access my account",
    needHelp: "Need help",
    talkWithOur: "Talk to one of our consultants",
    ifCheckOut: "If you prefer, check out our",
  },
  passwordNewPage: {
    title: "Reset my password",
    registeredEmail: "Registered email",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    passwordDosentMatch: "The password and password confirmation doesn't match",
    passwordChanged: "Password changed",
  },
  trackMyOrderPage: {
    title: "Track My Order",
    subtitle: "Waiting for something? Track your order now!",
    trackingNumber: "Tracking Number",
    placeholderTrackingNumber: "Example: BRBR771909457310",
    taxIdentificationNumber: "Tax identification number (CPF/CNPJ)",
    track: "Track",
    enterTtrackingNumber: "Please enter a tracking number",
    enterIdentificationNumber:
      "Please enter the corresponding tax identification number",
    trackingNotFound: "Tracking number not found",
    taxIdentificationNotFound: "Wrong tax identification number",
  },
  trackMyOrderViewPage: {
    title: "Track My Order",
    contactUs: "Contact us",
    statusBar: {
      created: "Created",
      shiped: "Shiped",
      inTransit: "In Transit",
      outOfDelivery: "Out of delivery",
      delivery: "Delivery",
    },
    deliveredBby: "Delivered by",
    estimatedDelivery: "Estimated delivery",
    destination: "Destination",
    fromNow: "from Now",
    days: "days",
    late: "late",
    latestStatus: "LATEST STATUS",
    lastMileTrackingNumber: "Last Mile Tracking Number",
    expandOrderHistory: "Expand order history",
    updateByEmail: "UPDATES VIA EMAIL",
    authEmail: "I authorize emails with updates about this order",
    shippingInformation: "SHIPPING INFORMATION",
    lastMilePartner: "Last mile partner",
  },
  userProfilePage: {
    title: "My Profile",
    firstText: "Check and update, if necessary, your personal information",
    checkAndEditUser: "Check and edit your data if necessary",
    loginInfo: "Login information",
    checkAndEditLogin:
      "If necessary, edit the data you use to access the platform",
  },
  userProfileEditPage: {
    title: "Update my profile",
    updatedText: "Profile updated",
  },
  downloadsPage: {
    title: "Archives",
    subtitle: "All downloads are centralized on this page",
    errors: "Errors",
    found: "no download found | download | downloads",
    status: {
      failed: "failed",
      processing: "processing",
      done: "done",
      starting_upload: "starting upload",
      checking_csv_format: "checking csv format",
      checking_columns: "checking columns",
      checking_equal_orders_volumes: "checking equal orders volumes",
      checking_cells: "checking cells",
      validation_success: "validations ended succesfully! creating the table",
    },
    fields: {
      id: "ID",
      type: "Type",
      status: "Status",
      fileName: "File Name",
      creationDate: "Creation Date",
      messages: "Messages",
    },
  },
  volumesReversePage: {
    title: "Volumes Reverse",
    viewTitle: "Reverse ID",
    reverseNumber: "Reverse Number",
    reverseVolume: "Reverse Volume",
    reference: "Tracking Number",
    salesChannelName: "Sales Channel Name",
    volumeId: "Volume ID",
    found: "no volume reverse found | volume reverse | volumes reverse",
    fields: {
      id: "ID",
      volumeId: "Volume ID",
      reverseNumber: "Reverse Number",
      addressComplement: "Address Complement",
      orderItemsName: "Order Items Name",
      orderItemsDescription: "Order Items description",
      orderItemsHsCode: "Order Items HS Code",
    },
    orderErroTicket: "orders have an error when generating the e-ticket",
    orderWaitingTicket: "orders awaiting e-ticket creation",
  },
  myNotificationsPage: {
    found: "no notification found | notification | notifications",
    readBtn: "mark as read",
    notReadBtn: "mark as new",
    viewAllNotifications: "View All Notifications",
    showAll: "Show all",
    response: "Response",
    reply: "Reply",
    fields: {
      title: "Subject",
      body: "Notification",
      created_at: "Date",
      isNew: "New",
      isRead: "Read",
      type: "Type",
    },
    noNewNotifications: "You don't have new notifications",
    seconds: "seconds",
    minuteAgo: "1 minute ago",
    minutes: "minutes",
    hourAgo: "1 hour ago",
    hours: "hours",
    yesterday: "yesterday",
    days: "days",
    lastWeek: "Last week",
    weeks: "weeks",
    lastMonth: "Last month",
    months: "months",
    lastYear: "Last year",
    years: "years",
  },
  myNotificationsListPage: {
    title: "Notifications",
    found: "no notification found | notification | notifications",
    filters: {
      isNew: "New or Read",
    },
    allNotifications: "All notifications",
    view: "View",
    youHaveNewNotification: "You have new unread notifications.",
  },
  priceTablesPage: {
    titlePrice: "Prices Table",
    titleDelivery: "Delivery Times",
    importPricesTable: "Import Prices Table",
    importDeliveryTime: "Import Delivery Time",
    suretodelete: "Are you sure to delete",
    selectCsv: "Select a csv file to upload",
    fileUploaded: "File Uploaded",
    downloadHere: "Download here",
    ablankTable: "a blank table",
    import: "Import",
    name: "Name",
    filesUpload: "Browse your files and upload your",
    needsSupervisorApproval: "This action needs a supervisor's approval",
    chooseToSendRequest:
      "Please choose who you are going to send the request to",
    fields: {
      name: "Name",
      actions: "Actions",
    },
  },
  accessibilitySettingsPage: {
    subtitle: "Configure typography, preferences, language and more.",
    typography: "Typography",
    textPreferenceDescription: "Set your text preferences below.",
    textSize: "Text size",
    language: "Language",
    mainLanguageDescription: "Define the main language of the Platform.",
    selectLanguage: "Select the language",
    changeUnitsText: "change units of weights and measures",
    stillHavingDifficulties: "Still having difficulties?",
    weWantToHelpYou: "We want to help you! Contact us in any way you prefer",
    contactViaPhone: "Contact via telephone",
    contactViaEmail: "Contact via email",
    contactViaChat: "Contact via chat",
  },
  usersPage: {
    title: "Users",
    subtitle: "Check connected users and add new ones.",
    all: "All",
    sellerAdmin: "Seller Adm",
    sellerOperation: "Seller Operation",
    sellerViewer: "Seller Viewer",
    blocked: "Blocked",
    newUser: "New user",
    addUser: "Add user",
    editUser: "Edit user",
    allowAccess: "Allow access",
    blockedTooltip:
      "If you check this box, the User will be temporarily forbidden to access the platform. You can unset this at any time.",
    groupTooltip: `Seller-Viewer can only view data but are not allowed to create orders, change settings,…
    Seller-Operation can basically do anything except creating/blocking/removing users.
    Seller-Admin can do anyting, including creating/blocking/removing users.`,
  },
  contractsPage: {
    title: "Contracts",
    subtitle:
      "Contracts are the certification of the partnership between the company and the carrier.",
    inconterm: "Inconterm",
    taxingType: "Taxation",
    startDate: "Start Date",
    endDate: "End Date",
    downloadPdf: "Download PDF",
    downloadCsv: "Download CSV",
    footerHelpText: "Learn more about carriers and contracts",
  },
  dashboardPage: {
    title: "Dashboard",
    subtitle: "Browse through the tabs and check the data",
    overview: "Overview",
    salesTrend: "Sales trend",
    consumers: "Consumer | Consumers",
    totalNewCustomers: "{count} new customers",
    totalCustomersReturned: "{count} customers returned",
    new: "New",
    recurrent: "Recurrent",
    topConsumers: "Top consumers",
    showMore: "Show more",
    showLess: "Show less",
    volumesByRegion: "Volumes by region",
    volumes: "Volumes",
    ordersCreated: "Orders created",
    ordersByStatus: "Orders by status",
    seeOrders: "See orders",
    seeTableWithAllOrders: "See table with all orders",
    cards: {
      rejectOrder: "Rejected orders",
      freightValue: "Freight value",
      freightCost: "Freight cost",
      averageOrderCost: "Average order cost",
    },
    totalOrders: "Total orders",
    totalSales: "Total sales",
    currentPeriod: "Current period",
    bestSellers: "Best sellers",
    ordersByCategory: "Orders by category",
    salesByChannel: "Sales by channel",
    ecommerce: "ecommerce",
    period: "Period",

    noMoreOnboardText: `Click here if you don't want to see this page (you can still access this in side menu at <i class="fas fa-user-graduate"></i><b> Onboard</b>).`,
    customerOverview: "Customer Overview",
    startDate: "Start Date",
    endDate: "End Date",
    clear: "Clear",
    checkpoints: {
      new: "NEW",
      orderInformationsReceived: "ORDER INFORMATION RECEIVED",
      readyToShip: "READY TO SHIP",
      pickup: "PICKUP",
      shipped: "SHIPPED",
      inTransit: "IN TRANSIT",
      outForDelivery: "OUT_FOR_DELIVERY",
      failedAttempt: "FAILED_ATTEMPT",
      delivered: "DELIVERED",
      exception: "EXCEPTION",
      exceptionInPickup: "EXCEPTION IN PICKUP",
      partialDelivery: "PARTIAL DELIVERY",
      partialDeliveryFailed: "PARTIAL DELIVERY FAILED",
      expired: "EXPIRED",
      pending: "PENDING",
    },
    noShippingInThisState: "You have no shipping to this state 0.",
    createOrderHere: "You can create an order here.",
    moreInformation: "For more information check our onboard page here.",
    checkYourInternet: "Check your internet connection and try again.",
  },
  userProfileBalancePage: {
    title: "Current balance",
    statement: "Statement",
    downloadReport: "Download report",
    fields: {
      category: "Category",
      type: "Type",
      eventType: "Event",
      method: "Method",
      value: "Value",
      description: "Description",
      createdAt: "Created at",
    },
  },
  trackingRuleNotificationsPage: {
    title: "Tracking Notification Rules",
    addConfig: "Add configuration",
    createRule: "Create rule",
    giveRuleName: "Give a name to your Rule",
    ruleName: "Rule name",
    applyRuleTo: "You want to apply the rule to:",
    allShipments: "All shipments",
    someShipmentsOnly: "Some shipments only",
    chooseSellers: "First, choose the sellers that will use these rules",
    WhenNewSellersIsCreated:
      "What do you want to do when a new seller is created",
    addThisRuleAutomatically: "Automatically add to this rule",
    addManually:
      "Do not add it to this rule, they will be added manually if needed",
    notifications: "Notifications",
    conditions: "Conditions",
    wouldYouLikeToRemoveRule: "Would you like to remove rule",
    wouldYouLikeToDisableRule: "Would you like to disable rule",
    wouldYouLikeToEnableRule: "Would you like to enable rule",
    removalIsFinal: "Removal is final",
    disableRuleMessage:
      "The rule will be saved in the system but will not be applied to any order, with immediate effect. This can be undone at any time.",
    enableRuleMessage:
      "The rule will be applied to the requests involved with immediate effect. This can be undone at any time.",
    sellersPermission: "Sellers permission",
    sellersThatCanConfigureTheirOwnRules:
      "Sellers that can configure their own rules",
    sellersThatCanConfigureTheirOwnRulesDescription:
      "For these sellers, none of the rules created here will apply and they will be able to configure their own",
    sellersThatWillUseRulesConfiguredHere:
      "Sellers that will use rules configured here",
    sellersThatWillUseRulesConfiguredHereDescription:
      "These sellers will not be able to configure their rules",

    createOrUseExistingRule:
      "Would you like to create or use an existing rule?",
    createFromScratch: "Create from scratch",
    useExistingRule: "Use an existing rule as pre-setup",
    selectRuleToUse: "Select the rule you want to use",
    defineNotificationRules: "Defines notification rules for checkpoint groups",
    allSelected: "All selected",
    selectAll: "Select all",
    onSellerCreation: "What to do when a new seller is created?",
    onContractCreation: "What to do when a new contract is created?",
    toEditSellersList: "To edit sellers list, you need to choose:",
    toEditContractsList: "To edit contracts list, you need to choose:",
    autoAddRule: "Automatically add to this rule",
    notAddThisRule:
      "Do not add it to this rule, they will be added manually if needed",
    searchByNameOrId: "Search: find by name or id",
    noItemsSelected: "No items selected",
    selectedItems: "Selected items",
    chooseContracts:
      "Now, choose to which of the contracts below you want to apply the rule",
    allShipmentWarning:
      "You are about to create a rule applying for all shipments",
    keepEditing: "Keep editing",
    goIt: "Go it",
    wantToCreateRule: "Do you want to create the configured rule?",
    totalSellersLinked: "Total number of sellers linked to this rule",
    totalContractsLinked: "Total number of contracts linked to this rule",
    allSellersIncluded:
      "All sellers have been included in this rule, including new sellers that will be added later.",
    allContractsIncluded:
      "All contracts have been included in this rule, including new contracts that will be added later.",
    almostThere: "Almost there..",
    ruleCreatedSuccessfully: "Rule created successfully!",
    youWantActivate:
      "It is disabled for now. Do you want to enable this rule now?",
    maybeLater: "Maybe later",
    notifsConfigured: "{total} notifs configured",
    allSellers: "All sellers",
    allContracts: "All contracts",
    totalSellers: "{total} sellers",
    totalContracts: "{total} contracts",
    updateRule: "Update rule",
    wantToUpdateRule: "Do you want to update the configured rule?",
    ruleUpdatedSuccessfully: "Rule updated successfully!",
    activateUpdatedRule:
      "The rule has been successfully updated and has been disabled for security reasons. Do you want to enable the rule now?",
    platformWillBeInCharge:
      "{platform} will be in charge of setting up the notifications. If you want to configure your own notifications, please reach your CX contact",
    noContractSelected: "No contract selected",
    contractsSelected: "contracts selected",
    youDontHaveContractAllowed:
      "You don’t have any contract allowed. Please reach to {platform} to have access to your first contracts",
    ruleNameAlreadyExists: "The rule name provided already exists",
  },
  fiscalPaymentsPage: {
    title: "Fiscal Payments",
    fiscalInfo: "Fiscal Information",
    fiscalParcelData: "Fiscal Parcel Data",
    customerData: "Customer Data",
    fields: {
      reference_number: "Reference",
      barcode: "Barcode",
      authentication: "Authentication",
      status: "Status",
      payment_type: "Payment Type",
      value: "Value",
      fiscal_parcel_id: "Fiscal Parcel ID",
      payment_date: "Payment Date",
      created_at: "Creation Date",
      dir_number: "DIR Number",
      customer_name: "Customer Name",
      customer_cpf: "Customer CPF",
      customer_cep: "Customer CEP",
      tracking_number: "Tracking Number",
      products_description: "Products Description",
      customer_state: "Customer State",
      seller_id: "Seller ID",
      dir_date: "DIR Date",
      start_payment_date: "Start Payment Date",
      end_payment_date: "End Payment Date",
      start_created_at: "Start Creation Date",
      end_created_at: "End Creation Date",
      start_value: "Start Value",
      end_value: "End Value",
      start_dir_date: "Start DIR Date",
      end_dir_date: "End DIR Date",
      boletoGNRE: "Boleto GNRE",
      paymentDate: "Payment Date",
      masterNumber: "Master Number",
      messages: "Messages",
      courrierName: "Courrier Name",
      courrierCNPJ: "Courrier CNPJ",
    },
    status: {
      processing: "Processing",
      processed: "Processed",
      error: "Error",
    },
    downloadBoletoGNRE: "Download Boletos",
  },
};
//{{ $t('userProfileEditPage.title') }}
