<template>
  <section class="form-wrapper-new">
    <h6 class="title-card">
      {{ $t("trackMyOrderPage.subtitle") }}
    </h6>
    <NbBorderTextInput
      id="tracking-number"
      :name="$t('trackMyOrderPage.trackingNumber')"
      :placeholder="$t('trackMyOrderPage.placeholderTrackingNumber')"
      :error="errors.tracking_number"
      v-model="tracking_number"
    />
    <div class="d-flex justify-content-between align-items-end mt-4">
      <div></div>
      <button
        id="tracking-btn"
        class="button button-primary float-right"
        @click="setRecaptchaToken()"
        :disabled="loading"
      >
        <span v-if="loading">
          {{ $t("loading") }}
          <NbIcon
            icon="loader"
            :attributes="{
              class: 'mb-1 spin',
              width: '1rem',
              height: '1rem',
            }"
          />
        </span>
        <span class="mr-4" v-if="!loading"
          >{{ $t("trackMyOrderPage.track") }}
        </span>
        <NbIcon
          icon="chevron-right"
          :attributes="{
            class: 'mb-1',
            width: '1rem',
            height: '1rem',
          }"
        />
      </button>
    </div>
  </section>
</template>

<script>
import NbBorderTextInput from "../input/text/NbBorderTextInput.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
/* import TrackingService from "@/services/TrackingService"

const trackingService = new TrackingService(); */

export default {
  name: "track_form",
  components: { NbBorderTextInput, NbIcon },
  data() {
    return {
      tracking_number: "",
      loading: false,
      errors: {},
    };
  },
  created() {
    this.$recaptchaInstance.showBadge();
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.trackingNumber) {
      this.tracking_number = this.$route.query.trackingNumber;
      this.setRecaptchaToken();
    }
  },
  methods: {
    setRecaptchaToken() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("tracking").then((token) => {
          this.goTrack(token);
        });
      });
    },
    goTrack(token) {
      this.loading = true;
      if (!this.tracking_number) {
        this.errors["tracking_number"] = [
          this.$t("trackMyOrderPage.enterTtrackingNumber"),
        ];
      } else {
        delete this.errors["tracking_number"];
      }
      if (this.tracking_number) {
        this.$router.push({
          name: "tracking_view",
          params: { reference: this.tracking_number, recaptcha_token: token },
        });
      }
      this.loading = false;
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.tracking_number = from.params.reference;
        if (from.name == "tracking_view") {
          if (to.query.error == 404) {
            vm.errors["tracking_number"] = [
              this.$t("trackMyOrderPage.trackingNotFound"),
            ];
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper-new {
  width: 632px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 2.5rem;
}
.title-card {
  width: 400px;
  font: normal normal 800 32px/40px Nunito Sans;
  color: var(--primary);
  margin-bottom: 1.2rem;
}

@media only screen and (max-width: 855px) {
  .form-wrapper-new {
    max-width: 18.75rem;
    padding: 2rem;
    margin-bottom: 7.6rem;
  }
  .title-card {
    max-width: 14.75rem;
    font: normal normal bold 24px/32px Nunito Sans;
    letter-spacing: 0px;
    color: var(--primary);
    margin-bottom: 1.2rem;
  }
  .align-items-end.mt-4 {
    margin-top: 0.5rem !important;
  }
  .button.button-primary {
    width: 100% !important;
    padding: 0.6rem 1rem;

    span {
      float: left;
    }
    i {
      float: right;
    }
  }
}
@media only screen and (max-width: 480px) {
}
</style>
