<template>
  <div v-if="showApp" class="container-fluid">
    <NbTopBar :showSidenav="showSidenav" />

    <div class="row">
      <aside
        class="nb-bg-aside"
        :class="showSidenav ? 'splitscreen-sidebar' : 'fullScreen-sidebar'"
      >
        <NbMainMenu
          v-if="showSidenav"
          :current-page="currentPage"
          @switchShowSidenav="switchShowSidenav()"
        ></NbMainMenu>
      </aside>

      <div
        class="px-4 d-inline bg-wrapper mt-0 bg-page"
        :class="showSidenav ? 'splitscreen-workspace' : 'fullScreen-workspace'"
        id="main-container"
      >
        <div
          v-if="getPlatform() === 'total'"
          class="total-chatbot-toggle blzvQB"
          @click="toggleTotalBot()"
        >
          Toggle EVA message-circle
          <NbIcon
            icon="message-circle"
            :attributes="{
              class: 'ml-1 mb-1',
              width: '1rem',
              height: '1rem',
            }"
          />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
  <div v-else>
    <track-my-order />
  </div>
</template>

<script>
import UserService from "../services/UserService";
import NbMainMenu from "../components/menus/NbMainMenu";
import NbTopBar from "../components/menus/NbTopBar";
import TrackMyOrder from "./TrackMyOrder";
import NbIcon from "@/components/icons/NbIcon.vue";

const userService = new UserService();

export default {
  name: "BaseTemplate",
  components: { NbMainMenu, NbTopBar, TrackMyOrder, NbIcon },
  props: {
    currentPage: String,
  },
  data: function () {
    return {
      showSidenav: true,
      showApp: false,

      sellerNotifications: {
        count: 0,
        ids: null,
        elements: [],
      },
      showTotalBot: true,
      totalBot: null,
    };
  },
  created() {
    this.whichDomain();
    this.checkShowSidenav();
  },
  mounted() {
    //load total-chatboot
    this.loadTotalChatboot();
  },
  methods: {
    checkShowSidenav() {
      if (JSON.parse(localStorage.getItem("showSidenav"))) {
        this.showSidenav = JSON.parse(localStorage.getItem("showSidenav"));
      } else {
        this.showSidenav = true;
      }
    },
    switchShowSidenav() {
      this.showSidenav = !this.showSidenav;
      localStorage.setItem("showSidenav", JSON.stringify(this.showSidenav));
    },
    whichDomain() {
      const domain = window.location.host;
      const user = userService.getLoggedInUser();
      if (domain.includes("trackmyorder")) {
        this.showApp = false;
        return;
      }
      if (user) {
        this.showApp = true;
      } else {
        const prefix = this.$helpers.getPlatform("prefix");

        this.$router.push(`/${prefix}-login`);
      }
    },
    getPlatform(isPrefix) {
      return this.$helpers.getPlatform(isPrefix);
    },
    loadTotalChatboot() {
      if (this.getPlatform() === "total") {
        const botEva = document.getElementById("dt-widget");
        if (!botEva) {
          /*
          let botCss = document.createElement('link')
          botCss.setAttribute('rel', 'stylesheet')
          botCss.setAttribute('type', 'text/css')
          botCss.setAttribute('href', 'https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada/style.css')
          document.head.appendChild(botCss)

          let botScript1 = document.createElement('script')
          botScript1.setAttribute('id', 'dtbot-script')
          botScript1.setAttribute('src', "https://dtbot.directtalk.com.br/1.0/staticbot/dist/js/dtbot.js?token=58628a34-c65b-4e80-8eeb-f2ee32d085bc&amp;widget=true&amp;top=40&amp;text=Alguma%20d%C3%BAvida%3F&amp;textcolor=ffffff&amp;bgcolor=4E1D3A&amp;from=bottomRight")
          document.head.appendChild(botScript1)
          */

          let botScript2 = document.createElement("script");
          botScript2.setAttribute("id", "dt-widget");
          botScript2.setAttribute(
            "src",
            "https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada_ingles/widget.min.js?nome_usuario=&cpf=&origem=Area-logada"
          );
          document.head.appendChild(botScript2);

          /*
            let botScript3 = document.createElement('script')
            botScript3.setAttribute('id', 'dt-widget')
            botScript3.setAttribute('src', "https://www11.directtalk.com.br/clientes/custom/TotalExpress/area_logada_ingles/widget.min.js?nome_usuario=&cpf=&reid=&consultor=&origem=Area-logada")
            document.head.appendChild(botScript3)
            */

          //remove nb bot
          let nb_bot = document.getElementById("freshworks-container");
          if (!nb_bot) {
            setTimeout(() => {
              nb_bot = document.getElementById("freshworks-container");
              nb_bot.remove();
            }, 1000);
          }
          nb_bot.remove();

          return;
        }
        return;
      }
    },
    toggleTotalBot() {
      const totalBot = document.getElementById("bot");
      this.showTotalBot = !this.showTotalBot;
      if (this.showTotalBot) {
        totalBot.style.display = "inherit";
        return;
      }
      totalBot.style.display = "none";
    },
  },
};
</script>

<style lang="scss">
.navbar {
  height: var(--topNavBarHeight);
  padding: inherit;
}

.lang-flag {
  width: 20px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.lang-flag.active {
  width: 30px;
}
.lang-flag:hover {
  width: 30px;
}

.bg-aside {
  z-index: 10;
  background: var(--navBg);
  box-shadow: 13px -6px 10px -16px #0000008f;
  margin-top: calc(#{var(--topNavBarHeight)}) !important;
  height: calc(100vh - var(--topNavBarHeight));
  position: fixed;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 50px;
}

.bg-aside a {
  color: var(--navText) !important;
  transition: all 0.3s ease;
  font-size: 1.5em;
}
.bg-aside a:hover {
  color: var(--navText) !important;
  text-decoration: none;
}
.bg-aside a.text-dark:focus {
  color: var(--activeMenu) !important;
}

.nb-bg-aside {
  z-index: 10;
  background: var(--navBg);
  box-shadow: 4px 4px 8px var(--shadow);
  /* box-shadow: 13px -6px 10px -16px #0000008f; removido** */
  /* margin-top: calc(#{var(--topNavBarHeight)}) !important; */
  height: 100vh;
  position: fixed;
  overflow-y: auto; //n~ao esconder o botao switch
  overflow-x: auto;
  /* padding-top: 10px; */
  /* padding-bottom: 50px; //remover espaço no bottom */
}

.nb-bg-aside .nav-link.text-dark.collapsed {
  color: var(--navText) !important;
  transition: all 0.3s ease;
  font: normal normal normal 0.875rem/1.25 var(--font-family-base);

  background: var(--navBgClose);
  border-radius: 4px 4px 4px 4px;
  margin-top: 1px; //distancia entre os botoes
}

//itens ativos
.nav-link.text-dark.collapsed.router-link-exact-active.router-link-active,
.nb-bg-aside .nav-link.text-dark {
  color: var(--navText) !important;
  transition: all 0.3s ease;
  font: normal normal bold 0.875rem/1.25 var(--font-family-base);

  background: var(--navBgOpen) 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
}

.nb-bg-aside a:hover {
  color: var(--navText) !important;
  text-decoration: none;
}
.nb-bg-aside a.text-dark:focus {
  font: normal normal bold 0.875rem/1.25 var(--font-family-base);
  letter-spacing: 0px;
  //color: var(--activeMenu) !important;
}

/* .bg-page {
  height: calc(100vh -100px);
} */

.btn-navswitch {
  border: 1px solid var(--topNavHover);
  transition: all 0.8s ease;
  background: var(--navBg);
  font-weight: 600;
  /* background: linear-gradient(to right, var(--darkGreen), var(--navBg) 100%); */
  color: var(--navText) !important;
}
.btn-navswitch:hover {
  transition: all 0.8s ease;
  background: var(--topNavHover);
  /* background: linear-gradient(to right, var(--lightGreen), #2b3990 100%) !important; */
}

/*scroll bar */
.bg-aside::-webkit-scrollbar {
  display: none;
}
.nb-bg-aside::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-40);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track {
  background: var(--gray-20);
}
/*
::-webkit-scrollbar:hover {
  background: var(--gray-05);
}
*/
::-webkit-scrollbar-thumb:hover {
  background: var(--gray-50);
}

.bg-wrapper {
  position: relative;
  max-height: calc(100vh - #{var(--topNavBarHeight)});
  overflow-y: auto;
  padding-top: 25px;
  top: var(--topNavBarHeight);

  /* margin-left: var(--sideNavBarWidth) !important; */
  /* background: #E5EAF6; */
  /* background: var(--navBg);
 color: rgb(235, 235, 235) !important; */
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  /*
  transform: translateY(20px);
  */
  transform: translateX(100px);
  opacity: 0;
}

.fullScreen-button {
  animation: animatefullScreen-button 0.3s ease-in;
  animation-fill-mode: forwards;
}
.splitscreen-button {
  animation: animatesplitscreen-button 0.3s ease-in;
  animation-fill-mode: forwards;
}
@keyframes animatefullScreen-button {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes animatesplitscreen-button {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.row .fullScreen-workspace {
  animation: animatefullScreen-workspace 0.3s ease-in;
  animation-fill-mode: forwards;
}
.row .splitscreen-workspace {
  animation: animatesplitscreen-workspace 0.3s ease-in;
  animation-fill-mode: forwards;
}

.row .fullScreen-sidebar {
  animation: animatefullScreen-sidebar 0.3s ease-in;
  animation-fill-mode: forwards;
  overflow-y: hidden; /* nao esconder o botao siwtch */
  overflow-x: auto;
}
.row .splitscreen-sidebar {
  animation: animatesplitscreen-sidebar 0.3s ease-in;
  animation-fill-mode: forwards;
}

@keyframes animatefullScreen-workspace {
  0% {
    /* flex: 0 0 83.33333%; */
    width: calc(100vw - var(--sideNavBarWidth));
    left: var(--sideNavBarWidth);
  }
  100% {
    width: 100vw;
    left: 0px;
  }
}
@keyframes animatesplitscreen-workspace {
  0% {
    /* flex: 0 0 100%; */
    width: 100vw;
    left: 0px;
  }
  100% {
    /* flex: 0 0 83.33333%; */
    width: calc(100vw - var(--sideNavBarWidth));
    left: var(--sideNavBarWidth);
  }
}

@keyframes animatefullScreen-sidebar {
  0% {
    width: var(--sideNavBarWidth);
    margin-left: 0px;
  }
  100% {
    //width: 0px;
    margin-left: calc(0px - var(--sideNavBarWidth) - 3rem);
  }
}
@keyframes animatesplitscreen-sidebar {
  0% {
    //width: 0px;
    width: var(--sideNavBarWidth);
    margin-left: 0;

    //margin-left: calc(0px - var(--sideNavBarWidth)); //sidenav animate
  }
  100% {
    width: var(--sideNavBarWidth);
    margin-left: 0px;
  }
}
//logo anim
.animed-icon {
  fill: var(---navText);
  stroke-width: 0.1;
  stroke: var(--lightBlue);
  //deveria ficar no path?
  path {
    fill: var(--navText);
    stroke-width: 0.1;
    stroke: var(--lightBlue);
  }
}
.animed-icon.logo-minimal {
  stroke-dasharray: 128;
  stroke-dashoffset: 0;
  :hover path {
    animation: animate-logo-minimal 3s linear forwards;
  }
}

@keyframes animate-logo-minimal {
  0% {
    //fill: linear-gradient(to right, var(--green) 0%, var(--primary) 100%); //deixando a logo branca wtf?
    stroke-width: 1.8;
    stroke-dashoffset: 0;
  }

  20% {
    fill: transparent;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dashoffset: 128;
    fill: transparent;
  }
  75% {
    stroke-dashoffset: 256;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 256;
    //fill: linear-gradient(to right, var(--green) 0%, var(--primary) 100%); //deixando a logo branca wtf?
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1050px) {
  .bg-aside a {
    color: var(--navText) !important;
    transition: all 0.3s ease;
    font-size: 1em;
  }

  @keyframes animatefullScreen-workspace {
    0% {
      /* flex: 0 0 83.33333%; */
      width: calc(100vw - #{var(--topNavBarHeight)});
      left: var(--sideNavBarWidth);
    }
    100% {
      width: 100vw;
      left: 0px;
    }
  }
  @keyframes animatesplitscreen-workspace {
    0% {
      /* flex: 0 0 100%; */
      width: 100vw;
      left: 0px;
    }
    100% {
      /* flex: 0 0 83.33333%; */
      width: calc(100vw - #{var(--sideNavBarWidth)});
      left: var(--sideNavBarWidth);
    }
  }

  @keyframes animatefullScreen-sidebar {
    0% {
      width: var(--sideNavBarWidth);
      margin-left: 0px;
    }
    100% {
      //width: 0px;
      margin-left: calc(0px - var(--sideNavBarWidth));
    }
  }
  @keyframes animatesplitscreen-sidebar {
    0% {
      //width: 0px;
      width: var(--sideNavBarWidth);
      margin-left: 0;

      //margin-left: calc(0px - var(--sideNavBarWidth)); //sidenav animate
    }
    100% {
      width: var(--sideNavBarWidth);
      margin-left: 0px;
    }
  }
}

.blzvQB {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  float: right;
  border-radius: 30px 8px 30px 30px;
  background-color: var(--primary);
  box-shadow: none;
  color: rgb(255, 255, 255);
  z-index: 2147483647;
  cursor: pointer;
  padding: 8px;
  transition: all 0.1s ease-out 0s;
  -webkit-font-smoothing: antialiased;
}
.blzvQB:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
  transform: scale(1.04);
  transition: all 0.5s linear 0s;
  background: var(--primary-70-hover);
}
.total-chatbot-toggle {
  position: fixed;
  bottom: 0.1rem;
  right: 0.8rem;
  z-index: 99990;
}
.fc-widget-normal {
  right: 120px !important;
  bottom: 3px !important;
}

.blzvQB {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  float: right;
  border-radius: 30px 8px 30px 30px;
  background-color: rgb(0, 96, 99);
  box-shadow: none;
  color: rgb(255, 255, 255);
  z-index: 2147483647;
  cursor: pointer;
  padding: 8px;
  transition: all 0.1s ease-out 0s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}
.blzvQB:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
  transform: scale(1.04);
  transition: all 0.5s linear 0s;
  background: radial-gradient(circle, transparent 1%, rgb(0, 96, 99) 1%) center
    center / 15000% rgb(0, 96, 99);
}
.total-chatbot-toggle {
  position: fixed;
  bottom: 0.1rem;
  right: 0.8rem;
  z-index: 99990;
}
.fc-widget-normal {
  right: 120px !important;
  bottom: 3px !important;
}
</style>
