<template>
  <div
    class="nb-banner bg-primary mb-5 d-flex justify-content-between w-100"
    @click="$emit('click')"
  >
    <div class="d-flex justify-content-between">
      <vue-ellipse-progress
        v-if="progress > -1"
        class="ellipse-progress"
        :progress="progress"
        color="var(--white)"
        emptyColor="var(--gray-40)"
        fontColor="var(--white)"
        :size="65"
        :angle="-90"
        :thickness="7"
        emptyThickness="10%"
      >
        <span slot="legend-value">%</span>
      </vue-ellipse-progress>
      <div class="d-flex align-items-center">
        <div>
          <h4 class="banner-h4">{{ title }}</h4>
          <p class="banner-p">{{ text }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center banner-right">
      <span class="invisible-text">{{ command }}</span>
      <NbIcon
        icon="chevron-right"
        :attributes="{
          'stroke-width': 3,
          width: '1rem',
          height: '1rem',
        }"
      />
    </div>
  </div>
</template>

<script>
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "BannerLink",
  components: { NbIcon },

  props: {
    progress: {
      type: Number,
      default: -1,
    },
    title: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    command: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.nb-banner {
  color: var(--white);
  padding: 1rem 1.5rem;
  background: var(--primary) 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 8px var(--gray-05);
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;
}
.ellipse-progress {
  margin-left: 0.25rem;
  margin-right: 1.75rem;
}
.banner-h4 {
  text-align: left;
  font: normal normal bold 20px/27px Nunito Sans;
  letter-spacing: 0px;
}
.banner-p {
  color: var(--gray-05);
  text-align: left;
  font: normal normal 600 14px/20px Nunito Sans;
  letter-spacing: 0px;
  margin: 0;
}
.nb-banner:hover .banner-right > span {
  visibility: visible !important;
}
.invisible-text {
  margin-right: 0.4rem;
  visibility: hidden;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0.08px;
}
</style>
