<template>
  <b-modal id="modal-settings" ok-title="Ok" hide-footer scrollable>
    <template #modal-title>
      <upcase-string :string="settings.category" />
      <span>Settings</span>
    </template>
    <b-list-group class="mb-3">
      <b-list-group v-for="(field, id) in fields_display" :key="id">
        <b-list-group-item>
          {{ field.label }}:
          <span v-if="settings[field.key] === true"> Yes </span>
          <span v-else-if="settings[field.key] == false"> No </span>
          <span v-else-if="settings[field.key] == null"> -- </span>
          <span v-else-if="field.key == 'custom_fields'">
            {{ sanitizeCustomFields(settings[field.key]) }}
          </span>
          <span v-else-if="field.key == 'category' || field.key == 'method'">
            {{ categoryTitle(settings[field.key]) }}
          </span>
          <span v-else>
            {{ settings[field.key] }}
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-list-group>
  </b-modal>
</template>

<script>
import SellerAccountService from "@/services/SellerAccountService";
import UpcaseString from "@/components/UpcaseString";

const sellerAccountService = new SellerAccountService();

export default {
  name: "modalSettings",
  components: {
    UpcaseString,
  },
  props: {
    settings: Object,
  },
  data: () => {
    return {
      is_fixed: null,
      charge_method: null,
      charge_frequency: null,
      charge_day: null,
      limit: null,
      seller_id: null,
      custom_fields: {},
      off_settings: {},
      fields_display: [
        { key: "method", label: "Method" },
        { key: "is_active", label: "Active" },
        { key: "category", label: "Service" },
        { key: "charge_day", label: "Charge Day" },
        { key: "is_fixed", label: "Charge Day Fixed" },
        { key: "charge_frequency", label: "Charge Day Frequency" },
        { key: "limit", label: "Limit" },
        { key: "custom_fields", label: "Values" },
      ],
    };
  },
  created() {
    this.off_settings = this.settings;
    this.seller_id = this.$route.params.seller_id;
  },
  methods: {
    categoryTitle(category) {
      const split_category = category.split("_");
      let string_category = "";
      split_category.forEach((category) => {
        string_category +=
          category.charAt(0).toUpperCase() + category.slice(1) + " ";
      });
      return string_category;
    },
    updateSettings() {
      const data = this.off_settings;
      sellerAccountService.updateSetting(data).then(() => {
        this.$emit("settingsUpdated");
      });
    },
    sanitizeCustomFields(fields) {
      let arr = [];
      Object.keys(fields).forEach((item) => {
        arr.push(`${this.categoryTitle(item)}: ${fields[item]}`);
      });
      return arr.join(", ");
    },
  },
  computed: {
    customFields() {
      if (this.settings.custom_fields) {
        return Object.keys(this.settings.custom_fields).length;
      }
      return {};
    },
  },
  watch: {
    settings() {
      this.off_settings = this.settings;
    },
  },
};
</script>
