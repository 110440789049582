import HttpService from "./HttpService";

export default class SellerService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get sellers
   *
   * @param filters
   * @returns {Promise}
   */
  getSellers(filters = {}) {
    return this.httpService.get("/v1/sellers", {
      params: filters,
    });
  }

  /**
   * Get Seller
   *
   * @param {*} id
   * @returns {Promise}
   */
  getSeller(id) {
    return this.httpService.get("/v1/sellers/" + id);
  }

  /**
   * Get current seller
   *
   * @returns {Promise}
   */
  getCurrentSeller() {
    return this.httpService.get("/v1/current-seller");
  }

  /**
   * Set Seller
   *
   * @param datalist
   * @returns {Promise}
   */
  setSeller(data = {}) {
    return this.httpService.post("/v1/sellers-users", data);
  }

  /**
   * Update seller
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateSeller(id = null, data = {}) {
    return this.httpService.put("/v1/sellers/" + id, data);
  }

  /**
   * Update seller settings
   *
   * @param {String} id
   * @param {Object} data
   * @returns {Promise}
   */
  updateSellerSettings(id, data) {
    return this.httpService.put("/v1/seller_settings/" + id, data);
  }

  /**
   * get seller settings
   *
   * @param {String} id
   * @returns {Promise}
   */
  getSellerSettings(id) {
    return this.httpService.get("/v1/seller_settings/" + id);
  }

  /**
   * Upload seller ad
   *
   * @param {*} id
   * @param {*} file
   * @returns {Promise}
   */
  uploadAd(id, file) {
    var formData = new FormData();
    formData.append("ad", file);
    return this.httpService.put("/v1/sellers/" + id + "/upload-ad", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  saveImage(id, company_logo) {
    let data = new FormData();
    data.append("company_logo", company_logo);
    return this.httpService.put(
      "/v1/sellers/" + id + "/upload-company-logo",
      data,
      { headers: { "content-type": "multipart/form-data" } }
    );
  }

  /**
   * Get AnalyticDatas
   *
   * @param filters
   * @returns {Promise}
   */
  getAnalyticDatas(filters = {}, seller_id) {
    return this.httpService.get(`/v1/sellers/${seller_id}/analytics`, {
      params: filters,
    });
  }

  saveSignature(id, company_signature) {
    let dataSignature = new FormData();
    dataSignature.append("company_signature", company_signature);
    return this.httpService.put(
      "/v1/sellers/" + id + "/upload-company-signature",
      dataSignature,
      { headers: { "content-type": "multipart/form-data" } }
    );
  }

  getNewSellerNotifications(filters = {}) {
    return this.httpService.get(
      "/v1/my_notifications?is_new=true&offset=0&limit=50",
      {
        params: filters,
      }
    );
  }

  getSellerNotifications(filters = {}) {
    return this.httpService.get("/v1/my_notifications", {
      params: filters,
    });
  }

  updateSellerNotifications(data) {
    return this.httpService.put(
      "/v1/seller_notifications/massive_update",
      data
    );
  }

  getTrackingSettings(id) {
    return this.httpService.get(`/v1/tracking_settings/${id}`);
  }

  updateTrackingSettings(id, data) {
    return this.httpService.put(`/v1/tracking_settings/${id}`, data);
  }
}
