<template>
  <div>
    <div v-for="(event, index) in events" :key="index">
      <b-row
        class="seller-account-events-view mt-4 pr-2"
        :class="isClosed(event)"
      >
        <b-col class="my-auto align-items-center" cols="1">
          <div
            v-if="event.event_type == 'service'"
            class="seller-account-info-icon text-center"
          >
            <img height="18px" width="18px" :src="IconService" class="icon" />
          </div>
          <div
            v-if="event.event_type == 'credit' && event.value >= 0"
            class="seller-account-info-icon icon-credit-positive text-center"
          >
            <img height="18px" width="18px" :src="IconCredit" class="icon" />
          </div>
          <div
            v-if="event.event_type == 'credit' && event.value < 0"
            class="seller-account-info-icon icon-credit-negative text-center"
          >
            <img height="18px" width="18px" :src="IconCredit" class="icon" />
          </div>
        </b-col>
        <b-col class="pr-4 my-auto text-nowrap" cols="1">
          <div v-if="event.value >= 0 && !event.service" class="text-left">
            <strong class="nbx-value text-success">{{ event.value }}</strong
            ><span class="nbx-symbol"> NBx</span>
          </div>
          <div v-else class="text-left">
            <strong class="nbx-value text-danger">{{ event.value }}</strong>
            <span class="nbx-symbol"> NBx</span>
          </div>
        </b-col>
        <b-col class="p-1 pl-4 my-auto" cols="2">
          <div class="text-center" style="color: #4d7180">
            <upcase-string :string="event.category" />
          </div>
        </b-col>
        <b-col class="p-1 my-auto" cols="6" style="color: #7f8f97">
          <div class="text-left text-truncate">
            {{ event.description || " " }}
          </div>
        </b-col>
        <b-col class="p-1 my-auto text-right" cols="2" style="color: #7f8f97">
          <div class="text-right">{{ event.created_at }}</div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UpcaseString from "@/components/UpcaseString";
import IconService from "@/assets/img/nb_services.svg";
import IconInvoice from "@/assets/img/nb_invoice.svg";
import IconCredit from "@/assets/img/nb_credit.svg";

export default {
  name: "EventsList",
  components: {
    UpcaseString,
  },
  props: {
    events: Array,
  },
  data: () => {
    return {
      IconService,
      IconInvoice,
      IconCredit,
    };
  },
  methods: {
    isClosed(field) {
      let className = null;
      if (field.event_type === "invoice") {
        className = "seller-account-event-invoice";
      }
      return className;
    },
  },
};
</script>

<style scoped>
.view-events-ul {
  list-style-type: none;
}

.view-events-ul li {
  margin-bottom: 1rem;
}

.seller-account-events-view {
  min-height: 3.5rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1025px) {
  .seller-account-events-view {
    max-width: 70vw;
  }
}

.seller-account-event-invoice {
  background-color: #eef2f7;
  border-color: #d6e7f4;
}

.seller-account-events-view .nbx-value {
  font-size: small;
}

.seller-account-info-icon {
  width: 40px;
  height: 40px;
  background-color: rgba(185, 185, 185, 0.227);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-credit-positive {
  background-color: rgba(93, 231, 146, 0.11) !important;
}

.icon-credit-negative {
  background-color: rgba(250, 4, 4, 0.11) !important;
}

.nbx-symbol {
  font-size: x-small;
  font-weight: lighter;
}
</style>
