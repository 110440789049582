<template>
  <div class="d-flex justify-content-between nb-title-wrapper">
    <div>
      <h5 :class="titleClass ? titleClass : 'heading-4'" class="ml-3 mb-1">
        {{ title }} <slot name="title"></slot>
      </h5>
      <p
        v-if="subTitle"
        :class="subTitleClass ? subTitleClass : 'body-4'"
        class="ml-3 mb-0"
      >
        {{ subTitle }} <slot name="subTitle"></slot>
      </p>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    subTitle: {
      type: String,
      require: false,
    },
    titleClass: {
      type: String,
      require: false,
    },
    subTitleClass: {
      type: String,
      require: false,
    },
  },
};
</script>
<style scoped>
.nb-title-wrapper {
  margin-bottom: 1.5rem;
}
</style>
