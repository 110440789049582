<template>
  <div class="wrapper">
    <back-button style="margin: 20px"></back-button>
    <div class="container">
      <h1 class="title">
        {{ $t("error404Page.title") }}
        <router-link to=""
          ><a @click="$router.go(-1)"
            >{{ $t("error404Page.tryAgain") }}.</a
          ></router-link
        >
      </h1>
      <img
        class="notfound-page-img"
        alt="Erro 404"
        height="50%"
        src="../assets/img/404.svg"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "../components/generic/BackButton.vue";
export default {
  components: { BackButton },
  name: "Erro404",

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.title {
  margin: 1.1em;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
}
.container .notfound-page-img {
  width: 70%;
}

@media screen and (max-width: 991px) {
  .title {
    font-size: 40px;
  }
}
</style>
