import HttpService from "./HttpService";

export default class GlobalService {
  constructor() {
    this.httpService = HttpService;
  }

  getTwentyItems(namespace, /* offset, limit,  */ filters) {
    if (filters.limit > 0) {
      filters.offset = (filters.offset - 1) * filters.limit;
    } else {
      filters.offset = (filters.offset - 1) * 20;
    }
    return this.httpService.get("/v1/" + namespace, {
      params: filters,
    });
  }

  getAllItems(namespace) {
    return this.httpService.get("/v1/" + namespace + "?limit=1000&offset=0");
  }
}
