<template>
  <div>
    <div
      class="input-label input-switch pointer"
      v-if="name"
      @click="switchValue"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
      </NbHelpText>
    </div>
    <b-form-checkbox
      class="nb-siwtch"
      switch
      :class="disabled ? 'disabled' : ''"
      :disabled="disabled"
      @change="$emit('change', content)"
      v-model="content"
    >
      <div class="body-3-semibold">
        <slot />
      </div>
    </b-form-checkbox>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  name: "NbSwitch",
  components: {
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    switchValue() {
      if (!this.disabled) {
        this.content = !this.content;
        this.$emit("change", this.content);
      }
    },
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
  watch: {
    //altera o input (way data bind ->)
    content(newValue) {
      this.$emit("input", newValue);
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.content = newValue;
    },
  },
};
</script>

<style>
.input-label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px Nunito Sans;
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}

/* pill */
.nb-siwtch .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--black) !important;
  border-top-color: var(--black) !important;
  border-right-color: var(--black) !important;
  border-bottom-color: var(--black) !important;
  border-left-color: var(--black) !important;
  background-color: var(--black) !important;
}
.input-switch:hover
  ~ .nb-siwtch
  .custom-control-input
  ~ .custom-control-label::before,
.nb-siwtch .custom-control-input:hover ~ .custom-control-label::before {
  border-color: var(--gray-40) !important;
  border-top-color: var(--gray-40) !important;
  border-right-color: var(--gray-40) !important;
  border-bottom-color: var(--gray-40) !important;
  border-left-color: var(--gray-40) !important;
  background-color: var(--gray-20) !important;
  cursor: pointer;
}
.nb-siwtch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0px 0px 0px 1px var(--black) !important;
  border-color: var(--gray-40) !important;
  border-top-color: var(--gray-40) !important;
  border-right-color: var(--gray-40) !important;
  border-bottom-color: var(--gray-40) !important;
  border-left-color: var(--gray-40) !important;
}
.nb-siwtch.custom-switch.disabled
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: var(--gray-20) !important;
  border-top-color: var(--gray-20) !important;
  border-right-color: var(--gray-20) !important;
  border-bottom-color: var(--gray-20) !important;
  border-left-color: var(--gray-20) !important;
  background-color: var(--white) !important;
  cursor: not-allowed !important;
}

/* bolinha */
.nb-siwtch.custom-switch .custom-control-label::after {
  background-color: var(--gray-60);
  cursor: pointer;
}
.nb-siwtch.custom-switch.disabled .custom-control-label::after {
  background-color: var(--gray-20) !important;
  cursor: not-allowed !important;
}
</style>
