import Vue from "vue";
import Vuex from "vuex";
import { accessibilityModule } from "./modules/accessibility-module";
import { shippingRulesModule } from "./modules/shipping-rules-module";
import { trackingRuleSettingsModule } from "./modules/tracking-rule-settings-module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: true,
  modules: {
    accessibility: accessibilityModule,
    shippingRules: shippingRulesModule,
    notificationRuleSettings: trackingRuleSettingsModule,
  },
});
